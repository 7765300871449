import CampaignInput from "./inputs/CampaignInput";
import {Filter as RaFilter} from "react-admin";
import React from "react";
import UserInput from "./inputs/UserInput";
import {useMediaQuery} from "@material-ui/core";
import {useFiltersStyles} from "../components/lists/useStyles";

const Filters = (props) => {
    const classes = useFiltersStyles();
    const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    return (
        <RaFilter {...props} classes={classes} variant="outlined">
            <CampaignInput source="campaign_id" fullWidth alwaysOn={!sm}/>
            <UserInput source="user_id" fullWidth alwaysOn={!sm}/>
        </RaFilter>
    );
};
export default Filters;
