import { Checkbox, TableCell, TableRow, makeStyles } from "@material-ui/core";
import { DatagridCell, ExpandRowButton } from "react-admin";
import React, {
  Fragment,
  cloneElement,
  createElement,
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { linkToRecord, useExpanded } from "ra-core";

import PropTypes from "prop-types";
import classnames from "classnames";
import { grey } from "@material-ui/core/colors";
import isEqual from "lodash/isEqual";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  expanded: {
    backgroundColor: `${grey[200]}!important`,
  },
});

const computeNbColumns = (expand, children, hasBulkActions) =>
  expand
    ? 1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter((child) => !!child).length // non-null children
    : 0; // we don't need to compute columns if there is no expand panel;

const defaultClasses = { expandIconCell: "", checkbox: "", rowCell: "" };

const DatagridRow = ({
  basePath,
  children,
  classes = defaultClasses,
  className,
  expand,
  hasBulkActions,
  hover,
  id,
  onToggleItem,
  record,
  resource,
  rowClick,
  selected,
  style,
  selectable,
  ...rest
}) => {
  const [expanded, toggleExpanded] = useExpanded(resource, id);
  const [nbColumns, setNbColumns] = useState(
    computeNbColumns(expand, children, hasBulkActions)
  );
  useEffect(() => {
    // Fields can be hidden dynamically based on permissions;
    // The expand panel must span over the remaining columns
    // So we must recompute the number of columns to span on
    const newNbColumns = computeNbColumns(expand, children, hasBulkActions);
    if (newNbColumns !== nbColumns) {
      setNbColumns(newNbColumns);
    }
  }, [expand, nbColumns, children, hasBulkActions]);

  const history = useHistory();

  const handleToggleExpand = useCallback(
    (event) => {
      toggleExpanded();
      event.stopPropagation();
    },
    [toggleExpanded]
  );
  const handleToggleSelection = useCallback(
    (event) => {
      if (!selectable) return;
      onToggleItem(id);
      event.stopPropagation();
    },
    [id, onToggleItem, selectable]
  );
  const handleClick = useCallback(
    async (event) => {
      if (!rowClick) return;
      event.persist();

      const effect =
        typeof rowClick === "function"
          ? await rowClick(id, basePath, record)
          : rowClick;
      switch (effect) {
        case "edit":
          history.push(linkToRecord(basePath, id));
          return;
        case "show":
          history.push(linkToRecord(basePath, id, "show"));
          return;
        case "expand":
          handleToggleExpand(event);
          return;
        case "toggleSelection":
          handleToggleSelection(event);
          return;
        default:
          if (effect) history.push(effect);
          return;
      }
    },
    [
      basePath,
      history,
      handleToggleExpand,
      handleToggleSelection,
      id,
      record,
      rowClick,
    ]
  );

  const customClasses = useStyles();

  return (
    <Fragment>
      <TableRow
        className={classnames(
          className,
          expanded ? customClasses.expanded : {}
        )}
        key={id}
        style={style}
        hover={hover}
        onClick={handleClick}
        {...rest}
      >
        {expand && (
          <TableCell padding="none" className={classes.expandIconCell}>
            {id > 0 ? (
              <ExpandRowButton
                classes={classes}
                expanded={expanded}
                onClick={handleToggleExpand}
                expandContentId={`${id}-expand`}
              />
            ) : (
              " "
            )}
          </TableCell>
        )}
        {hasBulkActions && (
          <TableCell padding="checkbox">
            {selectable && (
              <Checkbox
                color="primary"
                className={`select-item ${classes.checkbox}`}
                checked={selected}
                onClick={handleToggleSelection}
              />
            )}
          </TableCell>
        )}
        {React.Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridCell
              key={`${id}-${field.props.source || index}`}
              className={classnames(
                `column-${field.props.source}`,
                classes.rowCell
              )}
              record={record}
              {...{ field, basePath, resource }}
            />
          ) : null
        )}
      </TableRow>
      {expand &&
        expanded &&
        (isValidElement(expand)
          ? cloneElement(expand, {
              // @ts-ignore
              record,
              basePath,
              resource,
              id: String(id),
            })
          : createElement(expand, {
              record,
              basePath,
              resource,
              id: String(id),
            }))}
    </Fragment>
  );
};

DatagridRow.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.any,
  className: PropTypes.string,
  // @ts-ignore
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  // @ts-ignore
  record: PropTypes.object,
  resource: PropTypes.string,
  // @ts-ignore
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.bool,
  style: PropTypes.object,
  selectable: PropTypes.bool,
};

DatagridRow.defaultProps = {
  hasBulkActions: false,
  hover: true,
  selected: false,
  selectable: true,
};

const areEqual = (prevProps, nextProps) => {
  const { children: _, ...prevPropsWithoutChildren } = prevProps;
  const { children: __, ...nextPropsWithoutChildren } = nextProps;
  return isEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
};

export const PureDatagridRow = memo(DatagridRow, areEqual);

PureDatagridRow.displayName = "PureDatagridRow";

export default DatagridRow;
