import { makeStyles } from "@material-ui/core";

const _useFormStyles = makeStyles(
  (theme) => ({
    main: {
      "& [class*=MuiToolbar-root]": {
        // marginLeft: -theme.spacing(1),
      },
      "& [class*=MuiFormControl-root], [class*=MuiFormGroup-root]": {
        "& p[class*=MuiFormHelperText-root] span": {
          display: "none",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      main: {
        width: "calc(100vw - 32px)",
        display: "block",
        margin: "1em",
        marginBottom: "6em",
        "& [class*=MuiFormControl-root], [class*=MuiFormGroup-root]": {
          width: "100%",
        },
        " & [class*=MuiToolbar-root]": {
          marginLeft: 0,
        },
      },
    },
  }),
  { name: "RaMobileList" }
);

export const useFormStyles = () => _useFormStyles();
