import List from "./List";
import { UsersCampaignIcon } from "../icons";

export default {
  icon: UsersCampaignIcon,
  list: List,
  options: {
    group: "campaigns",
    roles: ["admin", "advertiser"],
  },
};
