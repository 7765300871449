import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import React from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const ApprovalDialog = ({ record, onCloseRequest }) => {
  const translate = useTranslate();
  const handleClose = () => onCloseRequest();
  return (
    <Dialog open={record !== null} maxWidth="sm" fullWidth>
      <DialogTitle>
        {translate("resources.user-parent-approvals.titles.view")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {translate("resources.user-parent-approvals.messages.view", {
            user: get(record, "parent.name"),
            parent: get(record, "parent.name"),
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate("ra.action.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default ApprovalDialog;
