import { Filter } from "react-admin";
import React from "react";
import StatusInput from "./inputs/StatusInput";
import UserInput from "./inputs/UserInput";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes}
            variant="outlined">
      <UserInput source="user_id" alwaysOn fullWidth />
      <StatusInput source="status" alwaysOn resettable fullWidth />
    </Filter>
  );
};
export default Filters;
