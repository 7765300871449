import {
	Card,
	CardContent,
	CardMedia,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	withStyles,
} from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { useLocale, useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		backgroundColor: "transparent",
	},
	details: {
		display: "flex",
		flexDirection: "column",
	},
	content: {
		flex: "1 0 auto",
	},
	cover: {
		width: 151,
	},
	table: {
		// minWidth: 650,
	},
}));

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const RewardsField = ({ record, ...props }) => {
	const rewards = get(record, "rewards", []);
	const translate = useTranslate();
	const classes = useStyles();
	return (
		<TableContainer component={Paper} elevation={0}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>
							{translate("resources.rewards.fields.points")}
						</TableCell>
						<TableCell>{translate("resources.rewards.name", 1)}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rewards.map((reward) => (
						<RewardRow
							key={reward.id}
							record={reward}
							label="resources.contest-stats.fields.reward_id"
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const RewardRow = ({ record, ...props }) => {
	return (
		<StyledTableRow key={record.name}>
			<TableCell component="th" scope="row">
				<Typography variant="h5" style={{ fontWeight: "bold" }}>
					{record.points_from} - {record.points_to}
				</Typography>
			</TableCell>
			<TableCell>
				<Reward record={record} />
			</TableCell>
		</StyledTableRow>
	);
};

const Reward = ({ record }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const locale = useLocale();
	const locales = `${locale}-${locale}`;
	return (
		<Card className={classes.root} elevation={0}>
			<CardMedia
				className={classes.cover}
				image={get(record, "image.file.path")}
				title={get(record, "name")}
			/>
			<div className={classes.details}>
				<CardContent className={classes.content}>
					<Typography component="h5" variant="h5">
						{get(record, "name")}
					</Typography>
					<Typography variant="overline" color="textSecondary">
						{translate("resources.rewards.fields.price")}:{" "}
						{get(record, "price", 0).toLocaleString(locales, {
							style: "currency",
							currency: "EUR",
						})}
					</Typography>
					<Typography
						color="textSecondary"
						variant="body2"
						dangerouslySetInnerHTML={{
							__html: get(record, "description"),
						}}
					/>
				</CardContent>
			</div>
		</Card>
	);
};

export default RewardsField;
