import UserPaymentIcon from "@material-ui/icons/AccountBalanceWallet";
import StatIcon from "@material-ui/icons/Assessment";
import UsersCampaignsIcon, {
	default as UserProfileApprovalIcon,
	default as UsersCampaignIcon,
} from "@material-ui/icons/AssignmentTurnedIn";
import RedirectsIcon from "@material-ui/icons/Cached";
import PostbackIcon from "@material-ui/icons/CallToAction";
import ApiIcon from "@material-ui/icons/Code";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import DomainIcon from "@material-ui/icons/Domain";
import EventIcon from "@material-ui/icons/EmojiEvents";
import CommissionIcon from "@material-ui/icons/EuroSymbol";
import UserIcon from "@material-ui/icons/Face";
import FolderIcon from "@material-ui/icons/Folder";
import RewardIcon from "@material-ui/icons/FormatListNumbered";
import ContestStatIcon from "@material-ui/icons/Gavel";
import UserParentApprovalIcon from "@material-ui/icons/GroupAdd";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import LanguageMessagesIcon from "@material-ui/icons/Language";
import LinkIcon from "@material-ui/icons/Link";
import RoleIcon from "@material-ui/icons/Lock";
import {
	default as CategoryIcon,
	default as ContestIcon,
} from "@material-ui/icons/Loyalty";
import CategoryOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PagesIcon from "@material-ui/icons/Pages";
import CampaignBlacklistIcon from "@material-ui/icons/PanTool";
import CampaignMediaIcon from "@material-ui/icons/PermMedia";
import CampaignWhitelistIcon from "@material-ui/icons/PlaylistAddCheck";
import {
	default as CampaignIcon,
	default as OrderIcon,
} from "@material-ui/icons/ShoppingBasket";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import LandingIcon from "@material-ui/icons/ViewCarousel";
import ScriptIcon from "@material-ui/icons/Whatshot";

export {
	CampaignIcon,
	CampaignMediaIcon,
	CommissionIcon,
	CategoryIcon,
	CategoryOutlinedIcon,
	DashboardIcon,
	DomainIcon,
	LanguageMessagesIcon,
	NotificationsIcon,
	PagesIcon,
	RedirectsIcon,
	RoleIcon,
	UserIcon,
	UserParentApprovalIcon,
	UserPaymentIcon,
	UserProfileApprovalIcon,
	CampaignBlacklistIcon,
	LandingIcon,
	CampaignWhitelistIcon,
	UsersCampaignIcon,
	EventIcon,
	FolderIcon,
	FileIcon,
	UsersCampaignsIcon,
	ApiIcon,
	PostbackIcon,
	OrderIcon,
	StatIcon,
	LinkIcon,
	ScriptIcon,
	ContestIcon,
	RewardIcon,
	ContestStatIcon,
	AdminIcon,
};
