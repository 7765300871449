import EditButton from "./buttons/EditButton";
import FormTab from "./form/FormTab";
import Input from "./inputs/Input";
import StateCollectionInput from "./inputs/StateCollectionInput";
import StateField from "./fields/StateField";

export default {
  Input,
  FormTab,
  EditButton,
  StateField,
  StateCollectionInput,
};

export { Input, FormTab, EditButton, StateField, StateCollectionInput };
