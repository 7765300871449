import {
  Show as RaShow,
  SimpleShowLayout,
  TopToolbar,
  useRedirect,
} from "react-admin";

import ApprovalButton from "./buttons/ApprovalButton";
import MessageField from "./fields/MessageField";
import React from "react";
import Title from "components/Title";

const Actions = ({ data, resource }) => {
  const redirect = useRedirect();
  const handleSuccess = () => redirect("/notifications");
  return (
    <TopToolbar>
      {data && (
        <ApprovalButton
          record={data}
          resource={resource}
          forUser
          onSuccess={handleSuccess}
        />
      )}
    </TopToolbar>
  );
};

const Show = (props) => (
  <RaShow
    {...props}
    actions={<Actions />}
    title={<Title source="parent.name" />}
  >
    <SimpleShowLayout>
      <MessageField />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
