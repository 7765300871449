import { BooleanInput, useTranslate } from "react-admin";
import { Button, makeStyles } from "@material-ui/core";

import React from "react";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-error": {
      marginLeft: 47,
    },
  },
  link: {
    marginLeft: 47,
    padding: 0,
    fontSize: 12,
    color: grey[600],
  },
}));
const BooleanWithLinkInput = ({ link, linkLabel, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BooleanInput {...props} />
      <Button
        className={classes.link}
        target="_blank"
        component="a"
        href={link}
      >
        {translate(linkLabel)}
      </Button>
    </div>
  );
};
export default BooleanWithLinkInput;
