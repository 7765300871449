import ResponsiveList from "components/lists/ResponsiveList";
import { useListStyles, useSelector } from "components/lists/useStyles";
import React, { Fragment } from "react";
import { DateField, EditButton, List as RaList, TextField } from "react-admin";
import perPage from "utils/perPage";
import Filters from "./Filters";

const List = (props) => {
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const classes = useListStyles({ sidebarOpen });
	return (
		<RaList
			{...props}
			filters={<Filters />}
			classes={classes}
			perPage={perPage(25, 10)}
		>
			<ResponsiveList
				mobileHeader={
					<Fragment>
						<TextField source="name" />
					</Fragment>
				}
				mobileBody={
					<Fragment>
						<DateField source="modified" showTime />
					</Fragment>
				}
				mobileActions={
					<Fragment>
						<EditButton />
					</Fragment>
				}
			>
				<TextField source="id" />
				<TextField source="name" variant="subtitle2" />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
