import React, { useMemo } from "react";

import { SelectInput } from "react-admin";
import useWorkflow from "../useWorkflow";

const StateInput = (props) => {
  const workflow = useWorkflow(props);
  const states = useMemo(
    () => (workflow ? workflow.states.filter((s) => !s.isLoop) : []),
    [workflow]
  );
  return (
    <SelectInput
      {...props}
      choices={states}
      optionText="name"
      optionValue="code"
    />
  );
};
export default StateInput;
