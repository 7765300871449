import { useSelector as _useSelector } from "react-redux";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core";

export const useSelector = _useSelector;

const _useListStyles = makeStyles(
  (theme) => ({
    // main: {},
    // rowEven: { backgroundColor: red[500] },
    [theme.breakpoints.up("sm")]: {
      main: (props) =>
        props.nowrap
          ? {
              "& table th": {
                verticalAlign: "bottom",
              },
            }
          : {
              "& table td, table th": {
                whiteSpace: "nowrap",
              },
            },
    },
    [theme.breakpoints.down("sm")]: {
      main: {
        background: theme.palette.background.default,
      },
      content: {
        background: theme.palette.background.default,
        boxShadow: "none",
      },
    },
  }),
  { name: "RaMobileList" }
);

export const useListStyles = (props) => _useListStyles(props);

const _useDatagridStyles = makeStyles(
  () => ({
    rowEven: { backgroundColor: grey[100] },
  }),
  { name: "RaDatagrid" }
);

export const useDatagridStyles = () => _useDatagridStyles();

const _useFiltersStyles = makeStyles(
  (theme) => ({
    [theme.breakpoints.down("sm")]: {
      form: {
        width: "100%",
        "& .filter-field": {
          flexShrink: 0,
          flexGrow: 1,
          width: "100%",
          "& .RaFilterFormInput-spacer-56": {
            display: "none",
          },
        },
      },
    },
  }),
  { name: "RaFilters" }
);

export const useFiltersStyles = () => _useFiltersStyles();
const _useListContentStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("xs")]: {
    root: {
      margin: 0,
      marginTop: theme.spacing(1),
    },
  },
  table: {
    whiteSpace: "nowrap",
  },
  rowEven: { backgroundColor: grey[100] },
}));

export const useListContentStyles = () => _useListContentStyles();
