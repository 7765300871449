import React from "react";
const { SelectInput } = require("ra-ui-materialui");

const STATUSES = [
  {
    id: "approved",
    name: "resources.user-profile-approvals.status.approved",
  },
  {
    id: "pending",
    name: "resources.user-profile-approvals.status.pending",
  },
  {
    id: "rejected",
    name: "resources.user-profile-approvals.status.rejected",
  },
];

const StatusInput = (props) => (
  <SelectInput {...props} choices={STATUSES} optionText="name" />
);
export default StatusInput;
