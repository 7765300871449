import { LandingIcon } from "icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
	icon: LandingIcon,
	options: {
		group: "campaigns",
		roles: ["admin", "advertiser"],
	},
	list: List,
	edit: Edit,
	create: Create,
};
