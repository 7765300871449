import { Box, IconButton } from "@material-ui/core";
import React, { Fragment } from "react";

import { Link } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import projectLogo from "../../assets/images/logo512.png";
import { useTranslate } from "react-admin";

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  const translate = useTranslate();

  return (
    <Fragment>
      <div
        className={clsx("app-header-logo", {
          "app-header-logo-close": sidebarToggle,
          "app-header-logo-open": sidebarHover,
        })}
      >
        <Box
          className="header-logo-wrapper"
          title={translate("app.description")}
        >
          <Link to="/" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn"
            >
              <img
                className="app-header-logo-img"
                alt={translate("app.description")}
                src={projectLogo}
              />
            </IconButton>
          </Link>
          <Box className="header-logo-text">{translate("app.name")}</Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(HeaderLogo);
