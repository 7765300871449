import { useMediaQuery } from "@material-ui/core";
import DateRangeInput from "components/inputs/DateRangeInput";
import { Filter } from "ra-ui-materialui";
import React from "react";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes} variant="outlined">
			<DateRangeInput
				source="view"
				displayedFilters={props.displayedFilters}
				filterValues={props.filterValues}
				setFilters={props.setFilters}
				alwaysOn
				fullWidth
				style={!sm ? { marginTop: 32 } : { marginTop: 0 }}
			/>
		</Filter>
	);
};
export default Filters;
