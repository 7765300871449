import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Menu,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import {
  useAuthProvider,
  useGetIdentity,
  useLogout,
  useTranslate,
} from "react-admin";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { UserIcon } from "icons";
import { get } from "lodash";
import useImpersonate from "useImpersonate";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

const StyledBadge = withStyles({
  badge: {
    backgroundColor: "var(--success)",
    color: "var(--success)",
    boxShadow: "0 0 0 2px #fff",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
})(Badge);
export default function HeaderUserbox() {
  const authProvider = useAuthProvider();
  const logout = useLogout();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const stopImpersonate = () =>
    authProvider.stopImpersonate().then(() => document.location.reload());

  const translate = useTranslate();
  const impersonate = useImpersonate();
  const { loading, identity } = useGetIdentity();

  const [picture, setPicture] = useState(get(identity, "picture.file.path"));
  const viewVersion = useSelector((state) =>
    get(state, "admin.ui.viewVersion")
  );
  useEffect(() => {
    const identity = JSON.parse(localStorage.getItem("profile"));
    const picture = get(identity, "picture.file.path");
    setPicture(picture);
  }, [viewVersion]);
  if (loading || identity === null) {
    return null;
  }
  return (
    <Fragment key={viewVersion}>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center"
      >
        <Box>
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {!picture && <UserIcon />}
            {picture && (
              <Avatar
                sizes="44"
                alt={`${identity.name} ${identity.surname}`}
                src={picture}
              />
            )}
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">
            {identity.name} {identity.surname}
          </div>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={["fas", "angle-down"]} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handleClose}
        className="ml-2"
      >
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                {!picture && <UserIcon />}
                {picture && (
                  <Avatar
                    sizes="44"
                    alt={`${identity.name} ${identity.surname}`}
                    src={picture}
                  />
                )}
              </StyledBadge>
            </Box>
            <div className="pl-3">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {identity.name} {identity.surname}
              </div>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem
              button
              component={Link}
              to="/user-profile"
              className="mb-0"
              onClick={handleClose}
            >
              {translate("ra.auth.profile")}
            </ListItem>
            <ListItem button onClick={logout}>
              {translate("ra.auth.logout")}
            </ListItem>
            {impersonate && (
              <ListItem button onClick={stopImpersonate}>
                {translate("ra.auth.stop_impersonate")}
              </ListItem>
            )}
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
