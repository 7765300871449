import LinkIcon from "@material-ui/icons/Link";
import * as React from "react";
import { Button } from "react-admin";

const PreviewButton = ({ record, ...props }) => {
	return (
		<Button
			label="resources.landings.action.preview"
			variant="text"
			href={record.preview_url}
			target="_blank"
			rel="noopener noreferer"
		>
			<LinkIcon />
		</Button>
	);
};

export default PreviewButton;
