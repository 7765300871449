import { get } from "lodash";
import { useMemo } from "react";
const { default: useRouter } = require("./useRouter");

const useQueryString = () => {
  const router = useRouter();
  const search = get(router, "location.search");

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryString;
