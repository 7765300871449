import * as React from "react";

import { Children, Fragment, isValidElement } from "react";

import { Grid } from "@material-ui/core";
import Masonry from "react-masonry-css";
import PropTypes from "prop-types";
import { Toolbar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const Wrapper = ({ isMasonry, isGrid, spacing, children }) => {
  if (isMasonry) {
    return (
      <Masonry
        breakpointCols={{
          default: 4,
          1100: 3,
          700: 2,
          500: 1,
        }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {children}
      </Masonry>
    );
  }

  if (isGrid) {
    return <>{children}</>;
  }

  return (
    <Grid container spacing={spacing}>
      {children}
    </Grid>
  );
};

export const CardFormView = (props) => {
  const {
    basePath,
    children,
    className,
    classes: classesOverride,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    mutationMode,
    pristine,
    record,
    redirect: defaultRedirect,
    resource,
    saving,
    submitOnEnter,
    toolbar,
    undoable,
    variant,
    margin,
    validating,
    spacing,
    masonry,
    grid,
    ...rest
  } = props;
  const classes = useCardFormViewStyles(props);
  const toolbarClasses = useToolbarStyles();
  return (
    <form {...sanitizeRestProps(rest)}>
      <div className={classes.content}>
        <Wrapper spacing={spacing} isMasonry={masonry} isGrid={grid}>
          {Children.map(children, (card, index) => {
            if (!card) {
              return;
            }
            return (
              <Fragment>
                {isValidElement(card)
                  ? React.cloneElement(card, {
                      classes,
                      resource,
                      record,
                      basePath,
                      variant: card.props.variant || variant,
                      margin: card.props.margin || margin,
                      value: index,
                      masonry,
                      grid,
                    })
                  : null}
              </Fragment>
            );
          })}
        </Wrapper>
      </div>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          className: toolbarClasses.root,
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          mutationMode,
          pristine,
          record,
          redirect: defaultRedirect,
          resource,
          saving,
          submitOnEnter,
          validating,
          undoable,
        })}
    </form>
  );
};

export const useCardFormViewStyles = makeStyles(
  (theme) => ({
    errorCardButton: { color: theme.palette.error.main },
    content: {},
  }),
  { name: "RaCardForm" }
);

const useToolbarStyles = makeStyles(
  (theme) => ({
    root: {
      position: "sticky",
      bottom: 0,
      borderColor: theme.palette.grey[300],
      borderWidth: 1,
      borderStyle: "solid",
      marginLeft: "0!important",
      zIndex: 11,
    },
  }),
  {
    name: "RaCardForm-Toolbar",
  }
);

CardFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  handleSubmit: PropTypes.func, // passed by react-final-form
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  invalid: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  mutationMode: PropTypes.oneOf(["pessimistic", "optimistic", "undoable"]),
  pristine: PropTypes.bool,
  // @ts-ignore
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.number,
  version: PropTypes.number,
  spacing: PropTypes.number,
  masonry: PropTypes.bool,
  grid: PropTypes.bool,
};

CardFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar />,
  spacing: 2,
  masonry: false,
  grid: false,
};

const sanitizeRestProps = ({
  active,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  error,
  errors,
  form,
  hasSubmitErrors,
  hasValidationErrors,
  initialValues,
  modified = null,
  modifiedSinceLastSubmit,
  save = null,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touched = null,
  valid,
  values,
  visited = null,
  __versions = null,
  ...props
}) => props;
