import ContentCreate from "@material-ui/icons/Create";
import ContentView from "@material-ui/icons/Visibility";
import { EditButton as RaEditButton } from "react-admin";
import React from "react";
import { useRoles } from "../../../authProvider";
import useWorkflow from "../useWorkflow";

const EditButton = ({ record, resource, ...props }) => {
  const workflow = useWorkflow({ resource });
  const roles = useRoles();
  if (!workflow) {
    return <RaEditButton disabled={true} />;
  }

  const canEdit = workflow.canEdit(roles, record);
  const label = canEdit ? "ra.action.edit" : "ra.action.view";
  const icon = canEdit ? <ContentCreate /> : <ContentView />;
  return <RaEditButton icon={icon} label={label} record={record} {...props} />;
};

export default EditButton;
