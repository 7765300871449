import React from "react";
import SYMBOLS from "commissions/Symbols";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const AmountField = ({ source, record, basePath, ...props }) => {
  const event = get(record, "event");
  const symbol = get(SYMBOLS, get(event, "value_type"), get(event, "name"));

  return (
    <Typography {...props}>
      {get(record, source)} {symbol}
    </Typography>
  );
};
export default AmountField;
