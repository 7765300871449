import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { Fragment, useState } from "react";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import PropTypes from "prop-types";
import classnames from "classnames";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    // overflow: "hidden",
    // "text-overflow": "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    whiteSpace: "break-spaces",
  },
  public: {
    color: theme.palette.success.main,
  },
  private: {
    color: theme.palette.secondary.main,
  },
  readAllButton: {
    margin: 0,
    padding: 0,
  },
}));
const NotesField = ({
  admin = false,
  record,
  source,
  minWidth = 150,
  maxRows = 3,
  sortable,
  basePath,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const isPrivate = get(record, "is_private", true);
  const value = isPrivate && !admin ? null : get(record, source, "");
  const tooLong = value !== null && value.length > 200;
  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => setShowMore(!showMore);

  return (
    <Fragment>
      <Typography
        {...props}
        component="pre"
        className={classes.root}
        style={{
          minWidth,
        }}
      >
        {tooLong && !showMore
          ? value.substr(0, 200) + "..."
          : value === null
          ? translate("resources.workflow.fields.notes.empty")
          : value}
        {tooLong && <br />}
        {tooLong && (
          <Button
            endIcon={showMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            className={classes.readAllButton}
            variant="text"
            color="secondary"
            size="small"
            component="span"
            onClick={handleShowMore}
          >
            {translate(!showMore ? "app.view_all" : "app.view_less")}
          </Button>
        )}
      </Typography>
      {admin && (
        <Typography
          variant="caption"
          className={classnames(isPrivate ? classes.private : classes.public)}
        >
          {translate(
            `resources.workflow.fields.is_private.${isPrivate ? "yes" : "no"}`
          )}
        </Typography>
      )}
    </Fragment>
  );
};

NotesField.propTypes = {
  minWidth: PropTypes.number,
  maxRows: PropTypes.number,
};

export default NotesField;
