import { NumberInput, required, useRedirect, useTranslate } from "react-admin";

import { CardForm } from "components/forms/cards/CardForm";
import { CommissionIcon } from "icons";
import { FormCard } from "components/forms/cards/FormCard";
import ImageInput from "components/inputs/ImageInput";
import MoneyInput from "components/inputs/MoneyInput";
import React from "react";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import TextInput from "components/inputs/TextInput";
import { makeRedirect } from "../components/fields/ListField";

const Form = (props) => {
  const { record } = props;
  const redirect = useRedirect();
  const translate = useTranslate();
  return (
    <CardForm
      {...props}
      variant="outlined"
      toolbar={
        <ReferenceToolbar
          backRedirect="/campaigns"
          backReference="campaigns"
          backReferenceTarget="campaign_id"
          backTab={0}
        />
      }
      redirect={() => redirect(makeRedirect({ ...props, tab: 0 }))}
    >
      <FormCard
        {...props}
        size={4}
        offset={4}
        label={translate(`resources.${props.resource}.name`, 1)}
        icon={<CommissionIcon />}
        initialValues={{ ...record }}
      >
        <TextInput
          variant="outlined"
          source="name"
          validate={required()}
          maxLength={100}
          fullWidth
        />
        <MoneyInput
          variant="outlined"
          source="price"
          validate={required()}
          fullWidth
        />
        <MoneyInput
          variant="outlined"
          source="strikethrough_price"
          validate={required()}
          helperText="resources.campaign-offers.helpers.strikethrough_price"
          fullWidth
        />
        <NumberInput
          variant="outlined"
          source="quantity"
          validate={required()}
          fullWidth
        />
        <ImageInput variant="outlined" source="picture" fullWidth />
      </FormCard>
    </CardForm>
  );
};
export default Form;
