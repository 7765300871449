import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Popover,
  Typography
} from "@material-ui/core";
import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useQuery, useTranslate } from "ra-core";
import React, { Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import NotificationMessage from "./NotificationMessage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function HeaderDots() {
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);

  const translate = useTranslate();
  const stats = useQuery({
    resource: "notifications/stats",
    type: "getOne",
    payload: {
      id: "me",
    },
  });
  const unreaded = get(stats, "data.unreaded");
  const messages = get(stats, "data.notifications", []);
  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
        <Hidden smDown>
          <Box component="span" pr="2">
            <IconButton
              onClick={handleOpen}
              color="inherit"
              className="btn-inverse mx-1 d-50"
            >
              <div className="badge badge-pill badge-warning badge-header">
                {unreaded}
              </div>
              <NotificationsActiveTwoToneIcon />
            </IconButton>
            <Popover
              open={open1}
              anchorEl={anchorEl1}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                paper: "app-header-dots",
              }}
            >
              <div className="popover-custom-xl overflow-hidden">
                <div className="bg-composed-wrapper border-0 text-center rounded-sm m-1" style={{backgroundColor: "#3c4043"}}>
                  <div className="bg-composed-wrapper--content text-light px-2 py-4">
                    <h4 className="font-size-xl font-weight-bold mb-2">
                      {translate("resources.notifications.name", {
                        smart_count: 2,
                      })}
                    </h4>
                    <p
                      className="opacity-8 mb-0"
                      dangerouslySetInnerHTML={{
                        __html: translate("resources.notifications.message", {
                          unreaded,
                          smart_count: unreaded,
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="height-280">
                  <PerfectScrollbar>
                    <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                      {messages.map((message) => (
                        <NotificationMessage
                          message={message}
                          key={message.id}
                          onClick={handleClose}
                        />
                      ))}
                    </div>
                  </PerfectScrollbar>
                </div>
                <Divider />
                <div className="text-center py-3">
                  <Button
                    color="primary"
                    variant="contained"
                    className="px-4"
                    component={Link}
                    onClick={handleClose}
                    to="/notifications"
                  >
                    <span className="btn-wrapper--label">
                      {translate("resources.notifications.view_all")}
                    </span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                    </span>
                  </Button>
                </div>
              </div>
            </Popover>
          </Box>
        </Hidden>
      </div>
    </Fragment>
  );
}
