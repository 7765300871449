import * as React from "react";

import { ButtonGroup, makeStyles } from "@material-ui/core";
import {
  CreateButton,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from "react-admin";

import AppsIcon from "@material-ui/icons/Apps";
import Button from "@material-ui/core/Button";
import ListIcon from "@material-ui/icons/List";
import { cloneElement } from "react";

const useStyles = makeStyles((theme) => ({
  createButton: {
    marginRight: theme.spacing(2),
  },
}));

const Actions = ({ selected, onSelect, isPublisher, ...props }) => {
  const classes = useStyles();
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    basePath,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {!isPublisher && (
        <CreateButton className={classes.createButton} basePath={basePath} />
      )}
      <ButtonGroup
        variant="outlined"
        color="secondary"
        size="small"
        aria-label="list type"
      >
        <Button
          onClick={() => onSelect("grid")}
          color="primary"
          variant={selected === "grid" ? "contained" : "outlined"}
        >
          <AppsIcon />
        </Button>
        <Button
          onClick={() => onSelect("list")}
          color="primary"
          variant={selected === "list" ? "contained" : "outlined"}
        >
          <ListIcon />
        </Button>
      </ButtonGroup>
    </TopToolbar>
  );
};

export default Actions;
