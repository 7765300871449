import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMutation, useNotify, useRefresh, useTranslate } from "react-admin";

import PropTypes from "prop-types";
import { get } from "lodash";

const ApprovalDialog = ({ record, onClose }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const approval = get(record, "approval", { id: 0 });
  const defaultNotes = get(record, "approval.notes", "");
  const [notes, setNotes] = useState(defaultNotes);
  useEffect(() => setNotes(get(record, "approval.notes", "")), [record]);
  const handleChange = (e) => setNotes(e.target.value);
  const handleClose = () => onClose();
  const approvalType = get(approval, "id", 0) > 0 ? "update" : "create";
  const [requestApproval, { loading }] = useMutation(
    {
      type: approvalType,
      resource: "users-campaigns",
      payload: {
        id: get(approval, "id"),
        data: {
          ...(approval ?? {
            campaign_id: get(record, "id"),
          }),
          notes,
        },
      },
    },
    {
      onSuccess: () => {
        onClose();
        refresh();
        notify(translate(`resources.users-campaigns.messages.${approvalType}`));
      },
      onFailure: () => {
        onClose();
        notify(translate("resources.users-campaigns.messages.fail"), "warning");
      },
    }
  );

  return (
    <Dialog open={record !== null} maxWidth="sm" fullWidth>
      <DialogTitle>
        {translate(`resources.users-campaigns.titles.${approvalType}`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate("resources.users-campaigns.help")}
        </DialogContentText>
        <TextField
          value={notes}
          onChange={handleChange}
          autoFocus
          required
          margin="dense"
          id="notes"
          label={translate("resources.users-campaigns.fields.notes")}
          type="text"
          multiline
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={requestApproval} disabled={loading}>
          {translate(`resources.users-campaigns.buttons.${approvalType}`)}
        </Button>
        <Button onClick={handleClose} disabled={loading}>
          {translate("ra.action.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ApprovalDialog.propTypes = {
  record: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
export default ApprovalDialog;
