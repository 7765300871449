import { ShowButton as RaShowButton, useTranslate } from "react-admin";
import React, { Fragment, useState } from "react";

import ApprovalDialog from "../dialogs/ApprovalDialog";
import { Button } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { get } from "lodash";

const ShowButton = ({ record, ...props }) => {
  const isApprovalRequired = get(record, "is_approval_required");
  const status = get(record, "approval.status", "required");
  const [campaign, setCampaign] = useState(null);
  const handleOpen = () =>
    setCampaign(status === "pending" || status === "required" ? record : null);
  const handleClose = () => setCampaign(null);
  const translate = useTranslate();

  if (!isApprovalRequired || status === "approved") {
    return <RaShowButton record={record} {...props} />;
  }
  const approvalStatus = get(record, "approval.status", "required");
  return (
    <Fragment>
      <Button
        startIcon={
          status === "rejected" ? <SentimentVeryDissatisfiedIcon /> : null
        }
        onClick={handleOpen}
      >
        {translate(`resources.users-campaigns.buttons.${approvalStatus}`)}
      </Button>
      <ApprovalDialog record={campaign} onClose={handleClose} />
    </Fragment>
  );
};

export default ShowButton;
