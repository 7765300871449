import React from 'react';
const { Fragment } = require('react');
const { ClimbingBoxLoader } = require('react-spinners');

const SuspenseLoading = () => {
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#5383ff'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait while we load the live preview examples
          <span className="font-size-lg d-block text-dark">
            This live preview instance can be slower than a real production
            build!
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default SuspenseLoading;
