import React from "react";

import classnames from "classnames";
import { get } from "lodash";
import {makeStyles} from "@material-ui/core";
import useWorkflow from "../useWorkflow";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary[500],
  },
}));

const StateInfoField = ({ resource, record }) => {
  const classes = useStyles();
  const workflow = useWorkflow({ resource });
  if (!workflow) {
    return null;
  }
  const state = workflow.getState(record);
  if (!state) {
    return null;
  }
  return (
    <MuiAlert className={classnames("mb-4", classes.root)} severity="success">
      <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">{get(state, "name")}</strong>
            {get(state, "description")}
          </span>
      </div>
    </MuiAlert>
  );
};
export default StateInfoField;
