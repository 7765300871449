import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";
const ApprovalDialog = ({ record, onCloseRequest }) => {
	const translate = useTranslate();
	const handleClose = () => onCloseRequest();
	return (
		<Dialog open={record !== null} maxWidth="sm" fullWidth>
			<DialogTitle>
				{translate("resources.campaign-approvals.titles.view")}
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">{get(record, "notes")}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{translate("ra.action.close")}</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ApprovalDialog;
