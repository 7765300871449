import * as React from 'react';

import { API_URL } from './config';
import { useLocale } from 'react-admin';

const queued = [];

const putMessage = (locale, message) =>
  queued.indexOf(`${locale}-${message}`) === -1 &&
  queued.push(`${locale}-${message}`) &&
  fetch(`${API_URL}/languages/put-message`, {
    method: 'PUT',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      code: locale,
      message: {
        code: message,
        text: message
      }
    })
  });

const useI18nCatcher = () => {
  const locale = useLocale();
  React.useMemo(() => {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    const consoleError = console.error;
    console.error = function(message) {
      if (
        typeof message === 'string' &&
        message.indexOf('Missing translation for key: ') >= 0
      ) {
        message = message.replace('Warning: Missing translation for key: ', '');
        message = message
          .split('"')
          .join('')
          .trim();
        if (message.indexOf(' ') !== -1) {
          return;
        }
        const lc = localStorage.getItem('locale') || locale;
        putMessage(lc, message);
        return;
      }

      consoleError.apply(console, arguments);
    };
  }, [locale]);
  return true;
};

export default useI18nCatcher;
