import { CampaignIcon, CategoryIcon, UserIcon } from "../icons";
import { Edit as RaEdit, usePermissions } from "react-admin";

import { CardForm } from "../components/forms/cards/CardForm";
import ChangesField from "./fields/ChangesField";
import { FormCard } from "../components/forms/cards/FormCard";
import { FormGrid } from "../components/forms/cards/FormGrid";
import { FormGridColumn } from "../components/forms/cards/FormGridColumn";
import OrderItemsInput from "./inputs/OrderItemsInput";
import PaymentMethodInput from "./inputs/PaymentMethodInput";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import StatusInput from "./inputs/StatusInput";
import TextInput from "components/inputs/TextInput";
import Title from "../components/Title";
import { makeStyles } from "@material-ui/core";
import { useFormStyles } from "../components/forms/useStyles";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "inline-block",
    float: "left",
    width: 255,
    marginRight: theme.spacing(2),
  },
  inputBlock: {
    display: "block",
    float: "left",
    width: "calc(50% - " + theme.spacing(2) + "px)",
    marginRight: theme.spacing(2),
    height: 41,
    "& .MuiInputBase-root": {
      width: "100%",
    },
  },
  inputShort: {
    width: 130,
  },
  inputLong: {
    width: 380,
  },
  clear: {
    clear: "right",
  },
}));

const Form = ({ ...props }) => {
  const formClasses = useStyles();
  const { loaded, permissions } = usePermissions();
  const disabled = !loaded || !permissions(["admin", "advertiser"]);
  return (
    <CardForm {...props} grid={true} variant="outlined">
      <FormGrid container spacing={2}>
        <FormGridColumn size={5}>
          <FormCard
            label="resources.orders.tabs.general"
            icon={<CategoryIcon />}
          >
            <TextInput
              className={formClasses.inputBlock}
              source="code"
              disabled={disabled}
            />
            <StatusInput
              className={formClasses.inputBlock}
              label="resources.orders.fields.status"
              source="stats[0].status"
              disabled={disabled}
            />
            <PaymentMethodInput source="payment_method" />
            <TextInput
              source="summary"
              multiline
              rows={2}
              fullWidth
              disabled={disabled}
            />
            <TextInput
              source="notes"
              multiline
              rows={4}
              fullWidth
              disabled={disabled}
            />
          </FormCard>
        </FormGridColumn>
        <FormGridColumn size={7}>
          <FormCard label="resources.orders.tabs.customer" icon={<UserIcon />}>
            <TextInput
              className={formClasses.input}
              source="customer.first_name"
              disabled={disabled}
              fullWidth
            />
            <TextInput source="customer.last_name" disabled={disabled} />
            <TextInput
              className={[formClasses.input, formClasses.inputLong]}
              source="customer.address"
              disabled={disabled}
              fullWidth
            />
            <TextInput
              className={[formClasses.input, formClasses.inputShort]}
              source="customer.province"
              disabled={disabled}
              fullWidth
            />

            <TextInput
              className={[
                formClasses.clear,
                formClasses.input,
                formClasses.inputShort,
              ]}
              source="customer.zip_code"
              disabled={disabled}
            />
            <TextInput
              className={[formClasses.clear, formClasses.input]}
              source="customer.city"
              disabled={disabled}
              fullWidth
            />
            <TextInput
              className={formClasses.input}
              source="customer.phone"
              disabled={disabled}
              fullWidth
            />
            <TextInput
              className={formClasses.input}
              source="customer.email"
              disabled={disabled}
              fullWidth
            />
            <TextInput
              source="customer.notes"
              fullWidth
              multiline
              rows={3}
              disabled={disabled}
            />
          </FormCard>
        </FormGridColumn>
      </FormGrid>

      <FormCard label="resources.orders.tabs.items" icon={<CampaignIcon />}>
        <OrderItemsInput source="order_items" fullWidth disabled={disabled} />
      </FormCard>
      <FormCard label="resources.orders.tabs.logs" icon={<SearchIcon />}>
        <ChangesField />
      </FormCard>
    </CardForm>
  );
};

const Edit = ({ ...props }) => {
  const classes = useFormStyles();
  return (
    <RaEdit
      {...props}
      classes={classes}
      redirect={false}
      title={<Title source="code" />}
      component="div"
    >
      <Form />
    </RaEdit>
  );
};
export default Edit;
