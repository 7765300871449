import LinkIcon from "@material-ui/icons/Link";
import get from "lodash/get";
const createMenuItem = (resource, badges) => ({
  badge: get(badges, `${resource.name}`, null),
  order: get(resource, "options.order", 0),
  label: resource.name,
  icon: resource.icon,
  to: `/${resource.name}`,
});
const createPages = (pages) => ({
  icon: LinkIcon,
  label: "pages",
  order: 10000,
  expanded: false,
  content: pages.map((page, i) => ({
    localize: false,
    badge: null,
    order: i,
    label: page.name,
    to: `/pages/${page.id}/show`,
  })),
});
const createGroups = (config, resources, permissions, badges, pages) => {
  let groups = []
    .concat(resources.filter((r) => r.hasList && r.options && r.icon))
    .filter(
      (item) =>
        permissions &&
        (item.options.roles === undefined ||
          item.options.roles.filter((role) => permissions(role)).length > 0)
    )
    .reduce((groups, resource) => {
      let groupName = resource.options ? resource.options.group : "";
      let group = groups.find((g) => g.label === groupName);
      if (group) {
        group.content.push(createMenuItem(resource, badges));
        group.content.sort((a, b) =>
          a.order > b.order ? 1 : a.order < b.order ? -1 : 0
        );
      } else {
        group = {
          icon: get(config, `[${groupName}].icon`),
          label: groupName,
          order: config[groupName].order || 1000,
          content: [createMenuItem(resource, badges)],
        };
        groups.push(group);
      }
      return groups;
    }, []);
  groups.push(createPages(pages));
  groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
  return [
    {
      label: "main",
      content: groups,
    },
  ];
};

export default createGroups;
