import { useMediaQuery } from "@material-ui/core";
import { useFiltersStyles } from "components/lists/useStyles";
import React from "react";
import { Filter, ReferenceInput, SelectInput } from "react-admin";

const Filters = (props) => {
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes} variant="outlined">
			<ReferenceInput
				source="contest_id"
				fullWidth
				reference="contests"
				resettable
				style={sm ? { marginRight: 16 } : { marginRight: 0 }}
			>
				<SelectInput optionText="title" />
			</ReferenceInput>
			<ReferenceInput
				source="campaign_id"
				reference="campaigns"
				filter={{ status: "active" }}
				fullWidth
				style={sm ? { marginRight: 16 } : { marginRight: 0 }}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
		</Filter>
	);
};
export default Filters;
