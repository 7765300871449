import ImageField from "components/fields/ImageField";
import CreateLinkButton from "landings/buttons/CreateLinkButton";
import { get } from "lodash";
import React from "react";
import { DateField, TextField, useRedirect } from "react-admin";
import ListField, { makeRedirect } from "../../components/fields/ListField";

const LandingsField = ({
	planned,
	canModify = false,
	canCreate = false,
	canRemove = false,
	showCreateLinkButton = true,
	...props
}) => {
	const redirect = useRedirect();
	const id = get(props, "record.id", 0);
	if (id <= 0) {
		return null;
	}
	return (
		<ListField
			{...props}
			show={true}
			reference="landings"
			target="campaign_id"
			canModify={canModify}
			canCreate={canCreate}
			canRemove={canRemove}
			sort={{ field: "id", order: "ASC" }}
			removeRedirect={() => redirect(makeRedirect({ ...props, tab: 3 }))}
			useLabel={false}
		>
			<TextField label="ID" source="id" />
			<ImageField
				source="preview.file.path"
				label="resources.campaigns.fields.preview_media_id"
			/>
			<TextField
				source="name"
				variant="subtitle2"
				style={{ fontWeight: "bold" }}
			/>
			<DateField source="created" showTime />
			{showCreateLinkButton && <CreateLinkButton />}
		</ListField>
	);
};

export default LandingsField;
