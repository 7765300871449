import { get } from "lodash";
import React from "react";
import { ReferenceInput, SelectInput } from "react-admin";
import { useFormState } from "react-final-form";

const EventInput = ({ ...props }) => {
	const formState = useFormState();
	const type = get(formState.values, "type", "none");
	return (
		<ReferenceInput
			{...props}
			reference="events"
			filter={{
				show_commission: true,
				type,
			}}
			sort={{ field: "name", direction: "DESC" }}
		>
			<SelectInput optionText={"name"} />
		</ReferenceInput>
	);
};
export default EventInput;
