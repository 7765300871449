import {
  Box,
  Breadcrumbs,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment } from "react";

import { DashboardIcon } from "icons";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import clsx from "clsx";
import { connect } from "react-redux";
import usePageTitleInfo from "./hook/usePageTitleInfo";

const PageTitle = (props) => {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription,
  } = props;

  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { breadcrumbs, Icon, helpMessage, title, sub } = usePageTitleInfo();

  return (
    <Fragment>
      <Paper
        square
        elevation={pageTitleShadow ? 6 : 1}
        className={clsx("app-page-title", pageTitleStyle, pageTitleBackground)}
      >
        <div>
          {pageTitleBreadcrumb && !sm && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              className="mb-4"
              maxItems={2}
              aria-label="breadcrumb"
            >
              {breadcrumbs.map((breadcrumb, index) =>
                breadcrumb.to && index < breadcrumbs.length ? (
                  <Link key={index} color="inherit" to={breadcrumb.to}>
                    {breadcrumb.label}
                  </Link>
                ) : (
                  <Typography key={index} color="textPrimary">
                    {breadcrumb.label || breadcrumb}
                  </Typography>
                )
              )}
            </Breadcrumbs>
          )}

          <Box className="app-page-title--first">
            {pageTitleIconBox && !sm && (
              <Paper
                elevation={2}
                className="app-page-title--iconbox d-70 d-flex align-items-center bg-secondary justify-content-center"
              >
                {Icon ? <Icon /> : <DashboardIcon />}
              </Paper>
            )}
            <div className="app-page-title--heading">
              {!title && <Typography variant="h1" id="react-admin-title" />}
              {title && <Typography variant="h1">{title}</Typography>}
              {!sm && helpMessage && (
                <div
                  className="app-page-title--description"
                  dangerouslySetInnerHTML={{ __html: helpMessage }}
                />
              )}
              {pageTitleDescription && !sm && (
                <div className="app-page-title--description">
                  {props.titleDescription}
                </div>
              )}
              {sub && <div className="app-page-title--description">{sub}</div>}
            </div>
          </Box>
        </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription,
});

export default connect(mapStateToProps)(PageTitle);
