import React from "react";
import { SelectInput } from "ra-ui-materialui";

const choices = ["pending", "approved", "rejected", "undefined"].map((id) => ({
  id,
  name: `resources.orders.status.${id}`,
}));

const StatusInput = (props) => <SelectInput {...props} choices={choices} />;
export default StatusInput;
