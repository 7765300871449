import React from "react";
import {
	EditButton,
	List as RaList,
	NumberField,
	TextField,
} from "react-admin";
import perPage from "utils/perPage";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import Actions from "./Actions";
import CloneButton from "./buttons/CloneButton";
import Filters from "./Filters";

const Datagrid = (props) => {
	return (
		<ResponsiveList
			{...props}
			mobileBody={
				<React.Fragment>
					<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
					<TextField
						source="contest.title"
						label="resources.contests-campaigns.fields.contest_id"
						style={{ fontWeight: "bold" }}
					/>
					<TextField
						source="campaign.name"
						label="resources.contests-campaigns.fields.campaign_id"
					/>
					<NumberField source="cpa_value" />
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<EditButton />
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			<TextField
				source="contest.title"
				label="resources.contests-campaigns.fields.contest_id"
				style={{ fontWeight: "bold" }}
			/>
			<TextField
				source="campaign.name"
				label="resources.contests-campaigns.fields.campaign_id"
				style={{ fontWeight: "bold" }}
			/>
			<NumberField source="cpa_value" />
			<EditButton />
			<CloneButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			actions={<Actions />}
			perPage={perPage()}
			exporter={false}
			sort={{ field: "id", order: "desc" }}
		>
			<Datagrid />
		</RaList>
	);
};
export default List;
