import { Filter } from "ra-ui-materialui";
import React from "react";
import DateRangeInput from "../components/inputs/DateRangeInput";
import { useFiltersStyles } from "../components/lists/useStyles";
import CampaignInput from "./inputs/CampaignInput";
import LandingInput from "./inputs/LandingInput";
import LinkInput from "./inputs/LinkInput";
import UserInput from "./inputs/UserInput";

const Filters = ({ permissions, ...props }) => {
	const classes = useFiltersStyles();

	const isAdmin = permissions && permissions(["admin"]);
	return (
		<Filter {...props} classes={classes} variant="outlined">
			<DateRangeInput
				source="view"
				displayedFilters={props.displayedFilters}
				filterValues={props.filterValues}
				setFilters={props.setFilters}
				alwaysOn
				fullWidth
			/>
			<CampaignInput source="campaign_id" fullWidth />
			<LandingInput
				source="landing_id"
				fullWidth
				filterValues={props.filterValues}
			/>
			<LinkInput source="link_id" fullWidth filterValues={props.filterValues} />
			{isAdmin && <UserInput source="user_id" />}
		</Filter>
	);
};

export default Filters;
