import moment from "moment";
import { StatIcon } from "../icons";
import List from "./List";

// Visibile agli utenti dalla data $target
const target = moment("2021-10-01 00:00:00");
const today = moment();
const showToUser = today.isSameOrAfter(target);
const roles = showToUser ? ["admin", "user"] : ["admin"];

export default {
	options: {
		group: "gamification",
		roles,
	},
	icon: StatIcon,
	list: List,
};
