import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useDatagridStyles, useSelector } from "./useStyles";

import { Datagrid } from "react-admin";
import Mobilegrid from "./Mobilegrid";
import PropTypes from "prop-types";
import React from "react";

const getWidthToSubtract = (w) => {
  return w + (window.innerWidth - document.documentElement.clientWidth);
};

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    maxWidth: `calc(100vw - ${getWidthToSubtract(
      !props.sidebarToggle && !props.isMd ? 316 : theme.spacing(4)
    )}px)`,
    overflowX: "auto",
    overflowY: "hidden",
    "& th": {
      backgroundColor: "transparent",
    },
    transition: "max-width .1s linear .1s",
  }),
}));

const renderChildren = (component, props) =>
  React.isValidElement(component) &&
  React.Children.map(component.props.children, (field, index) =>
    React.isValidElement(field)
      ? React.cloneElement(field, {
          key: field.props.source || index,
          ...props,
        })
      : null
  );

const ResponsiveList = ({
  ids,
  data,
  basePath,
  resource,
  children,
  mobileMedia,
  mobileMediaAction,
  mobileHeader,
  mobileActions,
  mobileBody,
  mobileBodyAction,
  breakpoint,
  hasList,
  hasEdit,
  hasShow,
  permissions,
  classes,
  syncWithLocation,
  type,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(breakpoint));
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isGrid = type === "grid";
  const usefulProps = {
    ids,
    data,
    basePath,
    resource,
  };
  const sidebarToggle = useSelector(
    (state) => state.ThemeOptions.sidebarToggle
  );

  const commonClasses = useStyles({
    sidebarToggle,
    isMd,
  });
  const defaultClasses = useDatagridStyles();

  if (
    (isMobile || isGrid) &&
    (React.isValidElement(mobileBody) || React.isValidElement(mobileHeader))
  ) {
    return (
      <Mobilegrid
        media={mobileMedia}
        mediaAction={mobileMediaAction}
        header={mobileHeader}
        actions={mobileActions}
        bodyAction={mobileBodyAction}
        {...usefulProps}
      >
        {renderChildren(mobileBody, usefulProps)}
      </Mobilegrid>
    );
  }
  return (
    <div className={commonClasses.container}>
      <Datagrid
        classes={classes || defaultClasses}
        resource={resource}
        {...props}
      >
        {React.Children.map(children, (field, index) =>
          React.isValidElement(field)
            ? React.cloneElement(field, {
                key: field.props.source || index,
                resource,
                basePath,
              })
            : null
        )}
      </Datagrid>
    </div>
  );
};

ResponsiveList.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.object,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  children: PropTypes.node.isRequired,
  mobileMedia: PropTypes.string,
  mobileMediaAction: PropTypes.func,
  mobileHeader: PropTypes.node,
  mobileActions: PropTypes.node,
  mobileBody: PropTypes.node,
  mobileBodyAction: PropTypes.func,
  breakpoint: PropTypes.string,
  classes: PropTypes.object,
  type: PropTypes.oneOf(["grid", "list"]),
};

ResponsiveList.defaultProps = {
  breakpoint: "sm",
  classes: undefined,
  type: "list",
};

export default ResponsiveList;
