import {Filter, ReferenceInput, SearchInput, SelectInput} from "react-admin";

import React from "react";
import {useFiltersStyles} from "../components/lists/useStyles";

const Filters = (props) => {
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes} variant="outlined">
      <SearchInput source="q" alwaysOn fullWidth/>
      <ReferenceInput
        source="language_id"
        reference="languages"
        alwaysOn
        fullWidth
      >
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </Filter>
  );
};
export default Filters;
