import { BooleanInput } from "react-admin";
import React from "react";

const NotesPrivateInput = ({ admin = false, ...props }) =>
  admin && (
    <BooleanInput
      label="resources.workflow.fields.is_private"
      helperText="resources.workflow.fields.is_private.help"
      source="is_private"
      {...props}
    />
  );

export default NotesPrivateInput;
