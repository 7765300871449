// in src/posts.js
import * as React from "react";

import { Show as RaShow, SimpleShowLayout } from "react-admin";

import ShowField from "./fields/ShowField";
import Title from "../components/Title";
import { useFormStyles } from "../components/forms/useStyles";

const Show = (props) => {
  const classes = useFormStyles();
  return (
    <RaShow
      title={<Title source="name" />}
      actions={null}
      {...props}
      classes={classes}
    >
      <SimpleShowLayout>
        <ShowField />
      </SimpleShowLayout>
    </RaShow>
  );
};

export default Show;
