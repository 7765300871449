import { Chip, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import {
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";

import { get } from "lodash";

const StatusField = ({ source, record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const status = get(record, source);
  const refresh = useRefresh();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleChange = (status) => {
    setAnchorEl(null);
    dataProvider
      .update("users/change-status", {
        data: {
          selectedIds: [record.id],
          status: status,
        },
      })
      .then(() => {
        refresh();
        notify(translate("resources.users.messages.status_changed"));
      })
      .catch(({ error: { message } }) => notify(message, "warning"));
  };

  return (
    <>
      <Chip
        onClick={handleClick}
        color={status === "active" ? "primary" : "default"}
        label={translate(`resources.users.status.${status}`)}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {["pending", "active", "banned", "suspended"].map((status) => (
          <MenuItem onClick={handleChange.bind(this, status)} key={status}>
            {translate(`resources.users.status.${status}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default StatusField;
