import { Divider, Typography } from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {
	DateField,
	Pagination,
	ReferenceManyField,
	TextField,
	useTranslate,
} from "react-admin";
import ResponsiveList from "../../lists/ResponsiveList";
import { useListContentStyles } from "../../lists/useStyles";
import useWorkflow from "../useWorkflow";
import NotesField from "./NotesField";
import StateField from "./StateField";

const PaginationWrapper = ({ fullWidth, addLabel, ...props }) => (
	<Pagination {...props} />
);

const TransactionLogsField = ({ admin = false, useLabel = true, ...props }) => {
	const translate = useTranslate();
	const classes = useListContentStyles();
	const { resource } = props;
	const workflow = useWorkflow({ resource });
	if (!workflow) {
		return null;
	}
	return (
		<div className={classnames(classes.root, classes.displayEmptyMessage)}>
			{useLabel && (
				<Typography variant="subtitle1">
					{translate("resources.workflow.fields.logs")}
				</Typography>
			)}
			<Divider />
			<ReferenceManyField
				perPage={5}
				pagination={<PaginationWrapper />}
				reference={`workflow/transactions/${resource}`}
				target="id"
				sort={{ field: "WorkflowTransactions.created", order: "desc" }}
				{...props}
			>
				<ResponsiveList classes={{ table: classes.table }}>
					<TextField label="resources.workflow.fields.id" source="id" />
					<DateField
						label="resources.workflow.fields.created"
						source="created"
						showTime
						sortBy="WorkflowTransactions.created"
					/>
					<TextField
						label="resources.workflow.fields.user"
						source="user.name"
						sortBy="Users.username"
					/>
					<NotesField
						label="resources.workflow.fields.notes"
						variant="body2"
						source="notes"
						admin={admin}
						sortable={false}
					/>

					<StateField
						label="resources.workflow.fields.state"
						sortBy="WorkflowTransactions.state"
					/>
				</ResponsiveList>
			</ReferenceManyField>
		</div>
	);
};

export default TransactionLogsField;
