import FileCopyIcon from "@material-ui/icons/FileCopy";
import { get } from "lodash";
import { useNotify } from "ra-core";
import { Button } from "ra-ui-materialui";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const UrlButton = ({ record, source, basePath, ...props }) => {
	const url = get(record, source, "");
	const notify = useNotify();
	return (
		<CopyToClipboard
			text={url}
			onCopy={() =>
				notify("resources.landings.actions.copied_url", "info", { url })
			}
		>
			<Button {...props}>
				<FileCopyIcon />
			</Button>
		</CopyToClipboard>
	);
};

export default UrlButton;
