import { library } from "@fortawesome/fontawesome-svg-core";
import {
	fab,
	faDiscord,
	faDribbble,
	faFacebook,
	faGithub,
	faGoogle,
	faHtml5,
	faInstagram,
	faPinterest,
	faReact,
	faSlack,
	faTwitter,
	faVuejs,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
	faAddressCard,
	faBell,
	faBuilding,
	faCalendarAlt,
	faChartBar,
	faCheckCircle,
	faClock,
	faCommentDots,
	faComments,
	faDotCircle,
	faEnvelope,
	faEye,
	faFileAlt,
	faFileArchive,
	faFileAudio,
	faFileCode,
	faFileExcel,
	faFileImage,
	faFilePdf,
	faFileVideo,
	faFileWord,
	faFolder,
	faFolderOpen,
	faGem,
	faImages,
	faKeyboard,
	faLifeRing,
	faLightbulb,
	faMap,
	faObjectGroup,
	faQuestionCircle,
	far,
	faSquare,
	faThumbsUp,
	faTimesCircle,
	faTrashAlt,
	faUser,
	faUserCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
	faAlignCenter,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrowsAltH,
	faArrowUp,
	faAward,
	faBars,
	faBatteryEmpty,
	faBatteryFull,
	faBirthdayCake,
	faBomb,
	faBusAlt,
	faCameraRetro,
	faCarBattery,
	faCaretRight,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCog,
	faCubes,
	faDownload,
	faEllipsisH,
	faEllipsisV,
	faExternalLinkAlt,
	faEyeDropper,
	faFilm,
	faHeart,
	faInfoCircle,
	faLemon,
	faLink,
	faMapMarkedAlt,
	faNetworkWired,
	faPager,
	faPlayCircle,
	faPlus,
	faPlusCircle,
	faPrint,
	faQuoteRight,
	faReply,
	fas,
	faSave,
	faSearch,
	faShapes,
	faShareSquare,
	faSignOutAlt,
	faSitemap,
	faSlidersH,
	faSmile,
	faStar,
	faStarHalfAlt,
	faSync,
	faTable,
	faTachometerAlt,
	faTimes,
	faUnlockAlt,
	faUpload,
} from "@fortawesome/free-solid-svg-icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import campaignMedia from "campaign-media";
import campaignOffers from "campaign-offers";
import campaigns from "campaigns";
import categories from "categories";
import commissions from "commissions";
import RecoverPasswordPage from "components/pages/RecoverPasswordPage";
import SignupPage from "components/pages/SignupPage";
import useLoadAll from "components/workflow/useLoadAll";
import { ConnectedRouter } from "connected-react-router";
import contestStats from "contest-stats";
import contests from "contests";
import contestsCampaigns from "contests-campaigns";
import dashboard from "dashboard";
import domains from "domains";
import { AnimatePresence } from "framer-motion";
import { createHashHistory } from "history";
import landings from "landings";
import links from "links";
import orders from "orders";
import PropTypes from "prop-types";
import * as React from "react";
import {
	Admin,
	AuthContext,
	DataProviderContext,
	Notification,
	Resource,
	TranslationProvider,
} from "react-admin";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import withContext from "recompose/withContext";
import rewards from "rewards";
import stats from "stats";
import MuiTheme from "theme";
import userParentApprovals from "user-parent-approvals";
import usersCampaigns from "users-campaigns";
import usersCampaignsPostbacks from "users-campaigns-postbacks";
import "./assets/base.scss";
import authProvider from "./authProvider";
import campaignBlacklist from "./campaign-lists/blacklist";
import campaignWhitelist from "./campaign-lists/whitelist";
import Layout from "./components/layout/Layout";
import LeftSidebar from "./components/layout/LeftSidebar";
import LoginPage from "./components/pages/LoginPage";
import SuspenseLoading from "./components/SuspenseLoading";
import createAdminStore from "./createAdminStore";
import dataProvider from "./dataProvider";
import events from "./events";
import i18nProvider from "./i18nProvider";
import languageMessages from "./language-messages";
import notifications from "./notifications";
import pages from "./pages";
import redirects from "./redirects";
import useI18nCatcher from "./useI18nCatcher";
import useLanguages from "./useLanguages";
import userProfileApprovals from "./user-profile-approvals";
import userProfiles from "./user-profiles";
import userReferrals from "./user-referrals";
import users from "./users";

library.add(
	far,
	faSquare,
	faLifeRing,
	faCheckCircle,
	faTimesCircle,
	faDotCircle,
	faThumbsUp,
	faComments,
	faFolderOpen,
	faTrashAlt,
	faFileImage,
	faFileArchive,
	faCommentDots,
	faFolder,
	faKeyboard,
	faCalendarAlt,
	faEnvelope,
	faAddressCard,
	faMap,
	faObjectGroup,
	faImages,
	faUser,
	faLightbulb,
	faGem,
	faClock,
	faUserCircle,
	faQuestionCircle,
	faBuilding,
	faBell,
	faFileExcel,
	faFileAudio,
	faFileVideo,
	faFileWord,
	faFilePdf,
	faFileCode,
	faFileAlt,
	faEye,
	faChartBar
);
library.add(
	fab,
	faFacebook,
	faTwitter,
	faVuejs,
	faReact,
	faHtml5,
	faGoogle,
	faInstagram,
	faPinterest,
	faYoutube,
	faDiscord,
	faSlack,
	faDribbble,
	faGithub
);
library.add(
	fas,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faSmile,
	faHeart,
	faBatteryEmpty,
	faBatteryFull,
	faChevronRight,
	faSitemap,
	faPrint,
	faMapMarkedAlt,
	faTachometerAlt,
	faAlignCenter,
	faExternalLinkAlt,
	faShareSquare,
	faInfoCircle,
	faSync,
	faQuoteRight,
	faStarHalfAlt,
	faShapes,
	faCarBattery,
	faTable,
	faCubes,
	faPager,
	faCameraRetro,
	faBomb,
	faNetworkWired,
	faBusAlt,
	faBirthdayCake,
	faEyeDropper,
	faUnlockAlt,
	faDownload,
	faAward,
	faPlayCircle,
	faReply,
	faUpload,
	faBars,
	faEllipsisV,
	faSave,
	faSlidersH,
	faCaretRight,
	faChevronUp,
	faPlus,
	faLemon,
	faChevronLeft,
	faTimes,
	faChevronDown,
	faFilm,
	faSearch,
	faEllipsisH,
	faCog,
	faArrowsAltH,
	faPlusCircle,
	faAngleRight,
	faAngleUp,
	faAngleLeft,
	faAngleDown,
	faArrowUp,
	faArrowDown,
	faArrowRight,
	faArrowLeft,
	faStar,
	faSignOutAlt,
	faLink
);

const history = createHashHistory();

const App = () => {
	useI18nCatcher();
	const { loading, languages } = useLanguages();
	const { loaded, data } = useLoadAll();
	if (loading) {
		return null;
	}

	if (!loaded) {
		return null;
	}
	return (
		<Provider
			store={createAdminStore({
				authProvider,
				dataProvider,
				history,
				data,
			})}
		>
			<AuthContext.Provider value={authProvider}>
				<DataProviderContext.Provider value={dataProvider}>
					<TranslationProvider
						locale="en"
						i18nProvider={i18nProvider(languages)}
					>
						<ThemeProvider theme={MuiTheme}>
							<AnimatePresence>
								<React.Suspense fallback={<SuspenseLoading />}>
									<ConnectedRouter history={history}>
										<CssBaseline />
										<Switch>
											<Route path="/login" component={LoginPage} />
											<Route
												path="/recover-password"
												component={RecoverPasswordPage}
											/>
											<LeftSidebar>
												<Admin
													layout={Layout}
													history={history}
													i18nProvider={i18nProvider(languages)}
													dataProvider={dataProvider}
													authProvider={authProvider}
													customRoutes={[
														<Route
															key="user-profile"
															path="/user-profile"
															component={userProfiles.edit}
														/>,
														<Route
															key="signup"
															noLayout
															options={{ menu: false }}
															path="/signup"
															component={SignupPage}
														/>,
													]}
												>
													<Resource name="dashboard" {...dashboard} />
													<Resource name="orders" {...orders} />
													<Resource name="stats" {...stats} />
													<Resource name="notifications" {...notifications} />
													<Resource name="campaigns" {...campaigns} />
													<Resource name="landings" {...landings} />
													<Resource name="commissions" {...commissions} />
													<Resource name="campaign-media" {...campaignMedia} />
													<Resource
														name="campaign-offers"
														{...campaignOffers}
													/>
													<Resource name="campaign-media-categories" />
													<Resource name="postback-events" />
													<Resource
														name="users-campaigns"
														{...usersCampaigns}
													/>
													<Resource
														name="users-campaigns-postbacks"
														{...usersCampaignsPostbacks}
													/>
													<Resource
														name="campaign-whitelists"
														{...campaignWhitelist}
													/>
													<Resource
														name="campaign-blacklists"
														{...campaignBlacklist}
													/>
													<Resource name="categories" {...categories} />
													<Resource name="domains" {...domains} />
													<Resource name="users" {...users} />
													<Resource
														name="user-parent-approvals"
														{...userParentApprovals}
													/>
													<Resource name="user-profile" />
													<Resource
														name="user-profile-approvals"
														{...userProfileApprovals}
													/>
													<Resource name="links" {...links} />
													<Resource name="events" {...events} />
													<Resource name="user-referrals" {...userReferrals} />
													<Resource name="pages" {...pages} />
													<Resource name="redirects" {...redirects} />
													<Resource
														name="language-messages"
														{...languageMessages}
													/>
													<Resource name="roles" />
													<Resource name="resources" />
													<Resource name="groups" />
													<Resource name="languages" />
													<Resource name="profile" />
													<Resource name="landing-editor" />
													<Resource name="pub/roles" />
													<Resource name="workflow/transactions/campaigns" />
													<Resource name="contest-stats" {...contestStats} />
													<Resource name="contests" {...contests} />
													<Resource name="rewards" {...rewards} />
													<Resource
														name="contests-campaigns"
														{...contestsCampaigns}
													/>
												</Admin>
											</LeftSidebar>
										</Switch>
									</ConnectedRouter>
								</React.Suspense>
							</AnimatePresence>
							<Notification />
						</ThemeProvider>
					</TranslationProvider>
				</DataProviderContext.Provider>
			</AuthContext.Provider>
		</Provider>
	);
};

export default withContext(
	{
		authProvider: PropTypes.object,
	},
	() => ({ authProvider })
)(App);
