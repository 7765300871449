import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormGroupContextProvider } from "ra-core";
import * as React from "react";
import { FormInput, useTranslate } from "react-admin";

const GridOffset = ({ size }) => {
	const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	if (isXs) {
		return;
	}
	return (
		<Grid item sm={size} xs={12}>
			&nbsp;
		</Grid>
	);
};

const Wrapper = ({ isMasonry, isGrid, children, ...props }) => {
	if (isMasonry || isGrid) {
		return <>{children}</>;
	}

	return <Grid {...props}>{children}</Grid>;
};

export const FormCard = ({
	basePath,
	className,
	classes,
	contentClassName,
	children,
	hidden,
	icon,
	label,
	helperText,
	margin,
	path,
	record,
	resource,
	variant,
	value,
	size,
	offset,
	masonry,
	grid,
	...rest
}) => {
	const gridClasses = useGridStyles();
	const translate = useTranslate();
	const theme = useTheme();
	const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	if (isXs) offset = 0;

	return (
		<FormGroupContextProvider name={value.toString()}>
			{offset > 0 && !masonry && <GridOffset size={offset} />}
			<Wrapper
				item
				xs={12}
				md={size}
				id={`cardgrid-${value}`}
				isMasonry={masonry}
				isGrid={grid}
				className={classes.root}
			>
				<Card
					className={contentClassName}
					style={grid ? { marginBottom: theme.spacing(2) } : {}}
				>
					{(label || helperText) && (
						<CardHeader
							avatar={icon}
							title={translate(label)}
							titleTypographyProps={{ variant: "h6" }}
							subheader={helperText ? translate(helperText) : null}
							subheaderTypographyProps={{ variant: "body2" }}
							className={gridClasses.cardHeader}
						/>
					)}
					<CardContent>
						{React.Children.map(
							children,
							(input) =>
								input && (
									<FormInput
										basePath={basePath}
										input={input}
										record={record}
										resource={resource}
										variant={input.props.variant || variant}
										margin={input.props.margin || margin}
									/>
								)
						)}
					</CardContent>
				</Card>
			</Wrapper>
		</FormGroupContextProvider>
	);
};

FormCard.propTypes = {
	basePath: PropTypes.string,
	className: PropTypes.string,
	contentClassName: PropTypes.string,
	children: PropTypes.node,
	hidden: PropTypes.bool,
	icon: PropTypes.element,
	label: PropTypes.string.isRequired,
	helperText: PropTypes.string,
	margin: PropTypes.oneOf(["none", "dense", "normal"]),
	path: PropTypes.string,
	record: PropTypes.object,
	resource: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
	size: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	offset: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	masonry: PropTypes.bool,
	grid: PropTypes.bool,
};

FormCard.defaultProps = {
	helperText: null,
	size: 6,
	offset: 0,
	masonry: false,
	grid: false,
};

FormCard.displayName = "FormCard";

const useGridStyles = makeStyles((theme) => ({
	cardHeader: {
		borderBottom: 1,
		borderBottomColor: theme.palette.grey[300],
		borderBottomStyle: "solid",
	},
}));
