import useHelpProvider from "components/useHelpProvider";
import useMenu from "./useMenu";
import { useSelector } from "react-redux";

const { useMemo } = require("react");
const { useTranslate, useGetResourceLabel } = require("react-admin");
const { default: useRouter } = require("utils/useRouter");
const { get } = require("lodash");

const usePageTitleInfo = () => {
  const {
    location: { pathname },
  } = useRouter();
  const translate = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const content = useMenu();
  const { hasHelp, message } = useHelpProvider("HelpProvider");
  const resources = useSelector((state) => state?.admin?.resources);
  // console.info("resources:", resources);
  return useMemo(() => {
    const args = pathname.split("/").filter((s) => s.length > 0);
    const resource = get(args, "[0]", "");

    const pageTitleConfig =
      resources[resource]?.props?.options?.pageTitleConfig;
    if (pageTitleConfig) {
      return pageTitleConfig;
    }
    const param = get(args, "[1]", "");
    const group = get(content, "[0].content", []).find((c) =>
      c.content.some((subContent) => subContent.label === resource)
    );
    const resourceInfo = get(group, "content", []).find(
      (res) => res.label === resource
    );
    const resourceIcon = get(resourceInfo, "icon");
    const groupIcon = get(group, "icon");
    const groupLabel = get(group, "label");
    const groupLabelTranslated = translate(`menu.items.${groupLabel}`);
    const resourceLabelTranslated =
      resource.length > 0 ? getResourceLabel(resource, 2) : "dashboard";
    const paramTranslationCode = `resources.${resource}.${
      param === "create" ? "create" : "edit"
    }`;
    const paramTranslated =
      (param && param.length > 0 && translate(paramTranslationCode)) ||
      undefined;
    let breadcrumbs = [
      (groupLabel && {
        to: `/${group.label}`,
        label: groupLabelTranslated,
      }) ||
        undefined,
      {
        to: `/${resource}`,
        label:
          groupLabelTranslated === resourceLabelTranslated
            ? translate(`resources.${resource}.list`)
            : translate(`menu.items.${resource}`) === `menu.items.${resource}`
            ? getResourceLabel(resource, 2)
            : translate(`menu.items.${resource}`),
      },
      (param &&
        param.length > 0 &&
        paramTranslated !== paramTranslationCode &&
        paramTranslated) ||
        undefined,
    ].filter((item) => item !== undefined);

    return {
      breadcrumbs,
      Icon: resourceIcon || groupIcon,
      helpMessage: hasHelp ? message : undefined,
    };
  }, [
    pathname,
    translate,
    content,
    getResourceLabel,
    resources,
    hasHelp,
    message,
  ]);
};
export default usePageTitleInfo;
