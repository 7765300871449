import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { get } from "lodash";
import React from "react";
import { useLocale, useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		maxWidth: 350,
		backgroundColor: "transparent",
	},
	details: {
		display: "flex",
		flexDirection: "column",
	},
	content: {
		flex: "1 0 auto",
	},
	cover: {
		width: 151,
	},
}));

const RewardField = ({ record, ...props }) => {
	const classes = useStyles();
	const reward = get(record, "reward");
	const summary = get(record, "summary");
	const translate = useTranslate();
	const locale = useLocale();
	const locales = `${locale}-${locale}`;
	if (summary) {
		return (
			<Typography color="textSecondary">
				{translate("resources.contests.fields.modified")}
			</Typography>
		);
	}
	if (!reward) {
		return (
			<Typography color="textSecondary">
				{translate("ra.action.none")} :(
			</Typography>
		);
	}
	return (
		<Card className={classes.root} elevation={0}>
			<CardMedia
				className={classes.cover}
				image={get(reward, "image.file.path")}
				title={get(reward, "name")}
			/>
			<div className={classes.details}>
				<CardContent className={classes.content}>
					<Typography component="h5" variant="h5">
						{get(reward, "name")}
					</Typography>
					<Typography variant="overline" color="textSecondary">
						{translate("resources.rewards.fields.price")}:{" "}
						{get(reward, "price", 0).toLocaleString(locales, {
							style: "currency",
							currency: "EUR",
						})}
					</Typography>
				</CardContent>
			</div>
		</Card>
	);
};

export default RewardField;
