import { UserProfileApprovalIcon } from "icons";
import List from "./List";

export default {
	icon: UserProfileApprovalIcon,
	list: List,
	options: {
		group: "admin",
		roles: ["admin"],
	},
};
