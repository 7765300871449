import { Box, makeStyles } from "@material-ui/core";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import { SelectInput, useTranslate } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { capitalize } from "underscore.string";
import { DateInput } from "./DatePickers";

const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_RANGE_EXPR = {
	today: () => ({
		start_at: moment().format(DATE_FORMAT),
		end_at: moment().format(DATE_FORMAT),
		day: null,
	}),
	yesterday: () => ({
		start_at: moment()
			.subtract(1, "days")
			.format(DATE_FORMAT),
		end_at: moment()
			.subtract(1, "days")
			.format(DATE_FORMAT),
		day: null,
	}),
	last_7_days: () => ({
		start_at: moment()
			.subtract(7, "days")
			.format(DATE_FORMAT),
		end_at: moment().format(DATE_FORMAT),
		day: null,
	}),
	last_30_days: () => ({
		start_at: moment()
			.subtract(30, "days")
			.format(DATE_FORMAT),
		end_at: moment().format(DATE_FORMAT),
		day: null,
	}),
	prev_week: () => ({
		start_at: moment()
			.subtract(1, "weeks")
			.startOf("isoWeek")
			.format(DATE_FORMAT),
		end_at: moment()
			.subtract(1, "weeks")
			.endOf("isoWeek")
			.format(DATE_FORMAT),
		day: null,
	}),
	this_month: () => ({
		name: (props, translate) =>
			translate(`${props.resource}.${props.source}.date_range.this_month`, {
				month: moment().format("MMMM"),
			}),
		start_at: moment()
			.startOf("month")
			.format(DATE_FORMAT),
		end_at: moment()
			.endOf("month")
			.format(DATE_FORMAT),
		day: null,
	}),
	// prev_month: () => ({
	//   name: (props, translate) =>
	//     translate(`${props.resource}.${props.source}.date_range.prev_month`, {
	//       month: capitalize(moment().format("MMMM")),
	//     }),
	//   start_at: moment()
	//     .subtract(1, "months")
	//     .startOf("month")
	//     .format(DATE_FORMAT),
	//   end_at: moment().subtract(1, "months").endOf("month").format(DATE_FORMAT),
	//   day: null,
	// }),
	last_month: () => ({
		name: (props, translate) =>
			translate(`${props.resource}.${props.source}.date_range.last_month`, {
				month: capitalize(
					moment()
						.subtract(1, "months")
						.format("MMMM")
				),
			}),
		start_at: moment()
			.subtract(1, "months")
			.startOf("month")
			.format(DATE_FORMAT),
		end_at: moment()
			.subtract(1, "months")
			.endOf("month")
			.format(DATE_FORMAT),
		day: null,
	}),
	last_two_months: () => ({
		name: (props, translate) =>
			translate(
				`${props.resource}.${props.source}.date_range.last_two_months`,
				{
					from: capitalize(
						moment()
							.subtract(1, "months")
							.format("MMMM")
					),
					to: capitalize(moment().format("MMMM")),
				}
			),
		start_at: moment()
			.subtract(1, "months")
			.startOf("month")
			.format(DATE_FORMAT),
		end_at: moment()
			.endOf("month")
			.format(DATE_FORMAT),
		day: null,
	}),
	range: () => ({
		start_at: moment()
			.startOf("month")
			.format(DATE_FORMAT),
		end_at: moment()
			.endOf("month")
			.format(DATE_FORMAT),
		day: null,
	}),
	day: () => ({
		start_at: null,
		end_at: null,
		day: moment().format(DATE_FORMAT),
	}),
};
const DATE_RANGES = (props, translate) =>
	Object.keys(DATE_RANGE_EXPR).map((id) => {
		const nameResolver = (props, translate) =>
			translate(`${props.resource}.${props.source}.date_range.${id}`);
		const expr = get(DATE_RANGE_EXPR, `${id}`, () => ({
			name: nameResolver,
		}))();
		const name = get(expr, "name", nameResolver)(props, translate);
		return {
			id,
			name,
		};
	});

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: "100%",
	},
	box: {
		marginRight: theme.spacing(1),
	},
	select: { marginTop: theme.spacing(4) },
	input: {
		marginTop: theme.spacing(4),
	},
	[theme.breakpoints.down("sm")]: {
		input: {
			marginTop: 0,
		},
		box: {
			width: "100%",
			marginRight: 0,
		},
	},
}));

const Wrapper = ({ sm = false, children, spacerTop }) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			{!sm && spacerTop && 1 == 0 && <Box mt={8} />}
			{!sm ? (
				<Box display="flex" alignItems="flex-start" flexWrap="wrap">
					{children}
				</Box>
			) : (
				<Box className={classes.wrapper}>{children}</Box>
			)}
		</React.Fragment>
	);
};

const DateRangeInput = ({
	displayedFilters,
	filterValues,
	setFilters,
	...props
}) => {
	const classes = useStyles();
	const translate = useTranslate();
	const formState = useFormState();
	const form = useForm();
	const source = props.source;
	const handleChange = (evt) => {
		const value = evt.target.value;
		const range = get(DATE_RANGE_EXPR, value, get(DATE_RANGE_EXPR, "today"))();
		form.batch(() => {
			form.change(source, value);
			form.change("start_at", get(range, "start_at", undefined));
			form.change("end_at", get(range, "end_at", undefined));
			form.change("day", get(range, "day", undefined));
		});
	};
	const view = get(formState, `values.${source}`, "today");
	return (
		<React.Fragment>
			<Wrapper>
				<Box component="span" className={classes.box}>
					<SelectInput
						{...props}
						label={translate(`app.report.view_range`)}
						choices={DATE_RANGES(props, translate)}
						onChange={handleChange}
						className={classes.select}
					/>
				</Box>
				{view === "range" && (
					<React.Fragment>
						<Box component="span" className={classes.box}>
							<DateInput
								{...props}
								source="start_at"
								alwaysOn
								fullWidth
								className={classes.input}
							/>
						</Box>
						<Box component="span" className={classes.box}>
							<DateInput
								{...props}
								source="end_at"
								alwaysOn
								fullWidth
								className={classes.input}
							/>
						</Box>
					</React.Fragment>
				)}
				{view === "day" && (
					<Box component="span" className={classes.box}>
						<DateInput
							{...props}
							source="day"
							alwaysOn
							fullWidth
							className={classes.input}
						/>
					</Box>
				)}
			</Wrapper>
		</React.Fragment>
	);
};

export default DateRangeInput;
