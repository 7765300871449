import { Button, useRedirect, useRefresh, useUpdateMany } from 'react-admin';

import LinkIcon from '@material-ui/icons/Link';
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

const ViewField = ({ record, source, basePath, label = '', ...props }) => {
  const refresh = useRefresh();
  const [updateMany, { loading }] = useUpdateMany(
    'notifications',
    [record.id],
    {
      readed: moment().format()
    },
    {
      onSuccess: () => {
        refresh();
        redirect(`${link}`);
      }
    }
  );
  const redirect = useRedirect();
  const link = get(record, source);
  const readed = get(record, 'readed') !== null;
  if (!link) {
    return null;
  }
  const handleClick = () => {
    if (readed) {
      redirect(`${link}`);
    } else {
      updateMany();
    }
  };
  return (
    <Button onClick={handleClick} label={label} disabled={loading}>
      <LinkIcon />
    </Button>
  );
};
export default ViewField;
