import { Button, Card, CardContent, makeStyles } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import {
  clearState,
  useLogin,
  useNotify,
  useSafeSetState,
  useTranslate,
} from "ra-core";

import BasicInput from "components/layout/BasicInput";
import BasicLayout from "components/layout/BasicLayout";
import BasicTitle from "components/layout/BasicTitle";
import { Link } from "react-router-dom";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  loginForm: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };
  const dispatch = useDispatch();
  const submit = (values) => {
    setLoading(true);
    dispatch(clearState());
    login(values, "/")
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning",
          {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          }
        );
      });
  };
  return (
    <BasicLayout>
      <BasicTitle message="ra.auth.welcome" />
      <Card className="m-0 w-100 p-0 border-0">
        <CardContent className="p-3 pt-5">
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={classes.loginForm}
              >
                <div className="mb-3">
                  <Field
                    Icon={PersonIcon}
                    autoFocus
                    id="username"
                    name="username"
                    component={BasicInput}
                    label={translate("ra.auth.username")}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    Icon={LockTwoToneIcon}
                    id="password"
                    name="password"
                    type="password"
                    component={BasicInput}
                    label={translate("ra.auth.password")}
                    disabled={loading}
                  />
                </div>

                <div className="text-center">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={loading}
                    className="my-2"
                  >
                    {translate("ra.auth.sign_in")}
                  </Button>
                </div>
                <div>
                  <Button type="button" component={Link} to="/signup">
                    {translate("ra.auth.signup")}
                  </Button>
                  <br />
                  <Button type="button" component={Link} to="/recover-password">
                    {translate("ra.auth.forgot_password")}
                  </Button>
                </div>
              </form>
            )}
          />
        </CardContent>
      </Card>
    </BasicLayout>
  );
};
export default LoginPage;
