import { InputAdornment } from "@material-ui/core";
import React from "react";

const { NumberInput } = require("ra-ui-materialui");

const MoneyInput = (props) => (
  <NumberInput
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position="end">€</InputAdornment>,
    }}
  />
);
export default MoneyInput;
