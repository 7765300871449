import Edit from "./Edit";
import List from "./List";
import { OrderIcon } from "icons";

export default {
  icon: OrderIcon,
  list: List,
  edit: Edit,
  options: {
    group: "reporting",
  },
};
