import { get } from "lodash";
import React from "react";
import { DateField, NumberField } from "react-admin";

const PointsField = ({ record, source, ...props }) => {
	const summary = get(record, "summary");
	if (summary) {
		return (
			<DateField
				variant="body1"
				source={source}
				record={record}
				color="textSecondary"
				showTime
			/>
		);
	}
	return <NumberField record={record} source={source} {...props} />;
};

export default PointsField;
