import React from "react";
import { SelectInput } from "ra-ui-materialui";
import useFieldLabel from "components/useFieldLabel";

const APPROVAL_TYPES = ["automatic", "manual"].map((id) => ({
  id,
  name: `resources.commissions.approval_type.${id}`,
}));

const ApprovalTypeInput = (props) => {
  const fieldLabel = useFieldLabel(props);
  return (
    <SelectInput
      {...props}
      choices={APPROVAL_TYPES}
      optionText="name"
      helperText={fieldLabel(`${props.source}.help`)}
    />
  );
};

export default ApprovalTypeInput;
