import { useMediaQuery } from "@material-ui/core";
import CommissionsField from "components/fields/CommissionsField";
import ImageField from "components/fields/ImageField";
import ResponsiveList from "components/lists/ResponsiveList";
import { useListStyles, useSelector } from "components/lists/useStyles";
import React, { Fragment, useMemo } from "react";
import {
	DateField,
	List as RaList,
	NumberField,
	TextField,
	usePermissions,
	useRedirect,
} from "react-admin";
import perPage from "utils/perPage";
import Workflow from "../components/workflow";
import Actions from "./Actions";
import ShowButton from "./buttons/ShowButton";
import SettingsField from "./fields/SettingsField";
import Filters from "./Filters";

const List = (props) => {
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const classes = useListStyles({ sidebarOpen });
	const redirect = useRedirect();
	const { loaded, permissions } = usePermissions();
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const [listType, setListType] = React.useState(
		localStorage.getItem("Deals.list_type") || "list"
	);
	const handleSelectListType = (type) => {
		setListType(type);
		localStorage.setItem("Deals.list_type", type);
	};
	const isAdmin = useMemo(
		() => loaded && permissions && permissions(["admin"]),
		[loaded, permissions]
	);
	const isPublisher = useMemo(
		() => loaded && permissions && permissions(["publisher"]),
		[loaded, permissions]
	);
	const isGridList = listType === "grid" || sm;
	const component = isGridList ? { component: "div" } : {};
	const handleAction = ({ record }) =>
		redirect("show", props.basePath, record.id);
	return (
		<RaList
			{...props}
			{...component}
			filters={<Filters />}
			classes={classes}
			exporter={false}
			perPage={perPage(25, 10)}
			actions={
				!sm && (
					<Actions
						onSelect={handleSelectListType}
						isPublisher={isPublisher}
						selected={listType}
					/>
				)
			}
		>
			<ResponsiveList
				type={listType}
				mobileMedia="preview.file.path"
				mobileMediaAction={handleAction}
				mobileHeader={
					<Fragment>
						<TextField
							source="name"
							variant="subtitle2"
							style={{ fontWeight: "bold" }}
						/>
					</Fragment>
				}
				mobileBodyAction={handleAction}
				mobileBody={
					<Fragment>
						<Workflow.StateCollectionInput
							source="state"
							readonly={!isAdmin}
							admin={isAdmin}
						/>
						<CommissionsField source="commissions" />
						<NumberField source="count_landings" />
						<DateField source="created" showTime />
					</Fragment>
				}
				mobileActions={
					<Fragment>
						{isPublisher && <ShowButton />}
						{isPublisher === false && <Workflow.EditButton />}
						{!isPublisher && <SettingsField />}
					</Fragment>
				}
			>
				<TextField source="id" />
				<ImageField
					source="preview.file.path"
					label="resources.campaigns.fields.preview_media_id"
				/>
				<TextField
					source="name"
					variant="subtitle2"
					style={{ fontWeight: "bold" }}
				/>
				<Workflow.StateCollectionInput
					source="state"
					readonly={!isAdmin}
					admin={isAdmin}
				/>
				<CommissionsField source="commissions" />
				<NumberField source="count_landings" />
				{/* <NumberField
					source="epc"
					options={{ style: "currency", currency: "EUR" }}
				/> */}
				<NumberField source="count_media" />
				<DateField source="created" showTime />
				{!isPublisher && <SettingsField />}
				{isPublisher && <ShowButton />}
				{isPublisher === false && <Workflow.EditButton />}
			</ResponsiveList>
		</RaList>
	);
};
export default List;
