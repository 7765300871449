import React from "react";
import {Edit as RaEdit} from "react-admin";
import {useFormStyles} from "../components/forms/useStyles";
import Form from "./Form";

const Edit = (props) => {
    const classes = useFormStyles();
    return (
        <RaEdit
            {...props}
            mutationMode="pessimistic"
            classes={classes}
            component="div">
            <Form/>
        </RaEdit>
    );
};

export default Edit;
