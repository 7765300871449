import {
  ReferenceInput,
  SelectInput,
  TextInput,
  required
} from 'react-admin';

import React from 'react';
import types from './types';
import {CardForm} from "../components/forms/cards/CardForm";
import {RedirectsIcon} from "../icons";
import {FormCard} from "../components/forms/cards/FormCard";
import {useTranslate} from "ra-core";

const Form = props => {
  const translate = useTranslate();
  return (
    <CardForm {...props} redirect="list" variant="outlined">
      <FormCard size={4} offset={4}
                label={translate('resources.redirects.name', 1)}
                icon={<RedirectsIcon/>}>
        <ReferenceInput
          source="language_id"
          reference="languages"
          validate={required()}
          fullWidth
        >
          <SelectInput/>
        </ReferenceInput>
        <SelectInput source="type" choices={types} validate={required()} fullWidth/>
        <TextInput source="from" validate={required()} fullWidth/>
        <TextInput source="to" validate={required()} fullWidth/>
      </FormCard>
    </CardForm>
  );
};
export default Form;
