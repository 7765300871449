import { ArrayInput, NumberInput, SimpleFormIterator } from "ra-ui-materialui";

import React from "react";
import TextInput from "components/inputs/TextInput";
import { makeStyles } from "@material-ui/core";
import useFieldLabel from "components/useFieldLabel";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
    float: "left",

    display: "inline-block",
    marginRight: theme.spacing(2),
  },
  longInput: {
    width: "70%",
  },
  shortInput: {
    width: "20%",
  },
}));
const OrderItemsInput = (props) => {
  const classes = useStyles();
  const fieldLabel = useFieldLabel({ resource: "order-items" });
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator disableAdd disableRemove>
        <TextInput
          className={[classes.input, classes.longInput]}
          label={fieldLabel("campaign_offer_name")}
          source="campaign_offer_name"
          fullWidth
        />
        <NumberInput
          className={[classes.input, classes.shortInput]}
          label={fieldLabel("quantity")}
          source="quantity"
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default OrderItemsInput;
