import React from "react";
import { DateField, EditButton, List as RaList, TextField } from "react-admin";
import perPage from "utils/perPage";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import CloneButton from "./buttons/CloneButton";
import SettingsField from "./fields/SettingsField";
import Filters from "./Filters";

const Datagrid = (props) => {
	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField source="title" style={{ fontWeight: "bold" }} />
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
					<DateField source="start_at" showTime />
					<DateField source="end_at" showTime />
					<DateField source="created" showTime />
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<EditButton />
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			<TextField source="title" style={{ fontWeight: "bold" }} />
			<SettingsField />
			<DateField source="start_at" showTime />
			<DateField source="end_at" showTime />
			<DateField source="created" showTime />
			<EditButton />
			<CloneButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage()}
			exporter={false}
		>
			<Datagrid />
		</RaList>
	);
};
export default List;
