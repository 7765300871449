import { Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";

const RulesShowField = ({ record, ...props }) => {
	return (
		<div>
			<Typography
				variant="body2"
				dangerouslySetInnerHTML={{
					__html: get(record, "rules"),
				}}
			/>
		</div>
	);
};

export default RulesShowField;
