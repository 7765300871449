import Button from "@material-ui/core/Button";
import { LinkIcon } from "icons";
import { get } from "lodash";
import React from "react";
import { useTranslate } from "react-admin";
import { Link } from "react-router-dom";

const CreateLinkButton = ({ record, source, basePath, ...props }) => {
	const translate = useTranslate();
	return (
		<Button
			component={Link}
			to={{
				pathname: "/links/create",
				search: `?source=${encodeURIComponent(
					JSON.stringify({
						campaign_id: get(record, "campaign_id"),
						landing_id: get(record, "id"),
					})
				)}`,
			}}
			startIcon={<LinkIcon />}
			color="primary"
		>
			{translate("resources.campaigns.buttons.add_links")}
		</Button>
	);
};

export default CreateLinkButton;
