import AutocompleteInput from "components/inputs/AutocompleteInput";
import React from "react";
import { ReferenceInput } from "react-admin";

const UserInput = (props) => {
	return (
		<ReferenceInput
			{...props}
			reference="users"
			filter={{ role: "publisher" }}
			margin="dense"
			sort={{
				field: "username",
				order: "ASC",
			}}
		>
			<AutocompleteInput optionText="name" />
		</ReferenceInput>
	);
};
export default UserInput;
