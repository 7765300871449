import React from "react";
import {Create as RaCreate} from "react-admin";
import {useFormStyles} from "../components/forms/useStyles";
import Form from "./Form";

const Create = (props) => {
  const classes = useFormStyles();
  return (
    <RaCreate {...props} classes={classes} component="div">
      <Form/>
    </RaCreate>
  );
};
export default Create;
