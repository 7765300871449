import { Box, Button, useTheme } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useGetIdentity, useTranslate } from "react-admin";

import { Link } from "react-router-dom";
import { UserIcon } from "icons";
import clsx from "clsx";
import { connect } from "react-redux";
import { get } from "lodash";
import { useSelector } from "react-redux";

const SidebarUserbox = (props) => {
  const theme = useTheme();
  const translate = useTranslate();
  const { sidebarToggle, sidebarHover } = props;
  const { identity, loading } = useGetIdentity();

  const [picture, setPicture] = useState(get(identity, "picture.file.path"));
  const viewVersion = useSelector((state) =>
    get(state, "admin.ui.viewVersion")
  );
  useEffect(() => {
    const identity = JSON.parse(localStorage.getItem("profile"));
    const picture = get(identity, "picture.file.path");
    setPicture(picture);
  }, [viewVersion]);
  if (loading) {
    return null;
  }

  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox", {
          "app-sidebar-userbox--collapsed": sidebarToggle && !sidebarHover,
        })}
      >
        {!picture && <UserIcon className="app-sidebar-userbox-avatar" />}
        {picture && (
          <div className="avatar-icon-wrapper app-sidebar-userbox-avatar">
            <div className="avatar-icon">
              <img src={picture} alt={`${identity.name} ${identity.surname}`} />
            </div>
          </div>
        )}
        <Box className="app-sidebar-userbox-name">
          <Box style={{ marginBottom: theme.spacing(1) }}>
            <b>
              {identity.name} {identity.surname}
            </b>
          </Box>
          <Box className="app-sidebar-userbox-btn-profile">
            <Button
              disableElevation
              size="small"
              color="secondary"
              variant="contained"
              className="text-white"
              component={Link}
              to="/user-profile"
            >
              {translate("ra.auth.view_profile")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(SidebarUserbox);
