import { ChipField, EditButton, List as RaList, TextField } from "react-admin";

import Actions from "./Actions";
import AmountField from "./fields/AmountField";
import CampaignField from "./fields/CampaignField";
import Filters from "./Filters";
import ItemsField from "./fields/ItemsField";
import React from "react";
import ResponsiveList from "../components/lists/ResponsiveList";
import UserField from "./fields/UserField";
import perPage from "utils/perPage";
import { useListStyles } from "../components/lists/useStyles";
import { useTheme } from "@material-ui/core";

const List = (props) => {
  const classes = useListStyles();
  const theme = useTheme();
  return (
    <RaList
      {...props}
      classes={classes}
      actions={<Actions />}
      filters={<Filters />}
      perPage={perPage()}
    >
      <ResponsiveList
        mobileHeader={
          <React.Fragment>
            <ChipField source="event.name" variant="outlined" />
            <ItemsField
              source="items"
              style={{ paddingLeft: theme.spacing(2) }}
            />
          </React.Fragment>
        }
        mobileBody={
          <React.Fragment>
            <CampaignField source="campaign.name" />
            <UserField source="user.name" variant="body1" />
          </React.Fragment>
        }
        mobileActions={
          <React.Fragment>
            <EditButton />
          </React.Fragment>
        }
      >
        <TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
        <ChipField source="event.name" />
        <CampaignField source="campaign.name" />
        <UserField source="user.name" />
        <AmountField source="amount" />
        <EditButton />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
