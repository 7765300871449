import { Divider, Link } from "@material-ui/core";
import { useGetOne, useNotify, useTranslate } from "react-admin";

import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { SIGNUP_URL } from "../config";
import Typography from "@material-ui/core/Typography";
import { UserIcon } from "icons";
import { get } from "lodash";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: "center",
      opacity: theme.palette.type === "light" ? 0.5 : 0.8,
      margin: "0 1em",
      color:
        theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
    },
    icon: {
      width: "9em",
      height: "9em",
    },
    toolbar: {
      textAlign: "center",
      marginTop: "2em",
    },
    paragraph: {
      maxWidth: 600,
      margin: "0 auto",
    },
    divider: {
      width: "20%",
      margin: "1em auto",
    },
  }),
  { name: "RaEmpty" }
);

const Empty = ({ basePath, resource }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const { data } = useGetOne("users", "profile");
  const referralCode = get(data, "referral_code");
  return (
    <React.Fragment>
      <div className={classes.message}>
        <UserIcon className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate("ra.page.empty")}
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          {translate("resources.referral-user.page.empty")}
        </Typography>
        <Divider light className={classes.divider} />
        {referralCode && (
          <React.Fragment>
            <CopyToClipboard
              text={`http:${SIGNUP_URL}${referralCode}`}
              onCopy={() => notify(translate("ra.action.content_copied"))}
            >
              <Typography>
                <Link
                  style={{
                    cursor: "pointer",
                  }}
                >{`http:${SIGNUP_URL}${referralCode}`}</Link>
              </Typography>
            </CopyToClipboard>
            <Typography variant="caption">
              {translate("ra.action.click_to_copy")}
            </Typography>
          </React.Fragment>
        )}
      </div>
      <div className={classes.toolbar}></div>
    </React.Fragment>
  );
};

export default Empty;
