import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import React from "react";
import { get } from "lodash";
import moment from "moment";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: "100%",
  },
  chipLabel: {
    whiteSpace: "normal",
    padding: theme.spacing(0.5),
    "& strong": {
      fontWeight: "bold",
      marginRight: theme.spacing(1),
    },
  },
  chipInsideShort: {
    whiteSpace: "normal",
  },
}));

const useChanges = (record, translate) => {
  let changes = get(record, "changes", "{}");
  if (changes === null) {
    changes = {};
  } else {
    changes = JSON.parse(changes);
  }
  const dates = Object.keys(changes);
  return dates
    .map((date) => ({
      date: moment(date).format(translate("app.date_format.long")),
      fields: Object.keys(get(changes, date, {})).reduce((data, field) => {
        if (field.startsWith("order_item_") || field === "customer") {
          const resource = field.startsWith("order_item_")
            ? "order-items"
            : "orders";
          Object.keys(get(changes, `${date}.${field}`, [])).forEach((key) => {
            data.push({
              name: translate(
                `resources.${resource}.fields.${
                  resource === "orders" ? "customer." : ""
                }${key}`
              ),
              old: get(changes, `${date}.${field}.${key}.old`, ""),
              new: get(changes, `${date}.${field}.${key}.new`, ""),
            });
          });
        } else {
          data.push({
            name: translate(`resources.orders.fields.${field}`),
            old: get(changes, `${date}.${field}.old`, ""),
            new: get(changes, `${date}.${field}.new`, ""),
          });
        }
        return data;
      }, []),
    }))
    .reverse();
};

const ChangesField = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const changes = useChanges(record, translate);

  return (
    <Table size="small">
      {changes.length === 0 && (
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2">
                {translate("resources.orders.messages.no_changes")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      )}
      {changes.length > 0 && (
        <TableBody>
          {changes.map((change, index) => (
            <TableRow key={index}>
              <TableCell className={classes.dateColumn}>
                {change.date}
              </TableCell>
              <TableCell>
                {change.fields.map((field, index) => (
                  <Chip
                    className={classes.chip}
                    color="primary"
                    key={index}
                    label={
                      <Typography className={classes.chipLabel}>
                        <Typography component="strong">
                          {field.name}:
                        </Typography>
                        <Typography component="span">{field.old}</Typography>
                        <ArrowRightAltIcon />
                        <Typography component="span">{field.new}</Typography>
                      </Typography>
                    }
                  />
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
};

export default ChangesField;
