import React from "react";
import {
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
} from "react-admin";

const Form = (props) => {
	return (
		<SimpleForm {...props} redirect="list">
			<ReferenceInput
				source="contest_id"
				fullWidth
				reference="contests"
				resettable
				validate={required()}
			>
				<SelectInput optionText="title" />
			</ReferenceInput>
			<ReferenceInput
				source="campaign_id"
				reference="campaigns"
				filter={{ status: "active" }}
				validate={required()}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<NumberInput source="cpa_value" validate={required()} defaultValue="1" />
		</SimpleForm>
	);
};
export default Form;
