import useValidatableSave from "components/forms/useValidatableSave";
import React from "react";
import {
	BooleanInput,
	maxLength,
	required,
	TextInput,
	useTranslate,
} from "react-admin";
import { CardForm } from "../components/forms/cards/CardForm";
import { FormCard } from "../components/forms/cards/FormCard";
import { FormGrid } from "../components/forms/cards/FormGrid";
import { FormGridColumn } from "../components/forms/cards/FormGridColumn";
import Sanitize from "../components/Sanitize";
import { RoleIcon, UserIcon } from "../icons";
import ReferralCodeInput from "../user-profiles/inputs/ReferralCodeInput";
import TaxInput from "../user-profiles/inputs/TaxInput";
import GroupsInput from "./inputs/GroupsInput";
import PasswordInput from "./inputs/PasswordInput";
import PaymentMethodsInput from "./inputs/PaymentMethodInput";
import ReferralInput from "./inputs/ReferralInput";
import RolesInput from "./inputs/RolesInput";
import StatusInput from "./inputs/StatusInput";

const Form = ({ create = false, permissions, ...props }) => {
	const translate = useTranslate();
	const save = useValidatableSave({ ...props, redirect: undefined });
	return (
		<CardForm {...props} save={save} variant="outlined" grid={true}>
			<FormGrid container spacing={2}>
				<FormGridColumn size={6}>
					<FormCard
						label={translate("resources.users.name", 1)}
						icon={<UserIcon />}
					>
						<TextInput source="code" fullWidth />
						<StatusInput source="status" validate={required()} fullWidth />
						<TextInput source="username" validate={required()} fullWidth />
						<PasswordInput
							permissions={permissions}
							create={create}
							fullWidth
						/>
						<TextInput source="email" validate={required()} fullWidth />
						<ReferralInput source="referral_id" fullWidth />
						<ReferralCodeInput source="referral_code" fullWidth />
						{create && (
							<Sanitize>
								<div style={{ display: "none" }}>
									<BooleanInput
										source="accepted_disclaimer"
										defaultValue={true}
									/>
									<BooleanInput
										source="accepted_regulation"
										defaultValue={true}
									/>
								</div>
							</Sanitize>
						)}
					</FormCard>
					<FormCard
						label="resources.users.fields.roles"
						icon={<RoleIcon />}
						fullWidth
					>
						<RolesInput source="roles" fullWidth />
						<GroupsInput source="groups" fullWidth />
					</FormCard>
				</FormGridColumn>
				<FormGridColumn size={6}>
					<FormCard label="ra.auth.profile.title">
						<TextInput source="profile.name" validate={required()} fullWidth />
						<TextInput
							source="profile.surname"
							validate={required()}
							fullWidth
						/>
						<TextInput
							source="profile.site_link"
							fullWidth
							validate={required()}
						/>
						<TextInput
							source="profile.company_name"
							validate={[maxLength(100), required()]}
							fullWidth
						/>
						<TaxInput source="profile." fullWidth />
						<TextInput
							source="profile.address"
							validate={[maxLength(100), required()]}
							fullWidth
						/>
						<TextInput
							source="profile.phone"
							validate={maxLength(30)}
							fullWidth
						/>
						<TextInput
							source="profile.contact"
							validate={maxLength(100)}
							helperText="resources.users.fields.profile.contact.help"
							fullWidth
						/>
						<TextInput source="profile.facebook_profile" fullWidth />
						<PaymentMethodsInput source="profile.payment_method" fullWidth />
					</FormCard>
				</FormGridColumn>
			</FormGrid>
		</CardForm>
	);
};
export default Form;
