import { green, purple } from "@material-ui/core/colors";

import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import React from "react";
import { SelectField } from "ra-ui-materialui";

const items = [
  {
    id: "cod",
    name: "resources.orders.fields.payment_method.cod",
    color: purple[500],
    Icon: LocalShippingIcon,
  },
  {
    id: "prepaid",
    name: "resources.orders.fields.payment_method.prepaid",
    color: green[500],
    Icon: EuroSymbolIcon,
  },
];

const PaymentMethodField = (props) => (
  <SelectField {...props} choices={items} />
);
export default PaymentMethodField;
