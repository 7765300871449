import { Chip } from "@material-ui/core";
import { get } from "lodash";
import { useTranslate } from "ra-core";
import React from "react";
const StatusField = ({ source, record }) => {
	const translate = useTranslate();
	const status = get(record, source);
	return (
		<Chip
			color={
				status === "approved"
					? "primary"
					: status === "rejected"
					? "default"
					: "secondary"
			}
			label={translate(`resources.domains.status.${status}`)}
		/>
	);
};
export default StatusField;
