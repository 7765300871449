import { Filter, SearchInput } from "react-admin";

import React from "react";
import StateInput from "components/workflow/inputs/StateInput";
import { useFiltersStyles } from "../components/lists/useStyles";
import {useMediaQuery} from "@material-ui/core";

const Filters = (props) => {
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes} variant="outlined">
      <SearchInput source="q" alwaysOn fullWidth style={sm ? { marginRight: 16 } : {marginRight: 0}}/>
      <StateInput source="state" alwaysOn fullWidth style={sm ? { marginRight: 16 } : {marginRight: 0}}/>
    </Filter>
  );
};
export default Filters;
