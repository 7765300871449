import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";
import Title from "../components/Title";
import { useFormStyles } from "../components/forms/useStyles";

const Edit = ({ permissions, ...props }) => {
  const classes = useFormStyles();
  return (
    <RaEdit
      {...props}
      classes={classes}
      redirect={false}
      title={<Title source="email" />}
      component="div"
    >
      <Form permissions={permissions} />
    </RaEdit>
  );
};
export default Edit;
