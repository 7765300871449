import React from "react";
import { Filter, usePermissions } from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";
import StatusInput from "./inputs/StatusInput";
import UserInput from "./inputs/UserInput";

const Filters = (props) => {
	const classes = useFiltersStyles();
	const { loaded, permissions } = usePermissions();
	const isAdmin = loaded && permissions(["admin"]);
	return (
		<Filter {...props} classes={classes} variant="outlined">
			{isAdmin && <UserInput source="user_id" alwaysOn />}
			<StatusInput source="status" alwaysOn />
		</Filter>
	);
};
export default Filters;
