import React from "react";
import { CloneButton as RaCloneButton } from "react-admin";

const CloneButton = ({ record, ...props }) => {
	if (!record) {
		return null;
	}
	const {
		id,
		contest,
		campaign,
		campaign_id,
		created,
		modified,
		deleted,
		...rest
	} = record;
	const newRecord = {
		...rest,
	};
	return <RaCloneButton record={newRecord} {...props} />;
};
export default CloneButton;
