import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import { useTranslate } from "ra-core";
const MessageField = ({ record }) => {
  const user = get(record, "user.name");
  const userCompany = get(record, "user.profile.company_name");
  const parent = get(record, "parent.name");
  const translate = useTranslate();

  return (
    <Typography variant="body1">
      {translate("resources.user-parent-approvals.messages.request", {
        user,
        userCompany,
        parent,
      })}
    </Typography>
  );
};
export default MessageField;
