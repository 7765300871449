import { BooleanInput, Filter } from "react-admin";

import React from "react";
import SearchInput from "components/inputs/SearchInput";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes} variant="outlined" className="">
      <SearchInput source="q" alwaysOn fullWidth />
      <BooleanInput
        source="not_readed"
        label="resources.notifications.fields.readed.empty"
        alwaysOn
      />
    </Filter>
  );
};
export default Filters;
