import Dashlets from "./dashlets";
import { Grid } from "@material-ui/core";
import React from "react";

const Dashlet = ({ dashlet }) => {
  const { props } = dashlet;
  const Component = Dashlets[dashlet.type] || Dashlets.NotImplemented;
  return (
    <Grid item {...props}>
      <Component {...dashlet} />
    </Grid>
  );
};

export default Dashlet;
