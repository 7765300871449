import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import classnames from "classnames";
import { get } from "lodash";
import React, { useMemo } from "react";
import {
	FieldTitle,
	InputHelperText,
	useInput,
	useTranslate,
} from "react-admin";
import { useFormState } from "react-final-form";

const useStyles = makeStyles((theme) => ({
	formField: {
		minWidth: 250,
		marginBottom: 4,
		"& [class*=MuiFormControl-root]": {
			width: "100%",
		},
	},
}));
const AutocompleteInput = ({
	choices,
	optionText = "name",
	helperText = null,
	className,
	resource,
	source,
	setFilter,
	label,
	...props
}) => {
	const formState = useFormState();
	const classes = useStyles();
	const {
		input: { name, onChange, ...rest },
		meta: { touched, error },
		isRequired,
	} = useInput(props);
	const translate = useTranslate();
	const helperTextContent = useMemo(() => {
		return translate(helperText);
	}, [helperText, translate]);
	const selectedValues = useMemo(() => {
		const currentSelectedValues = get(formState, `values.${source}`);

		let selection = choices.filter((c) => c && currentSelectedValues === c.id);
		return get(selection, "[0]", null);
	}, [formState, source, choices]);
	const handleChange = (_, values) => {
		onChange(get(values, "id"));
	};
	const getOptionLabel = (option) => {
		return typeof optionText === "function"
			? optionText(option)
			: get(option, optionText);
	};
	const { formField, ...usefullClasses } = classes;
	return (
		<div className={classnames(className, formField)}>
			<Autocomplete
				{...rest}
				classes={usefullClasses}
				options={choices}
				onChange={handleChange}
				value={selectedValues}
				getOptionLabel={getOptionLabel}
				renderInput={(params) => (
					<TextField
						margin="dense"
						error={!!error}
						variant={props.variant}
						onChange={(e) => setFilter(e.target.value)}
						helperText={
							(helperText !== false && helperText !== null) ||
							(touched && error) ? (
								<InputHelperText
									style={{ marginBottom: 32 }}
									helperText={helperTextContent}
									touched={touched}
									error={error}
								/>
							) : null
						}
						required={isRequired}
						label={
							<FieldTitle
								label={label}
								source={source}
								resource={resource}
								isRequired={isRequired}
							/>
						}
						{...params}
					/>
				)}
			/>
		</div>
	);
};

export default AutocompleteInput;
