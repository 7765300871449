import React from "react";
import {
	Show as RaShow,
	Tab,
	TabbedShowLayout,
	useRedirect,
	useTranslate,
} from "react-admin";
import Title from "../components/Title";
import RewardsShowField from "./fields/RewardsShowField";
import RulesShowField from "./fields/RulesShowField";

const Show = (props) => {
	console.log(props);
	const translate = useTranslate();
	const redirect = useRedirect();
	const contestStatsUrl = `/contest-stats?filter=${encodeURIComponent(
		JSON.stringify({ scc: 1, contest_id: parseInt(props.id) })
	)}`;
	return (
		<RaShow {...props} title={<Title source="title" />}>
			<TabbedShowLayout>
				<Tab label={translate("resources.contests.fields.rewards")}>
					<RewardsShowField />
				</Tab>
				<Tab label={translate("resources.contests.fields.rules")}>
					<RulesShowField />
				</Tab>
				<Tab
					label={translate("resources.contest-stats.name")}
					onClick={(e) => {
						e.preventDefault();
						redirect(contestStatsUrl);
					}}
				/>
			</TabbedShowLayout>
		</RaShow>
	);
};

export default Show;
