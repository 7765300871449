import { TableCell, TableRow, Typography } from "@material-ui/core";
import { useQueryWithStore, useTranslate } from "react-admin";

import EventField from "./fields/EventField";
import React from "react";
import { get } from "lodash";

const DatagridRowExpanded = ({ record, events, filterValues, permissions }) => {
  const hasUserIdFilter = get(filterValues, "user_id");
  const groupBy =
    permissions("publisher") || hasUserIdFilter
      ? "group_by_user_and_landing"
      : "group_by_user_and_campaign";

  const translate = useTranslate();
  const { loading, loaded, data } = useQueryWithStore({
    type: "getList",
    resource: "stats",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: {
        field: permissions("publisher") ? "landing" : "campaign",
        order: "ASC",
      },
      filter: {
        ...filterValues,
        ...(permissions("publisher") || hasUserIdFilter
          ? {
              campaign_id: get(record, "campaign_id"),
            }
          : {
              user_id: get(record, "user_id"),
            }),
        group_by: groupBy,
      },
    },
  });

  if (loading) {
    return (
      <TableRow>
        <TableCell colSpan={events.length + 2}>
          {translate("resources.stats.loading")}
        </TableCell>
      </TableRow>
    );
  }

  return (
    loaded &&
    data.map((row) => {
      const id = get(row, "id");

      return (
        <TableRow key={id}>
          <TableCell align="left" colSpan={2}>
            <Typography variant="overline">
              {get(
                row,
                permissions("publisher") || hasUserIdFilter
                  ? "landing"
                  : "campaign"
              )}
            </Typography>
          </TableCell>
          {events.map((event) => (
            <TableCell key={event.id} align="left">
              <EventField source={event.code} record={row} event={event} />
            </TableCell>
          ))}
        </TableRow>
      );
    })
  );
};
export default DatagridRowExpanded;
