import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const formatter = (props, lang = "it-IT") => Intl.NumberFormat(lang, props);

const EventField = ({ source, record, event, basePath, ...props }) => {
  switch (event.value_type) {
    case "money":
      const moneyValue = parseFloat(get(record, source));
      return (
        <Typography variant="body2" {...props}>
          {formatter({
            style: "currency",
            currency: "EUR",
          }).format(moneyValue)}
        </Typography>
      );

    case "percent":
      const percentValue = parseFloat(get(record, source) || 0);
      return (
        <Typography variant="body2" {...props}>
          {formatter({
            style: "percent",
            maximumFractionDigits: 2,
          }).format(percentValue / 100)}
        </Typography>
      );

    case "float":
      const floatValue = parseFloat(get(record, source) || 0);
      return (
        <Typography variant="body2" {...props}>
          {formatter({
            maximumFractionDigits: 2,
          }).format(floatValue / 100)}
        </Typography>
      );

    default:
      const intValue = parseInt(get(record, source), 10);
      return (
        <Typography variant="body2" {...props}>
          {!isNaN(intValue) === false ? 0 : intValue}
        </Typography>
      );
  }
};
export default EventField;
