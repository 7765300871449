import Create from "./Create";
import { DomainIcon } from "icons";
import Edit from "./Edit";
import List from "./List";

export default {
  icon: DomainIcon,
  options: {
    group: "settings",
    roles: ["publisher", "admin"],
  },
  list: List,
  edit: Edit,
  create: Create,
};
