import PropTypes from "prop-types";
import * as React from "react";
import { FormWithRedirect } from "react-admin";
import { CardFormView } from "./CardFormView";

/**
 * Form layout where inputs are divided by card, one input per line.
 *
 * Pass FormCard components as children.
 * or
 * Pass FormGrid and FormGridColumn as children.
 *
 * @example
 *
 * import * as React from "react";
 * import {
 *     Edit,
 *     Datagrid,
 *     TextField,
 *     DateField,
 *     TextInput,
 *     ReferenceManyField,
 *     NumberInput,
 *     DateInput,
 *     BooleanInput,
 *     EditButton
 * } from 'react-admin';
 *
 * import { CardForm } from "../components/forms/cards/CardForm";
 * import { FormCard } from "../components/forms/cards/FormCard";
 * import { FormGrid } from "../components/forms/cards/FormGrid";
 * import { FormGridColumn } from "../components/forms/cards/FormGridColumn";
 *
 * export const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <CardForm {...props} toolbar={<Toolbar />} save={save} grid={true}>
 * 					<FormGrid container spacing={2}>
 * 						<FormGridColumn>
 *             <FormCard label="summary">
 *                 <TextInput disabled label="Id" source="id" />
 *                 <TextInput source="title" validate={required()} />
 *                 <TextInput multiline source="teaser" validate={required()} />
 *             </FormCard>
 *             <FormCard label="body">
 *                 <RichTextInput source="body" validate={required()} addLabel={false} />
 *             </FormCard>
 * 						</FormGridColumn>
 *          </FormGrid>
 *         </CardForm>
 *     </Edit>
 * );
 *
 * @typedef {Object} Props the props you can use (other props are injected by Create or Edit)
 * @prop {ReactElement[]} FormTab elements
 * @prop {Object} initialValues
 * @prop {Function} validate
 * @prop {boolean} submitOnEnter
 * @prop {string} redirect
 * @prop {ReactElement} toolbar The element displayed at the bottom of the form, containing the SaveButton
 * @prop {string} variant Apply variant to all inputs. Possible values are 'standard', 'outlined', and 'filled' (default)
 * @prop {string} margin Apply variant to all inputs. Possible values are 'none', 'normal', and 'dense' (default)
 * @prop {boolean} sanitizeEmptyValues Whether or not deleted record attributes should be recreated with a `null` value (default: true)
 *
 * @param {Props} props
 */

export const CardForm = (props) => (
	<FormWithRedirect
		{...props}
		render={(formProps) => <CardFormView {...formProps} />}
	/>
);

CardForm.propTypes = {
	children: PropTypes.node,
	initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	mutationMode: PropTypes.oneOf(["pessimistic", "optimistic", "undoable"]),
	// @ts-ignore
	record: PropTypes.object,
	redirect: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
		PropTypes.func,
	]),
	save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
	saving: PropTypes.bool,
	submitOnEnter: PropTypes.bool,
	undoable: PropTypes.bool,
	validate: PropTypes.func,
	sanitizeEmptyValues: PropTypes.bool,
};
