import { useMediaQuery } from "@material-ui/core";
import React from "react";
import {
	ArrayField,
	BooleanField,
	ChipField,
	DateField,
	EditButton,
	List as RaList,
	SelectField,
	SingleFieldList,
	TextField,
} from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/perPage";
import Filters from "./Filters";
import types from "./types";
import valueTypes from "./valueTypes";

const List = (props) => {
	const isBig = useMediaQuery((theme) => theme.breakpoints.up("xl"));
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			perPage={perPage(50, 25)}
		>
			<ResponsiveList
				mobileHeader={
					<React.Fragment>
						<TextField
							source="name"
							variant="h6"
							style={{ fontWeight: "bold" }}
						/>
						<EditButton />
					</React.Fragment>
				}
				mobileBody={
					<React.Fragment>
						<ChipField source="code" style={{ marginLeft: 0 }} />
						<TextField source="name" style={{ fontWeight: "bold" }} />
						<SelectField source="type" variant="overline" choices={types} />
						<SelectField
							source="value_type"
							variant="overline"
							choices={valueTypes}
						/>
						<BooleanField source="show_commission" />
						<BooleanField source="show_column" />
						<TextField source="order" />
					</React.Fragment>
				}
			>
				<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
				<ChipField source="code" style={{ marginLeft: 0 }} />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<SelectField source="type" variant="overline" choices={types} />
				<SelectField
					source="value_type"
					variant="overline"
					choices={valueTypes}
				/>
				<ArrayField source="roles" style={{ display: "block" }}>
					<SingleFieldList>
						<ChipField source="name" style={{ marginLeft: 0 }} />
					</SingleFieldList>
				</ArrayField>
				<BooleanField source="show_commission" />
				<BooleanField source="show_column" />
				<TextField source="order" />
				{isBig && <DateField source="modified" showTime />}
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
