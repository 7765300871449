import {
  DatagridBody,
  Pagination,
  List as RaList,
  TextField,
  useQueryWithStore,
} from "react-admin";

import { DATE_RANGE_EXPR } from "../components/inputs/DateRangeInput";
import DatagridRow from "./DatagridRow";
import DatagridRowExpanded from "./DatagridRowExpanded";
import EventField from "./fields/EventField";
import Filters from "./Filters";
import { LinearProgress } from "@material-ui/core";
import React from "react";
import ResponsiveList from "components/lists/ResponsiveList";
import { get } from "lodash";
import perPage from "../utils/perPage";
import { useListStyles } from "../components/lists/useStyles";

const DesktopList = ({ permissions, events, ...props }) => {
  const hasUserIdFilter = get(props.filterValues, "user_id");
  return (
    <ResponsiveList
      {...props}
      body={<DatagridBody row={<DatagridRow />} />}
      expand={
        <DatagridRowExpanded
          filterValues={get(props, "filterValues")}
          permissions={permissions}
          events={events}
        />
      }
    >
      <TextField
        source={
          permissions("publisher") || hasUserIdFilter ? "campaign" : "user"
        }
        style={{ fontWeight: "bold", width: "10%" }}
      />
      {events.map((event) => (
        <EventField
          key={event.id}
          label={event.name}
          source={event.code}
          event={event}
        />
      ))}
    </ResponsiveList>
  );
};

const List = (props) => {
  const today = DATE_RANGE_EXPR.today();
  const { data } = useQueryWithStore({
    type: "getList",
    resource: "events",
    payload: {
      sort: { field: "order", order: "ASC" },
      pagination: {
        page: 1,
        perPage: 100,
      },
      filter: {
        user: true,
      },
    },
  });
  const classes = useListStyles();
  const { permissions } = props;
  if (typeof permissions !== "function") {
    return <LinearProgress />;
  }
  return (
    <RaList
      {...props}
      classes={classes}
      perPage={perPage(50, 100)}
      filters={<Filters permissions={props.permissions || ((role) => false)} />}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 50, 100, 200, 250, 500, 1000]}
        />
      }
      bulkActionButtons={false}
      sort={{
        field: permissions && permissions("user") ? "campaign" : "user",
        order: "ASC",
      }}
      filterDefaultValues={{
        view: "today",
        start_at: today.start_at,
        end_at: today.end_at,
      }}
      title="resources.stats.name"
      exporter={false}
    >
      <DesktopList
        events={data || []}
        permissions={props.permissions || ((role) => false)}
      />
    </RaList>
  );
};
export default List;
