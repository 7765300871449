import useFieldLabel from "components/useFieldLabel";
import UrlButton from "links/buttons/UrlButton";
import { get } from "lodash";
import React from "react";
import { TextField, useRedirect } from "react-admin";
import ListField, { makeRedirect } from "../../components/fields/ListField";

const LinksField = ({ planned, show = false, ...props }) => {
	const redirect = useRedirect();
	const fieldLabel = useFieldLabel({ resource: "links" });
	const id = get(props, "record.id", 0);
	if (id <= 0) {
		return null;
	}
	return (
		<ListField
			{...props}
			show={show}
			reference="links"
			target="campaign_id"
			canModify={true}
			canCreate={true}
			canRemove={true}
			sort={{ field: "id", order: "ASC" }}
			removeRedirect={() => redirect(makeRedirect({ ...props, tab: 2, show }))}
		>
			<TextField label="ID" source="id" />
			<TextField
				source="landing.name"
				sortBy="Landings.name"
				label={fieldLabel("landing_id")}
			/>
			<TextField source="subid" label={fieldLabel("subid")} />
			<UrlButton source="url" label="resources.landings.actions.copy_url" />
		</ListField>
	);
};

export default LinksField;
