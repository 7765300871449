import React from "react";
import { CloneButton as RaCloneButton } from "react-admin";

const CloneButton = ({ record, ...props }) => {
	if (!record) {
		return null;
	}
	const { id, contest, created, modified, deleted, start_at, end_at, ...rest } =
		record;
	const newRecord = {
		...rest,
		title: `${rest.title} copy`,
	};
	return <RaCloneButton record={newRecord} {...props} />;
};
export default CloneButton;
