import AssessmentIcon from "@material-ui/icons/Assessment";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { AdminIcon, CampaignIcon, ContestIcon } from "icons";
const config = {
	dashboard: {
		icon: DashboardTwoToneIcon,
		order: 5,
		expanded: true,
	},
	reporting: {
		icon: AssessmentIcon,
		order: 7.5,
		expanded: true,
	},
	campaigns: {
		icon: CampaignIcon,
		order: 10,
		expanded: true,
	},
	gamification: {
		icon: ContestIcon,
		order: 20,
		expanded: false,
	},
	pages: {
		icon: ViewModuleIcon,
		order: 50,
		expanded: false,
	},
	settings: {
		icon: SettingsIcon,
		order: 70,
		expanded: false,
	},
	admin: {
		icon: AdminIcon,
		order: 100,
		expanded: false,
	},
};

export const isExpanded = (location, group) =>
	group.resources &&
	group.resources.some(
		(resource) =>
			location.pathname === `/${resource.name}` ||
			location.pathname === resource.path ||
			location.pathname.indexOf(`/${resource.name}?`) === 0 ||
			location.pathname.indexOf(`/${resource.name}/`) === 0
	);

export const isSelected = (location, resource) =>
	location.pathname === `/${resource.name}` ||
	location.pathname === resource.path ||
	location.pathname.indexOf(`/${resource.name}?`) === 0 ||
	(location.pathname.indexOf(`/${resource.name}/`) === 0 &&
		!location.pathname.endsWith("/create"));

export default config;
