import React from "react";
import {
    BooleanInput,
    CheckboxGroupInput,
    NumberInput,
    ReferenceArrayInput,
    required,
    SelectInput,
    TextInput,
} from "react-admin";
import CodeInput from "../components/inputs/CodeInput";
import {formatMany, parseMany} from "../utils/parsing";
import types from "./types";
import valueFormatters from "./valueFormatters";
import valueTypes from "./valueTypes";
import {CardForm} from "../components/forms/cards/CardForm";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {FormGrid} from "../components/forms/cards/FormGrid";
import {FormGridColumn} from "../components/forms/cards/FormGridColumn";
import {FormCard} from "../components/forms/cards/FormCard";
import {RoleIcon} from "../icons";
import {KeyboardIcon} from "@material-ui/pickers/_shared/icons/KeyboardIcon";
import {useTranslate} from "ra-core";

const Form = (props) => {
    const translate = useTranslate();
    return (
        <CardForm
            {...props}
            redirect="list"
            variant="outlined"
            grid={true}>
            <FormGrid container spacing={2}>
                <FormGridColumn size={4}>
                    <FormCard
                        label={translate('resources.events.name',1)}
                        icon={<VisibilityIcon/>}
                    >
                        <TextInput source="code" validate={required()} fullWidth/>
                        <TextInput source="name" validate={required()} fullWidth/>
                        <NumberInput source="order" validate={required()} fullWidth/>
                        <SelectInput source="type" choices={types} validate={required()} fullWidth/>
                        <SelectInput
                            source="value_type"
                            choices={valueTypes}
                            validate={required()}
                            fullWidth
                        />
                        <SelectInput
                            source="value_formatter"
                            choices={valueFormatters}
                            validate={required()}
                            fullWidth
                        />
                        <BooleanInput
                            source="show_commission"
                            helperText="resources.events.helpers.show_commission"
                            fullWidth
                        />
                        <BooleanInput
                            source="show_column"
                            helperText="resources.events.helpers.show_column"
                            fullWidth
                        />
                    </FormCard>
                </FormGridColumn>
                <FormGridColumn size={8}>
                    <FormCard
                        label="resources.events.fields.roles"
                        icon={<RoleIcon/>}
                    >
                        <ReferenceArrayInput
                            source="roles"
                            reference="roles"
                            fullWidth
                            parse={parseMany}
                            format={formatMany}
                            sort={{field: "name", order: "ASC"}}
                            label="resources.events.fields.roles"
                            helperText="resources.events.helpers.roles"
                        >
                            <CheckboxGroupInput optionText="name"/>
                        </ReferenceArrayInput>
                    </FormCard>
                    <FormCard
                        label="resources.events.fields.sql_expr"
                        icon={<KeyboardIcon/>}
                    >
                        <CodeInput source="sql_expr" type="sql" fullWidth defaultValue=""/>

                    </FormCard>
                </FormGridColumn>
            </FormGrid>
        </CardForm>
    );
};
export default Form;
