import ResponsiveList from "components/lists/ResponsiveList";
import { useListStyles, useSelector } from "components/lists/useStyles";
import useFieldLabel from "components/useFieldLabel";
import React, { Fragment } from "react";
import {
	DateField,
	List as RaList,
	TextField,
	UrlField,
	usePermissions,
	useTranslate,
} from "react-admin";
import perPage from "utils/perPage";
import EditButton from "./buttons/EditButton";
import StatusField from "./fields/StatusField";
import Filters from "./Filters";

const List = (props) => {
	const translate = useTranslate();
	const fieldLabel = useFieldLabel(props);
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const classes = useListStyles({ sidebarOpen });
	const { loaded, permissions } = usePermissions();
	const isAdmin = loaded && permissions(["admin"]);
	return (
		<RaList
			{...props}
			filters={<Filters />}
			classes={classes}
			perPage={perPage(25, 10)}
			sort={{ field: "modified", order: "DESC" }}
		>
			<ResponsiveList
				mobileHeader={
					<Fragment>
						<UrlField source="name" />
					</Fragment>
				}
				mobileBody={
					<Fragment>
						{isAdmin && (
							<TextField
								source="user.name"
								label={fieldLabel("user_id")}
								style={{ fontWeight: "bold" }}
								emptyText={translate("ra.users.all_users")}
							/>
						)}
						<StatusField source="status" />
						<DateField source="modified" showTime />
					</Fragment>
				}
				mobileActions={
					<Fragment>
						<EditButton />
					</Fragment>
				}
			>
				<TextField source="id" />
				{isAdmin && (
					<TextField
						label={fieldLabel("user_id")}
						source="user.name"
						sortBy="Users.username"
						style={{ fontWeight: "bold" }}
						emptyText={translate("ra.users.all_users")}
					/>
				)}
				<UrlField source="name" variant="subtitle2" />
				<StatusField source="status" />
				<DateField source="modified" showTime />
				<EditButton />
			</ResponsiveList>
		</RaList>
	);
};
export default List;
