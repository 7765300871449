import { List, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import SidebarMenuListItem from "./SidebarMenuListItem";
import { matchPath } from "react-router-dom";
import useRouter from "utils/useRouter";
import { useTranslate } from "ra-core";

const SidebarMenuList = (props) => {
  const { pages, ...rest } = props;

  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

SidebarMenuList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const reduceChildRoutes = (props) => {
  const { router, items, page, depth } = props;

  if (page.content) {
    const open = page.content.some((content) =>
      matchPath(router.location.pathname, {
        path: [content.to, `${content.to}/create`],
      })
    );

    items.push(
      <SidebarMenuListItem
        depth={depth}
        icon={page.icon}
        key={page.label}
        badge={page.badge}
        open={Boolean(open)}
        title={page.label}
        localize={page.localize}
      >
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList
            depth={depth + 1}
            pages={page.content}
            router={router}
          />
        </div>
      </SidebarMenuListItem>
    );
  } else {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={page.to}
        icon={page.icon}
        key={page.label}
        badge={page.badge}
        title={page.label}
        localize={page.localize}
      />
    );
  }

  return items;
};

const SidebarMenu = (props) => {
  const { title, pages, className, component: Component, ...rest } = props;

  const router = useRouter();
  const translate = useTranslate();

  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">
          {translate(`menu.items.${title}`)}
        </Typography>
      )}
      <SidebarMenuList depth={0} pages={pages} router={router} />
    </Component>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

SidebarMenu.defaultProps = {
  component: "nav",
};

export default SidebarMenu;
