import types from "campaigns/types";
import { required } from "ra-core";
import { BooleanInput, SelectInput } from "ra-ui-materialui";
import React from "react";

const SettingsInputs = ({ created = false, ...props }) => {
	return (
		<React.Fragment>
			<SelectInput
				{...props}
				source="type"
				variant="outlined"
				helperText="resources.campaigns.helpers.type"
				validate={required()}
				choices={types}
				defaultValue="public"
			/>
			<BooleanInput
				{...props}
				defaultValue={true}
				source="is_approval_required"
				helperText="resources.campaigns.helpers.is_approval_required"
			/>
		</React.Fragment>
	);
};
export default SettingsInputs;
