import { ReferenceInput, SelectInput } from "ra-ui-materialui";
import React from "react";

const LanguageInput = ({ helperText = false, ...props }) => (
	<ReferenceInput
		{...props}
		reference="languages"
		sort={{ field: "name", order: "ASC" }}
	>
		<SelectInput optionText="name" helperText={helperText} />
	</ReferenceInput>
);
export default LanguageInput;
