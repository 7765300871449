import DescriptionIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useValidatableSave from "components/forms/useValidatableSave";
import ImageInput from "components/inputs/ImageInput";
import StateInfoField from "components/workflow/fields/StateInfoField";
import TransactionLogsField from "components/workflow/fields/TransactionLogsField";
import Toolbar from "components/workflow/form/Toolbar";
import NotesInput from "components/workflow/inputs/NotesInput";
import NotesPrivateInput from "components/workflow/inputs/NotesPrivateInput";
import { CampaignMediaIcon, CommissionIcon, LandingIcon } from "icons";
import RichTextInput from "ra-input-rich-text";
import React, { useMemo } from "react";
import { required, TextInput, usePermissions } from "react-admin";
import { CardForm } from "../components/forms/cards/CardForm";
import { FormCard } from "../components/forms/cards/FormCard";
import { FormGrid } from "../components/forms/cards/FormGrid";
import { FormGridColumn } from "../components/forms/cards/FormGridColumn";
import CommissionsField from "./fields/CommissionsField";
import LandingsField from "./fields/LandingsField";
import MediaField from "./fields/MediaField";
import OffersField from "./fields/OffersField";
import SettingsField from "./fields/SettingsField";
import AdvertiserInput from "./inputs/AdvertiserInput";
import CategoryInput from "./inputs/CategoryInput";
import LanguageInput from "./inputs/LanguageInput";
import SettingsInputs from "./inputs/SettingsInput";

const Form = ({ created, onSuccess, ...props }) => {
	const save = useValidatableSave({ ...props, refresh: true, onSuccess });
	const { loaded, permissions } = usePermissions();
	const isAdmin = useMemo(
		() => loaded && permissions && permissions(["admin"]),
		[loaded, permissions]
	);
	return (
		<CardForm
			{...props}
			toolbar={
				<Toolbar>
					<SettingsField />
				</Toolbar>
			}
			save={save}
			grid={true}
		>
			<FormGrid container spacing={2}>
				<FormGridColumn>
					<FormCard
						label="resources.campaigns.tabs.general"
						icon={<VisibilityIcon />}
					>
						{permissions && permissions("admin") && (
							<AdvertiserInput
								variant="outlined"
								source="advertiser_id"
								validate={required()}
								helperText="resources.campaigns.helpers.advertiser_id"
								fullWidth
							/>
						)}
						<CategoryInput
							variant="outlined"
							source="category_id"
							validate={required()}
							helperText="resources.campaigns.helpers.category_id"
							fullWidth
						/>
						<LanguageInput
							variant="outlined"
							source="language_id"
							validate={required()}
							helperText="resources.campaigns.helpers.language_id"
							fullWidth
						/>
						<TextInput
							variant="outlined"
							source="name"
							validate={required()}
							fullWidth
							helperText="resources.campaigns.helpers.name"
						/>
						<RichTextInput
							source="description"
							fullWidth
							multiline
							helperText="resources.campaigns.helpers.description"
						/>
						<ImageInput
							source="preview"
							label="resources.campaigns.fields.preview_media_id"
							helperText="resources.campaigns.helpers.preview_media_id"
						/>
					</FormCard>
					<FormCard
						label="resources.campaigns.tabs.settings"
						icon={<SettingsIcon />}
					>
						<SettingsInputs created={created} fullWidth />
					</FormCard>
				</FormGridColumn>
				<FormGridColumn>
					{created && (
						<FormCard
							label="resources.campaigns.tabs.landings"
							icon={<LandingIcon />}
						>
							<LandingsField
								source="landings"
								fullWidth
								useLabel={false}
								canModify
								canCreate
								canRemove
								showCreateLinkButton={false}
							/>
						</FormCard>
					)}
					{created && (
						<FormCard
							label="resources.campaigns.tabs.offers"
							icon={<CommissionIcon />}
						>
							<OffersField
								variant="outlined"
								source="campaign_offers"
								helperText="resources.campaigns.helpers.campaign_offers"
								fullWidth
								useLabel={false}
							/>
						</FormCard>
					)}
					{created && (
						<FormCard
							label="resources.campaigns.tabs.media"
							icon={<CampaignMediaIcon />}
						>
							<MediaField useLabel={false} source="media" fullWidth />
						</FormCard>
					)}
					{created && (
						<FormCard
							label="resources.campaigns.tabs.commissions"
							icon={<CommissionIcon />}
						>
							<CommissionsField
								source="commissions"
								fullWidth
								useLabel={false}
							/>
						</FormCard>
					)}
				</FormGridColumn>
				<FormGridColumn size={12}>
					<FormCard
						label="resources.campaigns.tabs.logs"
						icon={<DescriptionIcon />}
						size={12}
					>
						<TransactionLogsField admin={isAdmin} useLabel={false} fullWidth />
						<NotesInput variant="outlined" source="notes" fullWidth />
						<NotesPrivateInput admin={isAdmin} source="is_private" fullWidth />
						<StateInfoField />
					</FormCard>
				</FormGridColumn>
			</FormGrid>
		</CardForm>
	);
};
export default Form;
