import { makeStyles } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import {
	EditButton,
	ImageField,
	List as RaList,
	NumberField,
	TextField,
} from "react-admin";
import perPage from "utils/perPage";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import Actions from "./Actions";
import CloneButton from "./buttons/CloneButton";
import Filters from "./Filters";

const useStyles = makeStyles((theme) => ({
	image: {
		"& img": { width: 70, height: 70, objectFit: "cover" },
	},
}));

const Datagrid = (props) => {
	const classes = useStyles();
	const hasContestFilter = get(props, "filterValues.contest_id");
	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField source="name" style={{ fontWeight: "bold" }} />
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
					<TextField
						source="contest.title"
						label="resources.rewards.fields.contest_id"
					/>
					<ImageField
						source="image.file.path"
						className={classes.image}
						label="resources.rewards.fields.image_media_id"
					/>
					<NumberField source="points_from" />
					<NumberField source="points_to" />
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<EditButton />
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			{!hasContestFilter && (
				<TextField
					source="contest.title"
					label="resources.rewards.fields.contest_id"
					style={{ fontWeight: "bold" }}
				/>
			)}
			<ImageField
				source="image.file.path"
				className={classes.image}
				label="resources.rewards.fields.image_media_id"
			/>
			<TextField source="name" style={{ fontWeight: "bold" }} />
			<NumberField source="points_from" />
			<NumberField source="points_to" />
			<EditButton />
			<CloneButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			actions={<Actions />}
			perPage={perPage()}
			exporter={false}
			sort={{ field: "points_from", order: "asc" }}
		>
			<Datagrid />
		</RaList>
	);
};
export default List;
