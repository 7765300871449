import { IconButton, InputAdornment } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { get } from "lodash";
import React from "react";
import { TextInput, useNotify } from "react-admin";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

const CopiableInput = ({ record, source, ...props }) => {
	const value = get(record, source);
	const notify = useNotify();
	const handleClick = (e) => {
		e.target.select();
	};
	const handleButtonClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};
	return (
		<TextInput
			{...props}
			onClick={handleClick}
			record={record}
			source={source}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<CopyToClipboard
							text={value}
							onCopy={() =>
								notify("resources.landings.actions.copied_script", "info")
							}
						>
							<IconButton aria-label="copy script" onClick={handleButtonClick}>
								<FileCopyIcon />
							</IconButton>
						</CopyToClipboard>
					</InputAdornment>
				),
			}}
		/>
	);
};
export default CopiableInput;
