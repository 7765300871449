import {
    BooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
    useTranslate,
} from "react-admin";

import ImageInput from "../components/inputs/ImageInput";
import React from "react";
import RichTextInput from "ra-input-rich-text";
import {CardForm} from "../components/forms/cards/CardForm";
import {FormGridColumn} from "../components/forms/cards/FormGridColumn";
import {FormGrid} from "../components/forms/cards/FormGrid";
import {FormCard} from "../components/forms/cards/FormCard";
import {ApiIcon, PagesIcon} from "../icons";

const Form = (props) => {
    const translate = useTranslate();
    return (
        <CardForm {...props} redirect="list"
                  variant="outlined"
                  grid={true}>
            <FormGrid container spacing={2}>
                <FormGridColumn size={6}>
                    <FormCard label={translate("resources.pages.name", 1)}
                              icon={<PagesIcon/>}>
                        <FormGrid container spacing={2}>
                            <FormGridColumn size={6}>
                                <ReferenceInput
                                    source="language_id"
                                    reference="languages"
                                    validate={required()}
                                    sort={{field: "name", order: "ASC"}}
                                >
                                    <SelectInput optionText="name"/>
                                </ReferenceInput>
                                <TextInput source="code" validate={required()}/>
                                <TextInput source="name" validate={required()}/>
                            </FormGridColumn>
                            <FormGridColumn size={6}>
                                <BooleanInput source="is_menu"/>
                                <NumberInput source="order"/>
                            </FormGridColumn>
                            <FormGridColumn size={12}>
                                <RichTextInput source="content" multline fullWidth {...props} />
                                <ImageInput source="image"/>
                            </FormGridColumn>
                        </FormGrid>
                    </FormCard>
                </FormGridColumn>
                <FormGridColumn size={6}>
                    <FormCard label="resources.pages.og"
                              icon={<ApiIcon/>}>
                        <TextInput source="og_title" fullWidth/>
                        <TextInput source="og_description" multiline rows={5} fullWidth/>
                        <ImageInput source="og_image"/>
                    </FormCard>
                </FormGridColumn>
            </FormGrid>
        </CardForm>
    );
};
export default Form;
