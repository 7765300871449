import { SelectInput, TextInput } from "ra-ui-materialui";

import RaInput from "components/inputs/RaInput";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { required } from "ra-core";
import types from "landings/types";
import { url } from "utils/validation";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    clear: "both",
    "& .MuiTextField-root": {
      width: "100%",
      marginRight: 0,
    },
  },
  input: {
    // float: "left",
    marginRight: theme.spacing(2),
    width: 256,
  },
  fullWidth: {
    marginRight: theme.spacing(2),
    width: "100%",
  },
}));

const SettingsInputs = ({ created = false, ...props }) => {
  const classes = useStyles();
  const properties = {
    ...props,
    className: classes.input,
  };
  return (
    <div className={classes.root}>
      <RaInput>
        <SelectInput
          {...properties}
          source="type"
          helperText="resources.landings.helpers.type"
          validate={required()}
          choices={types}
          defaultValue="redirect"
        />
      </RaInput>
      <RaInput>
        <TextInput
          {...properties}
          source="redirect_url"
          validate={[required(), url]}
          className={classes.fullWidth}
        />
      </RaInput>
    </div>
  );
};
export default SettingsInputs;
