import {TextInput, required} from "react-admin";

import React from "react";
import StatusInput from "./inputs/StatusInput";
import UserInput from "./inputs/UserInput";
import useFieldLabel from "components/useFieldLabel";
import useValidatableSave from "components/forms/useValidatableSave";
import {CardForm} from "../components/forms/cards/CardForm";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {FormCard} from "../components/forms/cards/FormCard";
import {useTranslate} from "ra-core";

const Form = ({create = false, permissions, ...props}) => {
  const save = useValidatableSave({...props, redirect: undefined});
  const fieldLabel = useFieldLabel(props);
  const isAdmin = permissions && permissions(["admin"]);
  const translate = useTranslate();
  return (
    <CardForm {...props} save={save}
              variant="outlined">
      <FormCard size={4} offset={4}
                label={translate('resources.user-parent-approvals.name',1)}
                icon={<VisibilityIcon/>}
      >
        {!isAdmin && (
          <TextInput
            source="email"
            validate={required()}
            fullWidth
            helperText={fieldLabel("email.help")}
          />
        )}
        {isAdmin && <UserInput source="parent_id" validate={required()} fullWidth/>}
        {isAdmin && <UserInput source="user_id" validate={required()} fullWidth/>}
        {isAdmin && <StatusInput source="status" validate={required()} fullWidth/>}
      </FormCard>
    </CardForm>
  );
};
export default Form;
