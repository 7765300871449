import { Grid } from "@material-ui/core";
import React, { Children, Fragment, isValidElement } from "react";

export const FormGridColumn = ({ size, children, ...props }) => {
	return (
		<Grid item xs={12} md={size}>
			{Children.map(children, (column, index) => {
				if (!column) {
					return;
				}
				return (
					<Fragment>
						{isValidElement(column) ? React.cloneElement(column, props) : null}
					</Fragment>
				);
			})}
		</Grid>
	);
};

FormGridColumn.defaultProps = {
	size: 6,
};
