import { get, set } from "lodash";

import { API_URL } from "./config";
import { fetchJson } from "./fetch";
import getHeaders from "./authHeaders";
import { stringify } from "query-string";

const prepareData = (data) => {
  let newData = Object.keys(data).reduce(
    (r, key) => ({
      ...r,
      [key]: Array.isArray(data[key])
        ? data[key].map((item) => {
            if (item._joinData === null) {
              return { id: item.id };
            }
            return item;
          })
        : data[key],
    }),
    {}
  );

  return newData;
};

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
const convertFile = async (file) =>
  file.rawFile
    ? convertFileToBase64(file).then((convertedFile) => ({
        data: convertedFile,
        name: file.rawFile.name,
        size: file.rawFile.size,
        type: file.rawFile.type,
      }))
    : Promise.resolve(file);

const FILE_FIELDS = [
  "cover",
  "docs",
  "favicon",
  "image",
  "logo",
  "media",
  "media",
  "og_image",
  "picture",
  "preview",
  "profile.picture",
  "template",
];

async function prepareUpload(data) {
  for (var i = 0; i < FILE_FIELDS.length; i++) {
    const field = FILE_FIELDS[i];
    const value = get(data, field);
    if (value && Array.isArray(value)) {
      const files = await value.map((file) => convertFile(file));
      data = set(data, field, files);
    } else if (value) {
      const file = await convertFile(value);
      data = set(data, field, file);
    }
  }
  return Promise.resolve(data);
}

const dataProvider = {
  getInfo: (resource, params) => {
    const url = `${API_URL}/${resource}/info?${stringify(params)}`;
    const options = { headers: getHeaders() };
    return fetchJson(url, options).then(({ json }) => ({
      data: json.data,
    }));
  },
  getBadges: () => {
    const url = `${API_URL}/stats/badges`;
    const options = { headers: getHeaders() };
    return fetchJson(url, options).then(({ json }) => ({
      data: json,
    }));
  },
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const filter = Object.keys(params.filter || {}).reduce(
      (f, filterName) => ({
        ...f,
        [filterName]:
          params.filter[filterName] instanceof Array
            ? params.filter[filterName].join(",")
            : params.filter[filterName],
      }),
      {}
    );

    const query = {
      sort: field,
      direction: order,
      page: page,
      limit: perPage,
      ...filter,
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;
    const options = { headers: getHeaders() };

    return fetchJson(url, options).then(({ json }) => ({
      data: json.data,
      total: parseInt(json.pagination.count, 10),
    }));
  },
  getOne: (resource, params) => {
    let url = `${API_URL}/${resource}` + (params.id ? `/${params.id}` : "");
    let options = { headers: getHeaders() };
    return fetchJson(url, options).then(({ json }) => ({
      data: json.data,
    }));
  },
  getMany: (resource, params) => {
    const query = {
      ids: params.ids.map((id) => (id.id ? id.id : id)).join(","),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;
    const options = { headers: getHeaders() };
    return fetchJson(url, options).then(({ json }) => ({
      data: json.data,
      total: parseInt(json.pagination.count, 10),
    }));
  },
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = Object.keys(params.filter || {}).reduce(
      (f, filterName) => ({
        ...f,
        [filterName]:
          params.filter[filterName] instanceof Array
            ? params.filter[filterName].join(",")
            : params.filter[filterName],
      }),
      {}
    );

    const query = {
      sort: field,
      direction: order,
      page: page,
      limit: perPage,
      [params.target]: params.id,
      ...filter,
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;
    const options = { headers: getHeaders() };
    return fetchJson(url, options).then(({ json }) => ({
      data: json.data,
      total: parseInt(json.pagination.count, 10),
    }));
  },
  create: (resource, params) =>
    prepareUpload(params.data).then((data) => {
      const url = `${API_URL}/${resource}`;
      const options = {
        method: "POST",
        body: JSON.stringify(prepareData(data)),
        headers: getHeaders(),
      };
      return fetchJson(url, options).then(({ json }) => ({
        data: { ...(json.data || params.data), id: json.data.id },
      }));
    }),
  update: (resource, params) =>
    prepareUpload(params.data).then((data) => {
      const id = data && data.pk ? data.pk : params.id;
      const url = `${API_URL}/${resource}` + (id ? `/${id}` : "");
      const options = {
        method: "PUT",
        body: JSON.stringify(prepareData(data)),
        headers: getHeaders(),
      };
      return fetchJson(url, options).then(({ json }) => ({
        data: { id: data.pk, ...json.data },
      }));
    }),
  updateMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) =>
        fetchJson(`${API_URL}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
          headers: getHeaders(),
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  },
  delete: (resource, params) => {
    const url = `${API_URL}/${resource}/${params.id}`;
    const options = { method: "DELETE", headers: getHeaders() };
    return fetchJson(url, options).then(({ json }) => ({
      data: json,
    }));
  },
  deleteMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) =>
        fetch(`${API_URL}/${resource}/${id}`, {
          method: "DELETE",
          headers: getHeaders(),
        }).then((response) => response.json())
      )
    ).then((responses) => {
      let errors = responses.filter(
        (r) =>
          r.data && r.data.code && (r.data.code === 409 || r.data.code === 403)
      );
      if (errors.length > 0) {
        return Promise.reject(errors.map((e) => e.data.message).join("\n"));
      }

      return {
        data: responses.map(({ json }) => ({ data: json })),
      };
    });
  },

  post(resource, params) {
    const url = `${API_URL}/${resource}`;
    const { body } = params;
    const options = {
      body: JSON.stringify(body),
      method: "POST",
      headers: getHeaders(),
    };
    return fetchJson(url, options).then(({ json }) => ({ data: json }));
  },
  get(resource, params) {
    const { query } = params;
    const queryString = stringify(query);
    const url = `${API_URL}/${resource}?${queryString}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    return fetchJson(url, options).then(({ json }) => ({ data: json }));
  },
  getTransactions(resource, params) {
    const url = `${API_URL}/workflow/transactions/${resource}/${params.id}`;
    return fetchJson(url, {
      headers: getHeaders(),
    }).then(({ json }) => ({
      data: json.data,
    }));
  },
};

export default dataProvider;
