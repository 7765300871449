import { ReferenceInput, SelectInput } from "ra-ui-materialui";
import React from "react";

const DomainInput = (props) => (
	<ReferenceInput
		{...props}
		reference="domains"
		sort={{ field: "name", order: "ASC" }}
		filter={{
			status: "approved",
		}}
	>
		<SelectInput optionText="name" />
	</ReferenceInput>
);
export default DomainInput;
