import { Container, Grid } from "@material-ui/core";

import React from "react";

/** Created to improve code reading. */
const BasicLayout = ({ children, ...props }) => (
  <div className="app-wrapper min-vh-100">
    <div className="app-main flex-column">
      <div className="app-content p-0">
        <div className="app-content--inner d-flex align-items-center">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content py-5">
              <Container maxWidth="lg">
                <Grid container spacing={5} justify="center">
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    md={6}
                    sm={8}
                    className="d-flex flex-column align-items-center"
                    {...props}
                  >
                    {children}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default BasicLayout;
