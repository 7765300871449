import VisibilityIcon from "@material-ui/icons/Visibility";
import { useTranslate } from "ra-core";
import React from "react";
import { AutocompleteInput, ReferenceInput, required } from "react-admin";
import { CardForm } from "../components/forms/cards/CardForm";
import { FormCard } from "../components/forms/cards/FormCard";

const Form = (props) => {
	const translate = useTranslate();
	return (
		<CardForm {...props} variant="outlined">
			<FormCard
				size={4}
				offset={4}
				label={translate(`resources.${props.resource}.name`, 1)}
				icon={<VisibilityIcon />}
			>
				<ReferenceInput
					source="campaign_id"
					reference="campaigns"
					fullWidth
					validate={required()}
				>
					<AutocompleteInput optionText="name" />
				</ReferenceInput>
				<ReferenceInput
					source="user_id"
					reference="users"
					fullWidth
					validate={required()}
				>
					<AutocompleteInput optionText="name" />
				</ReferenceInput>
			</FormCard>
		</CardForm>
	);
};

export default Form;
