import React from "react";
import { SelectInput } from "ra-ui-materialui";

const choices = ["cod"].map((id) => ({
  id,
  name: `resources.orders.payment_method.${id}`,
}));

const PaymentMethodInput = (props) => (
  <SelectInput {...props} choices={choices} />
);
export default PaymentMethodInput;
