import React from "react";
import {
	AutocompleteInput,
	Filter,
	ReferenceInput,
	SearchInput,
} from "react-admin";
import { useFiltersStyles } from "../components/lists/useStyles";

const Filters = (props) => {
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes} variant="outlined">
			<SearchInput source="q" alwaysOn fullWidth />
			<ReferenceInput
				source="campaign_id"
				reference="campaigns"
				fullWidth
				alwaysOn
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
		</Filter>
	);
};
export default Filters;
