import { makeStyles } from "@material-ui/core";

const useCheckboxGroupStyles = makeStyles((theme) => ({
  root: {
    "& label": {
      width: 250,
    },
  },
  rootFullWidth: {
    "& label": {
      width: "100%",
    },
  },
}));
export default useCheckboxGroupStyles;
