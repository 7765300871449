import { CategoryIcon, UserIcon } from "../icons";
import {
  Toolbar as RaToolbar,
  SaveButton,
  TextInput,
  maxLength,
  required,
  useNotify,
  usePermissions,
  useRefresh,
} from "react-admin";
import React, { Fragment, useCallback } from "react";

import { CardForm } from "components/forms/cards/CardForm";
import { CompletePercentageField } from "./fields/CompletePercentageField";
import { FormCard } from "components/forms/cards/FormCard";
import { FormGrid } from "../components/forms/cards/FormGrid";
import { FormGridColumn } from "../components/forms/cards/FormGridColumn";
import { Grid } from "@material-ui/core";
import ImageInput from "components/inputs/ImageInput";
import PasswordInput from "../users/inputs/PasswordInput";
import PaymentMethodsInput from "../users/inputs/PaymentMethodInput";
import ReferralCodeInput from "./inputs/ReferralCodeInput";
import TaxInput from "./inputs/TaxInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useValidatableSave from "components/forms/useValidatableSave";

const Toolbar = (props) => (
  <RaToolbar {...props}>
    <SaveButton />
  </RaToolbar>
);

const Form = ({ title, saving, mutationMode, save: saved, ...props }) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleSuccess = useCallback(
    ({
      data: {
        profile: { picture },
      },
    }) => {
      notify("resources.user-profiles.updated");

      const profile = JSON.parse(localStorage.getItem("profile"));
      profile.picture = picture;
      localStorage.setItem("profile", JSON.stringify(profile));

      refresh();
    },
    [notify, refresh]
  );
  const save = useValidatableSave({
    ...props,
    resource: "users/profile",
    onSuccess: handleSuccess,
  });

  return (
    <CardForm
      {...props}
      toolbar={<Toolbar />}
      grid={true}
      initialValues={{
        accepted_regulation: true,
        accepted_disclaimer: true,
      }}
      save={save}
      variant="outlined"
    >
      <CompletePercentageField source="profile.complete_percentage" />
      <FormGrid container spacing={2}>
        <FormGridColumn size={5}>
          <FormCard
            label="resources.user-profiles.tabs.avatar"
            icon={<UserIcon />}
          >
            <ImageInput source="profile.picture" />
          </FormCard>
          <FormCard
            label="ra.auth.signup.fields.password"
            icon={<VisibilityIcon />}
          >
            <PasswordInput {...props} permissions={permissions} fullWidth />
          </FormCard>
        </FormGridColumn>
        <FormGridColumn size={7}>
          <FormCard
            label="resources.user-profiles.tabs.general"
            icon={<CategoryIcon />}
          >
            <Card permissions={permissions} />
          </FormCard>
        </FormGridColumn>
      </FormGrid>
    </CardForm>
  );
};

const Card = ({ permissions, className, ...props }) => {
  const inputProps = {
    ...props,
    fullWidth: true,
  };
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <TextInput
            {...inputProps}
            source="profile.name"
            validate={required()}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            {...inputProps}
            source="profile.surname"
            validate={required()}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput {...inputProps} source="email" validate={required()} />
        </Grid>
        <Grid item md={6}>
          <ReferralCodeInput {...inputProps} source="referral_code" readOnly />
        </Grid>
        <Grid item md={6}>
          <TextInput
            {...inputProps}
            source="profile.site_link"
            validate={required()}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            {...inputProps}
            source="profile.facebook_profile"
            validate={required()}
          />
        </Grid>
        <Grid item md={6}>
          <TextInput
            {...inputProps}
            source="profile.company_name"
            validate={[maxLength(100), required()]}
          />
        </Grid>
        <TaxInput {...inputProps} source="profile." />
        <TextInput
          {...inputProps}
          source="profile.address"
          validate={[maxLength(100), required()]}
        />
        <TextInput
          {...inputProps}
          source="profile.phone"
          validate={maxLength(30)}
        />
        <TextInput
          {...inputProps}
          source="profile.contact"
          validate={maxLength(100)}
          helperText="resources.users.fields.profile.contact.help"
        />
        <PaymentMethodsInput {...inputProps} source="profile.payment_method" />
      </Grid>
    </Fragment>
  );
};

export default Form;
