import List from "./List";
import { UserIcon } from "icons";

export default {
  options: {
    group: "dashboard",
    roles: ["referral"],
  },
  icon: UserIcon,
  list: List,
  create: () => {},
};
