import React from "react";
import { ReferenceInput, SelectInput } from "react-admin";

const CategoryInput = ({ helperText = false, ...props }) => (
	<ReferenceInput
		{...props}
		reference="categories"
		sort={{ field: "name", order: "ASC" }}
	>
		<SelectInput optionText="name" helperText={helperText} />
	</ReferenceInput>
);
export default CategoryInput;
