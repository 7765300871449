import { Button, makeStyles } from "@material-ui/core";
import { useFormStyles } from "components/forms/useStyles";
import useValidatableSave from "components/forms/useValidatableSave";
import BooleanWithLinkInput from "components/inputs/BooleanWithLinkInput";
import BasicLayout from "components/layout/BasicLayout";
import BasicTitle from "components/layout/BasicTitle";
import { get } from "lodash";
import {
	maxLength,
	required,
	useNotify,
	useRedirect,
	useTranslate,
} from "ra-core";
import {
	Create,
	RadioButtonGroupInput,
	ReferenceInput,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar as RaToolbar,
} from "ra-ui-materialui";
import React from "react";
import { Link } from "react-router-dom";
import TaxInput from "user-profiles/inputs/TaxInput";

const passwordMatchValidator = () => (value, values) => {
	const password = get(values, "password", null);
	return password !== value ? "ra.validation.password_match_fail" : false;
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			width: "auto",
		},
	},
}));

const BackButton = () => {
	const translate = useTranslate();
	return (
		<Button type="button" component={Link} to="/login">
			&larr;
			{translate("ra.auth.back_to_login")}
		</Button>
	);
};
const Toolbar = ({ basePath, ...props }) => {
	return (
		<RaToolbar {...props}>
			<SaveButton label="ra.auth.signup" />
			<BackButton />
		</RaToolbar>
	);
};

const RolesInput = ({ setFilter, setPagination, setSort, choices, ...props }) =>
	choices ? <RadioButtonGroupInput choices={choices} {...props} /> : null;

const SignupPage = ({ staticContext, ...props }) => {
	const classes = useFormStyles();
	const notify = useNotify();
	const redirect = useRedirect();
	const localClasses = useStyles();
	const save = useValidatableSave({
		resource: "users",
		redirect: "/login",
		onSuccess: () => {
			notify("resources.users.messages.signup_completed");
			redirect("/login");
		},
	});

	return (
		<BasicLayout>
			<BasicTitle message="ra.auth.signup" />
			<Create
				{...props}
				className={localClasses.root}
				classes={classes}
				basePath="users"
				resource="users"
			>
				<SimpleForm save={save} toolbar={<Toolbar />}>
					<ReferenceInput
						source="role_id"
						reference="pub/roles"
						validate={required()}
						sort={{ field: "name", direction: "ASC" }}
					>
						<RolesInput optionText="name" validate={required()} />
					</ReferenceInput>
					<TextInput source="profile.name" validate={required()} fullWidth />
					<TextInput source="profile.surname" validate={required()} fullWidth />
					<TaxInput source="profile." fullWidth />
					<TextInput
						fullWidth
						source="profile.address"
						validate={[required(), maxLength(100)]}
					/>
					<TextInput
						fullWidth
						source="profile.phone"
						validate={[required(), maxLength(30)]}
					/>
					<TextInput
						fullWidth
						source="profile.contact"
						validate={[maxLength(100)]}
						helperText="resources.users.fields.profile.contact.help"
					/>
					<TextInput source="email" validate={required()} fullWidth />
					<TextInput
						source="password"
						validate={required()}
						fullWidth
						type="password"
					/>
					<TextInput
						source="password_confirm"
						validate={[required(), passwordMatchValidator()]}
						fullWidth
						type="password"
					/>
					<TextInput
						source="profile.site_link"
						validate={required()}
						fullWidth
					/>
					<TextInput
						source="profile.facebook_profile"
						validate={required()}
						fullWidth
					/>
					<BooleanWithLinkInput
						source="accepted_regulation"
						link="/regulation"
						linkLabel="resources.users.fields.accepted_regulation.link_label"
						validate={required()}
					/>
					<BooleanWithLinkInput
						source="accepted_disclaimer"
						link="/disclaimer"
						linkLabel="resources.users.fields.accepted_disclaimer.link_label"
						validate={required()}
					/>
				</SimpleForm>
			</Create>
		</BasicLayout>
	);
};
export default SignupPage;
