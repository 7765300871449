import AmountField from "commissions/fields/AmountField";
import CampaignField from "commissions/fields/CampaignField";
import UserField from "commissions/fields/UserField";
import { get } from "lodash";
import React from "react";
import { ChipField, TextField, useRedirect } from "react-admin";
import ListField, { makeRedirect } from "../../components/fields/ListField";

const CommissionsField = ({ planned, ...props }) => {
	const redirect = useRedirect();
	const id = get(props, "record.id", 0);
	if (id <= 0) {
		return null;
	}
	return (
		<ListField
			{...props}
			reference="commissions"
			target="campaign_id"
			canModify={true}
			canCreate={true}
			canRemove={true}
			sort={{ field: "id", order: "ASC" }}
			removeRedirect={() => redirect(makeRedirect({ ...props, tab: 1 }))}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			<ChipField source="event.name" />
			<CampaignField source="campaign.name" />
			<UserField source="user.name" />
			<AmountField source="amount" />
		</ListField>
	);
};

export default CommissionsField;
