import { DateField, List as RaList, TextField } from "react-admin";
import React, { useState } from "react";

import ApprovalDialog from "./dialogs/ApprovalDialog";
import Filters from "./Filters";
import ResponsiveList from "../components/lists/ResponsiveList";
import SettingsField from "./fields/SettingsField";
import StatusField from "./fields/StatusField";
import { useListStyles } from "../components/lists/useStyles";

const List = (props) => {
  const [record, setRecord] = useState(null);
  const handleSetRecord = (record) => setRecord(record);
  const handleUnsetRecord = () => setRecord(null);
  const classes = useListStyles();
  return (
    <>
      <RaList
        {...props}
        classes={classes}
        perPage={25}
        filters={<Filters />}
        filterDefaultValues={{ status: "pending" }}
        sort={{ field: "created", order: "desc" }}
      >
        <ResponsiveList
          mobileHeader={
            <React.Fragment>
              <TextField
                source="user.name"
                label="resources.user-profile-approvals.fields.user_id"
                style={{ fontWeight: "bold" }}
              />
              <StatusField source="status" />
            </React.Fragment>
          }
          mobileBody={
            <React.Fragment>
              <DateField showTime source="created" color="textSecondary" />
            </React.Fragment>
          }
          mobileActions={
            <React.Fragment>
              <SettingsField
                sortable={false}
                label={""}
                onViewDetailsClick={handleSetRecord}
              />
            </React.Fragment>
          }
        >
          <TextField
            source="user.name"
            label="resources.user-profile-approvals.fields.user_id"
            style={{ fontWeight: "bold" }}
          />
          <StatusField source="status" />
          <DateField showTime source="created" />
          <SettingsField
            sortable={false}
            label={""}
            onViewDetailsClick={handleSetRecord}
          />
        </ResponsiveList>
      </RaList>
      <ApprovalDialog record={record} onCloseRequest={handleUnsetRecord} />
    </>
  );
};
export default List;
