import useFieldLabel from "components/useFieldLabel";
import { get } from "lodash";
import React from "react";
import { TextField, useRedirect } from "react-admin";
import DownloadButton from "../../campaign-media/buttons/DownloadButton";
import ListField, { makeRedirect } from "../../components/fields/ListField";

const MediaField = ({ planned, ...props }) => {
	const redirect = useRedirect();
	const fieldLabel = useFieldLabel({ resource: "campaign-media" });
	const id = get(props, "record.id", 0);
	if (id <= 0) {
		return null;
	}
	return (
		<ListField
			{...props}
			reference="campaign-media"
			target="campaign_id"
			sort={{ field: "id", order: "ASC" }}
			removeRedirect={() => redirect(makeRedirect({ ...props, tab: 3 }))}
		>
			<TextField label="ID" source="id" />
			<TextField
				label={fieldLabel("campaign_media_category_id")}
				source="campaign_media_category.name"
				sortBy="CampaignMediaCategories.name"
			/>
			<TextField label="app.filename" source="media.filename" />
			<DownloadButton label="" source="media.file" />
		</ListField>
	);
};

MediaField.defaultProps = {
	canModify: false,
	canCreate: true,
	canRemove: true,
};

export default MediaField;
