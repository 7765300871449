import { SelectInput } from "ra-ui-materialui";
import React from "react";
import { useForm } from "react-final-form";

const ITEMS = ["none", "referral"].map((id) => ({
	id,
	name: `resources.commissions.type.${id}`,
}));

const TypeInput = (props) => {
	const form = useForm();
	const handleChange = () => {
		form.change("event_id", null);
	};
	return <SelectInput {...props} choices={ITEMS} onChange={handleChange} />;
};
export default TypeInput;
