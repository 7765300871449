import React, { useMemo } from "react";

import DatagridRow from "./DatagridRow";

const Datagrid = ({ data }) => {
  const rows = useMemo(() => Object.keys(data).map((id) => data[id]), [data]);

  return rows.map((row) => <DatagridRow key={row.id} row={row} />);
};

export default Datagrid;
