import React, { useMemo } from "react";

import { ReferenceInput } from "ra-ui-materialui";
import { SelectInput } from "react-admin";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const LandingInput = (props) => {
  const formState = useFormState();
  const campaignId = useMemo(() => get(formState.values, "campaign_id"), [
    formState.values,
  ]);
  return (
    <ReferenceInput
      {...props}
      reference="landings"
      filter={{ campaign_id: campaignId }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default LandingInput;
