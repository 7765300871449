import { DateInput, Filter } from "ra-ui-materialui";
import React, { useMemo } from "react";

import CampaignInput from "./inputs/CampaignInput";
import DateRangeInput from "components/inputs/DateRangeInput";
import SearchInput from "components/inputs/SearchInput";
import StatusInput from "./inputs/StatusInput";
import UserInput from "./inputs/UserInput";
import { get } from "lodash";
import { useMediaQuery } from "@material-ui/core";
import { usePermissions } from "ra-core";
import {useFiltersStyles} from "../components/lists/useStyles";

const Filters = (props) => {
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { loading, permissions } = usePermissions();
  const admin = useMemo(
    () => !loading && permissions(["admin", "advertiser"]),
    [loading, permissions]
  );
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes} variant="outlined">
      <SearchInput source="q" alwaysOn fullWidth style={{marginTop:32}} />
      <DateRangeInput
        source="view"
        displayedFilters={props.displayedFilters}
        filterValues={props.filterValues}
        setFilters={props.setFilters}
        alwaysOn
        fullWidth
        style={!sm ? { marginTop: 32 } : {marginTop: 0}}
      />

      {get(props, "filterValues.view") === "range" && (
        <DateInput source="start_at" alwaysOn fullWidth />
      )}
      {get(props, "filterValues.view") === "range" && (
        <DateInput source="end_at" alwaysOn fullWidth />
      )}
      {get(props, "filterValues.view") === "day" && (
        <DateInput source="day" alwaysOn fullWidth />
      )}
      <StatusInput source="status" alwaysOn={!sm} />
      <CampaignInput source="campaign_id" />
      {admin && <UserInput source="user_id" />}
    </Filter>
  );
};
export default Filters;
