import { useMediaQuery } from "@material-ui/core";
import { useFiltersStyles } from "components/lists/useStyles";
import React from "react";
import { Filter, ReferenceInput, SelectInput } from "react-admin";
import SearchInput from "../components/inputs/SearchInput";

const Filters = (props) => {
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes} variant="outlined">
			<SearchInput source="q" alwaysOn fullWidth />
			<ReferenceInput
				source="contest_id"
				fullWidth
				reference="contests"
				resettable
				style={sm ? { marginRight: 16 } : { marginRight: 0 }}
			>
				<SelectInput optionText="title" />
			</ReferenceInput>
		</Filter>
	);
};
export default Filters;
