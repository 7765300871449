import { InputAdornment } from "@material-ui/core";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import ImageInput from "../components/inputs/ImageInput";

const Form = (props) => {
	return (
		<SimpleForm {...props} redirect="list">
			<ReferenceInput
				source="contest_id"
				reference="contests"
				fullWidth
				validate={required()}
			>
				<SelectInput optionText="title" />
			</ReferenceInput>
			<TextInput source="name" fullWidth validate={required()} />
			<RichTextInput
				{...props}
				source="description"
				multline
				fullWidth
				validate={required()}
			/>
			<ImageInput
				source="image"
				label="resources.rewards.fields.image_media_id"
			/>
			<NumberInput
				source="price"
				validate={required()}
				InputProps={{
					endAdornment: <InputAdornment position="end">€</InputAdornment>,
				}}
			/>
			<NumberInput
				source="points_from"
				validate={required()}
				InputProps={{
					endAdornment: <InputAdornment position="end">pt</InputAdornment>,
				}}
			/>
			<NumberInput
				source="points_to"
				validate={required()}
				InputProps={{
					endAdornment: <InputAdornment position="end">pt</InputAdornment>,
				}}
			/>
		</SimpleForm>
	);
};
export default Form;
