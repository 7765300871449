import clsx from "clsx";
import PropTypes from "prop-types";
import { useGetIdentity } from "ra-core";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Sidebar from "./Sidebar";

const LeftSidebar = (props) => {
	const {
		children,
		sidebarToggle,
		sidebarFixed,
		footerFixed,
		contentBackground,
	} = props;
	const { loading, loaded, identity } = useGetIdentity();
	if (loading || !loaded || identity === null || identity.id <= 0) {
		return children;
	}
	return (
		<Fragment>
			<div className={clsx("app-wrapper", contentBackground)}>
				<Header />
				<div
					className={clsx("app-main", {
						"app-main-sidebar-static": !sidebarFixed,
					})}
				>
					<Sidebar />
					<div
						className={clsx("app-content", {
							"app-content-sidebar-collapsed": sidebarToggle,
							"app-content-sidebar-fixed": sidebarFixed,
							"app-content-footer-fixed": footerFixed,
						})}
					>
						<div>
							<div className="app-content--inner__wrapper">{children}</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

LeftSidebar.propTypes = {
	children: PropTypes.node,
};

const mapStateToProps = (state) => ({
	sidebarToggle: state.ThemeOptions.sidebarToggle,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
	sidebarFixed: state.ThemeOptions.sidebarFixed,

	headerFixed: state.ThemeOptions.headerFixed,
	headerSearchHover: state.ThemeOptions.headerSearchHover,
	headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

	footerFixed: state.ThemeOptions.footerFixed,

	contentBackground: state.ThemeOptions.contentBackground,
});

export default connect(mapStateToProps)(LeftSidebar);
