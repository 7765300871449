import { Grid } from '@material-ui/core';
import React from 'react';

const GridSingle = ({ children, className = '', ...props }) => {
  return (
    <Grid container className={className}>
      <Grid item xs={12} {...props}>
        {children}
      </Grid>
    </Grid>
  );
};

export default GridSingle;
