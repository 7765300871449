import { CardForm } from "components/forms/cards/CardForm";
import { FormCard } from "components/forms/cards/FormCard";
import useValidatableSave from "components/forms/useValidatableSave";
import { ContestIcon } from "icons";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
	BooleanInput,
	DateTimeInput,
	required,
	TextInput,
	useTranslate,
} from "react-admin";

const Form = (props) => {
	const save = useValidatableSave({ ...props, redirect: undefined });
	const translate = useTranslate();
	return (
		<CardForm
			{...props}
			save={save}
			initialValues={{ status: "pending" }}
			variant="outlined"
		>
			<FormCard
				size={8}
				offset={2}
				label={translate("resources.contests.name", 1)}
				icon={<ContestIcon />}
			>
				<TextInput source="title" fullWidth validate={required()} />
				<TextInput source="code" fullWidth validate={required()} />
				<RichTextInput
					{...props}
					source="description"
					multline
					fullWidth
					validate={required()}
				/>
				<RichTextInput
					{...props}
					source="rules"
					multline
					fullWidth
					validate={required()}
				/>
				<DateTimeInput source="start_at" validate={[required()]} />
				<DateTimeInput source="end_at" validate={[required()]} />
				<BooleanInput source="all_campaigns" fullWidth />
			</FormCard>
		</CardForm>
	);
};
export default Form;
