import { CardForm } from "components/forms/cards/CardForm";
import { FormCard } from "components/forms/cards/FormCard";
import TextInput from "components/inputs/TextInput";
import { LinkIcon } from "icons";
import React from "react";
import { required, useRedirect, useTranslate } from "react-admin";
import { makeRedirect } from "../components/fields/ListField";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import DomainInput from "./inputs/DomainInput";
import LandingInput from "./inputs/LandingInput";

const Form = (props) => {
	const { record } = props;
	const redirect = useRedirect();
	const translate = useTranslate();
	return (
		<CardForm
			{...props}
			toolbar={
				<ReferenceToolbar
					backRedirect="/campaigns"
					backReference="campaigns"
					backReferenceTarget="campaign_id"
					backTab={0}
					show
				/>
			}
			redirect={() => redirect(makeRedirect({ ...props, tab: 0 }))}
			variant="outlined"
		>
			<FormCard
				size={4}
				offset={4}
				label={translate(`resources.${props.resource}.name`, 1)}
				icon={<LinkIcon />}
				initialValues={{ ...record }}
			>
				<LandingInput source="landing_id" validate={required()} fullWidth />
				<DomainInput source="domain_id" validate={required()} fullWidth />
				<TextInput source="subid" fullWidth />
				<TextInput source="facebook_pixel" maxLength={20} fullWidth />
				<TextInput source="google_ads" maxLength={20} fullWidth />
				<TextInput source="hotjar" maxLength={20} fullWidth />
			</FormCard>
		</CardForm>
	);
};
export default Form;
