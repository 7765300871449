import React, { useMemo } from "react";
import { required, useTranslate } from "ra-core";

import AutocompleteInput from "components/inputs/AutocompleteInput";
import { ReferenceInput } from "ra-ui-materialui";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const UserInput = (props) => {
  const translate = useTranslate();
  const formState = useFormState();
  // Se l'advertiser seleziona una campagna devo mostrare i soli utenti attivi per quella campagna.
  const campaignId = useMemo(() => get(formState.values, "campaign_id", null), [
    formState.values,
  ]);
  const type = useMemo(() => get(formState.values, "type", "none"), [
    formState.values,
  ]);
  const validate = type === "referral" ? required() : undefined;

  return (
    <ReferenceInput
      {...props}
      validate={validate}
      reference="users"
      sort={{ field: "fullname", order: "ASC" }}
      filter={{ campaign_id: campaignId }}
    >
      <AutocompleteInput
        optionText="name"
        resettable
        emptyText={translate("resources.commissions.fields.users.all")}
      />
    </ReferenceInput>
  );
};
export default UserInput;
