import { FormHelperText, Typography, useMediaQuery } from "@material-ui/core";
import { Labeled, TextInput, useNotify, useTranslate } from "react-admin";

import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { SIGNUP_URL } from "../../config";
import { get } from "lodash";
import { useFormState } from "react-final-form";

export const CopyableCodeField = ({ content, label, referralCode }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <div
      style={{
        display: "block",
        clear: "both",
        cursor: "copy",
        overflow: "hidden",
      }}
    >
      <Labeled label={translate(label)}>
        <CopyToClipboard
          text={content}
          onCopy={() => notify(translate("ra.action.content_copied"))}
        >
          <Typography
            variant="body1"
            color="primary"
            style={{ fontFamily: "Consolas, Courier New" }}
          >
            {sm ? referralCode : content}
          </Typography>
        </CopyToClipboard>
      </Labeled>
      <FormHelperText>{translate("ra.action.click_to_copy")}</FormHelperText>
      <br />
    </div>
  );
};

const ReferralCodeInput = ({ source, readOnly = false, ...props }) => {
  const formState = useFormState();
  const referralCode = get(formState.values, source, null);
  if (!referralCode) {
    return null;
  }
  return (
    <React.Fragment>
      {!readOnly && <TextInput source={source} {...props} />}
      {referralCode && (
        <CopyableCodeField
          label="resources.users.fields.referral_code.help"
          content={`http:${SIGNUP_URL}${referralCode}`}
          referralCode={referralCode}
        />
      )}
    </React.Fragment>
  );
};

export default ReferralCodeInput;
