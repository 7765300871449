import useValidatableSave from "components/forms/useValidatableSave";
import { DomainIcon } from "icons";
import React from "react";
import { required, TextInput, usePermissions, useTranslate } from "react-admin";
import { url } from "utils/validation";
import { CardForm } from "../components/forms/cards/CardForm";
import { FormCard } from "../components/forms/cards/FormCard";
import StatusInput from "./inputs/StatusInput";
import UserInput from "./inputs/UserInput";

const Form = (props) => {
	const { loaded, permissions } = usePermissions();
	const save = useValidatableSave({ ...props, redirect: undefined });
	const isAdmin = loaded && permissions(["admin"]);
	const translate = useTranslate();
	return (
		<CardForm
			{...props}
			save={save}
			initialValues={{ status: "pending" }}
			variant="outlined"
		>
			<FormCard
				size={4}
				offset={4}
				label={translate("resources.domains.name", 1)}
				icon={<DomainIcon />}
			>
				{isAdmin && (
					<UserInput
						source="user_id"
						emptyText="ra.users.all_users"
						fullWidth
						resettable
					/>
				)}
				{isAdmin && (
					<StatusInput source="status" validate={required()} fullWidth />
				)}
				<TextInput source="name" validate={[required(), url]} fullWidth />
			</FormCard>
		</CardForm>
	);
};
export default Form;
