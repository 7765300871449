export default [
  {
    id: "approved",
    name: "resources.users-campaigns.status.approved",
  },
  {
    id: "pending",
    name: "resources.users-campaigns.status.pending",
  },
  {
    id: "rejected",
    name: "resources.users-campaigns.status.rejected",
  },
];
