import { useListStyles, useSelector } from "components/lists/useStyles";

import { DATE_RANGE_EXPR } from "components/inputs/DateRangeInput";
import Datagrid from "./Datagrid";
import Filters from "./Filters";
import { List as RaList } from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core";
import perPage from "utils/perPage";

const useStyles = makeStyles((theme) => ({
  content: {
    background: "transparent",
    boxShadow: "none",
  },
}));
const List = (props) => {
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useListStyles({ sidebarOpen });
  const customClasses = useStyles();
  return (
    <RaList
      {...props}
      filters={<Filters />}
      filterDefaultValues={{
        view: "today",
        ...DATE_RANGE_EXPR.today(),
      }}
      classes={{ ...classes, ...customClasses }}
      perPage={perPage(25, 10)}
      pagination={false}
      exporter={false}
    >
      <Datagrid />
    </RaList>
  );
};
export default List;
