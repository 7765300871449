import { SimpleForm, required, useRedirect } from "react-admin";

import ContentInput from "./inputs/ContentInput";
import PostbackEventInput from "./inputs/PostbackEventInput";
import React from "react";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import TypeInput from "./inputs/TypeInput";
import { makeRedirect } from "../components/fields/ListField";

const Form = (props) => {
  const { record } = props;
  const redirect = useRedirect();
  return (
    <SimpleForm
      {...props}
      initialValues={{ ...record, content: "" }}
      toolbar={
        <ReferenceToolbar
          backRedirect="/campaigns"
          backReference="campaigns"
          backReferenceTarget="campaign_id"
          backTab={3}
          show
        />
      }
      redirect={() => redirect(makeRedirect({ ...props, tab: 3 }))}
    >
      <PostbackEventInput source="postback_event_id" validate={required()} />
      <TypeInput source="type" validate={required()} />
      <ContentInput
        source="content"
        tokensProviderUrl="users-campaigns-postbacks/fields"
        fullWidth
      />
    </SimpleForm>
  );
};
export default Form;
