import React from "react";
import {
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import RaInput from "../components/inputs/RaInput";
import {CardForm} from "../components/forms/cards/CardForm";
import {FormCard} from "../components/forms/cards/FormCard";
import {LanguageMessagesIcon} from "../icons";
import {useTranslate} from "ra-core";

const Form = ({create, ...props}) => {
  const translate = useTranslate();
  return (
    <CardForm {...props} redirect="list" variant="outlined">
      <FormCard size={4} offset={4}
                label={translate('resources.language-messages.name', 1)}
                icon={<LanguageMessagesIcon/>}
      >
        {create && (
          <ReferenceInput source="resource" reference="resources" allowEmpty fullWidth>
            <SelectInput helperText="resources.language-messages.helpers.resource"/>
          </ReferenceInput>
        )}
        <ReferenceInput
          source="language_id"
          reference="languages"
          validate={required()}
          fullWidth
        >
          <SelectInput/>
        </ReferenceInput>
        <FormDataConsumer>
          {({formData, ...rest}) => {
            let {resource} = formData;
            if (!resource) {
              return (
                <React.Fragment>
                  <RaInput>
                    <TextInput
                      {...rest}
                      source="code"
                      validate={required()}
                      fullWidth
                      style={{width: "100%"}}
                    />
                  </RaInput>
                  <RaInput>
                    <TextInput
                      {...rest}
                      source="text"
                      multiline
                      validate={required()}
                      fullWidth
                      style={{width: "100%"}}
                      rows={4}
                    />
                  </RaInput>
                </React.Fragment>
              );
            }
          }}
        </FormDataConsumer>
      </FormCard>
    </CardForm>
  );
};
export default Form;
