import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SelectInput,
  usePermissions,
} from "react-admin";
import React, { useMemo } from "react";

import statuses from "./statuses";
import { useMediaQuery } from "@material-ui/core";

const Filters = (props) => {
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { loaded, permissions } = usePermissions();
  const isAdmin = useMemo(
    () => loaded && permissions && permissions(["admin"]),
    [loaded, permissions]
  );
  return (
    <Filter {...props} variant="outlined">
      <ReferenceInput
        source="campaign_id"
        reference="campaigns"
        alwaysOn
        fullWidth
        style={sm ? { marginTop: 32 } : {marginTop: 0}}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {isAdmin && (
        <ReferenceInput
          source="user_id"
          reference="users"
          alwaysOn={!sm}
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      )}
      <SelectInput
        source="status"
        choices={statuses}
        optionText="name"
        alwaysOn
        resettable
        fullWidth
        allowEmpty
        emptyText="ra.action.all"
      />
    </Filter>
  );
};
export default Filters;
