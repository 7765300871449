import { ChipField } from "ra-ui-materialui";
import React from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
}));

const RolesField = ({ source, record }) => {
  const classes = useStyles();
  const roles = get(record, source, []);
  return roles.map((role) => (
    <ChipField
      key={role.id}
      className={classes.chip}
      record={role}
      source="name"
    />
  ));
};
export default RolesField;
