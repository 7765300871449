import { Card, CardContent } from "@material-ui/core";

import React from "react";

const NotImplemented = ({ type }) => (
  <Card className="mb-4">
    <CardContent className="p-3">
      <h5 className="card-title font-weight-bold font-size-lg">
        Type {type} not implemented!
      </h5>
      <p className="card-text">
        Type of dashlet specified is not yet implemented for UX.
      </p>
    </CardContent>
  </Card>
);
export default NotImplemented;
