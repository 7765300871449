import { CampaignIcon } from "icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import Show from "./Show";

export default {
	icon: CampaignIcon,
	options: {
		group: "campaigns",
		roles: ["admin", "advertiser", "publisher"],
	},
	list: List,
	edit: Edit,
	create: Create,
	show: Show,
};
