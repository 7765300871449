import { Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";

const CommissionField = ({
	commission,
	admin,
	variant = "body1",
	forceCurrency,
}) => {
	const event = get(commission, "event");
	const valueType = forceCurrency ? "money" : get(event, "value_type");
	const amount = get(commission, "amount");

	switch (valueType) {
		case "money":
			const formatterMoney = (props, lang = "it-IT") =>
				Intl.NumberFormat(lang, props);
			return (
				<Typography variant={variant}>
					{formatterMoney({
						style: "currency",
						currency: "EUR",
					}).format(amount)}{" "}
					{get(event, "name")}
					{admin && " - "}
					{admin && get(commission, "user.nice_name")}
				</Typography>
			);
		case "percent":
			const formatterPercent = (props, lang = "it-IT") =>
				Intl.NumberFormat(lang, props);
			return (
				<Typography variant={variant}>
					{formatterPercent({
						style: "percent",
						minimumFractionDigits: 1,
						maximumFractionDigits: 2,
					}).format(amount / 100)}{" "}
					{get(event, "name")}
					{admin && " - "}
					{admin && get(commission, "user.nice_name")}
				</Typography>
			);
		default:
			return (
				<Typography variant={variant}>
					{amount} ({get(event, "name")})
				</Typography>
			);
	}
};
const CommissionsField = ({
	record,
	admin,
	basePath,
	resource,
	forceCurrency = false,
	...props
}) => {
	const commissions = get(record, "commissions", []);
	// Provo a fare un ragionamento intelligente:
	// Se admin = false vuol dire che chi sta guardando l'elenco degli ordini
	// è un utente che ha ricevuto revenue di qualsiasi tipo.
	return commissions.map((commission) => (
		<CommissionField
			key={commission.id}
			commission={commission}
			admin={admin}
			variant={commissions.length > 1 ? "body2" : "body1"}
			forceCurrency={forceCurrency}
		/>
	));
};

export default CommissionsField;
