import { Divider, makeStyles, Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { DateField, useTranslate } from "react-admin";
import RewardsField from "./RewardsField";

const useStyles = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	mb3: {
		marginBottom: theme.spacing(3),
	},
}));

const RewardsShowField = ({ record, ...props }) => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<div>
			<Typography variant="h4" style={{ fontWeight: "bold" }}>
				{get(record, "title")}
			</Typography>
			<Typography
				variant="subtitle2"
				color="textSecondary"
				className={classes.mb3}
			>
				<DateField
					source="start_at"
					record={record}
					options={{
						weekday: "long",
						year: "numeric",
						month: "long",
						day: "numeric",
					}}
				/>
				{" - "}
				<DateField
					source="end_at"
					record={record}
					options={{
						weekday: "long",
						year: "numeric",
						month: "long",
						day: "numeric",
					}}
				/>
			</Typography>
			<Typography
				variant="body1"
				dangerouslySetInnerHTML={{
					__html: get(record, "description"),
				}}
			/>
			<Divider className={classes.divider} />
			<Typography variant="h5" style={{ fontWeight: "bold" }}>
				{translate("resources.contests.fields.rewards")}:
			</Typography>
			<RewardsField record={record} />
		</div>
	);
};

export default RewardsShowField;
