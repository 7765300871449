import List from "./List";
import { StatIcon } from "../icons";

export default {
  options: {
    group: "reporting",
    roles: ["admin", "publisher", "advertiser"],
  },
  icon: StatIcon,
  list: List,
};
