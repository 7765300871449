import { Divider, Typography, makeStyles } from '@material-ui/core';

import React from 'react';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const ShowField = ({ record, ...props }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h5" style={{ fontWeight: 'bold' }}>
        {get(record, 'name')}
      </Typography>
      <Divider className={classes.divider} />
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: get(record, 'content')
        }}
      />
    </div>
  );
};

export default ShowField;
