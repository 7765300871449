import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const ItemsField = ({ record, source, alwaysList = false }) => {
  const translate = useTranslate();
  const items = get(record, source, []);
  if (items.length === 1 && !alwaysList) {
    return (
      <Typography variant="body2">
        {translate("resources.commissions.fields.one_item", {
          amount: items[0].amount,
          sign: "",
          event: get(record, "event.name"),
        })}
      </Typography>
    );
  } else {
    return (
      <>
        {items.map((item, index) => (
          <Typography key={index} variant="body2">
            {translate("resources.commissions.fields.multi_item", {
              amount: item.amount,
              event: index < items.length - 1 ? get(record, "event.name") : "",
              sign: "",
              value_limit:
                index < items.length - 1
                  ? item.value_limit
                  : translate(
                      "resources.commissions.fields.multi_item.infinite"
                    ),
            })}
          </Typography>
        ))}
      </>
    );
  }
};
export default ItemsField;
