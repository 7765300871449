import { required, useRedirect, useTranslate } from "react-admin";

import { CampaignMediaIcon } from "icons";
import { CardForm } from "components/forms/cards/CardForm";
import CategoryInput from "./inputs/CategoryInput";
import FileInput from "./inputs/FileInput";
import { FormCard } from "components/forms/cards/FormCard";
import React from "react";
import ReferenceToolbar from "../components/forms/ReferenceToolbar";
import { makeRedirect } from "../components/fields/ListField";
import useQueryString from "utils/useQueryString";

const Form = (props) => {
  const { record } = props;
  const redirect = useRedirect();
  const translate = useTranslate();
  const qs = useQueryString();
  const r = parseInt(qs.get("r"), 10);

  return (
    <CardForm
      {...props}
      variant="outlined"
      toolbar={
        r === 1 ? (
          <ReferenceToolbar
            backRedirect="/campaigns"
            backReference="campaigns"
            backReferenceTarget="campaign_id"
            backTab={0}
          />
        ) : (
          undefined
        )
      }
      redirect={
        r === 1 ? () => redirect(makeRedirect({ ...props, tab: 0 })) : undefined
      }
    >
      <FormCard
        size={4}
        offset={4}
        label={translate(`resources.${props.resource}.name`, 1)}
        icon={<CampaignMediaIcon />}
        {...props}
        initialValues={{ ...record }}
      >
        <CategoryInput
          source="campaign_media_category_id"
          validate={required()}
          fullWidth
        />
        <FileInput source="media" validate={required()} fullWidth />
      </FormCard>
    </CardForm>
  );
};
export default Form;
