import { AppBar, Box, Hidden, IconButton, Tooltip } from "@material-ui/core";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import clsx from "clsx";
import HelpToggle from "components/HelpToggle";
import { useTranslate } from "ra-core";
import { LoadingIndicator } from "ra-ui-materialui";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import projectLogo from "../../assets/images/logo512.png";
import {
	setSidebarToggle,
	setSidebarToggleMobile
} from "../../reducers/ThemeOptions";
import HeaderDots from "./HeaderDots";
import HeaderLogo from "./HeaderLogo";
import HeaderUserbox from "./HeaderUserbox";

const Header = (props) => {
	const toggleSidebar = () => {
		setSidebarToggle(!sidebarToggle);
	};
	const toggleSidebarMobile = () => {
		setSidebarToggleMobile(!sidebarToggleMobile);
	};
	const {
		headerShadow,
		headerFixed,
		setSidebarToggle,
		sidebarToggle,
		sidebarToggleMobile,
		setSidebarToggleMobile,
	} = props;

	const translate = useTranslate();

	return (
		<Fragment>
			<AppBar
				color="secondary"
				className={clsx("app-header", {
					"app-header-collapsed-sidebar": props.isCollapsedLayout,
				})}
				position={headerFixed ? "fixed" : "absolute"}
				elevation={headerShadow ? 11 : 3}
			>
				{!props.isCollapsedLayout && <HeaderLogo />}

				<Box className="app-header-toolbar">
					<Hidden lgUp>
						<Box className="app-logo-wrapper" title="WooPrice">
							<Link to="/" className="app-logo-link">
								<IconButton
									color="primary"
									size="medium"
									className="app-logo-btn"
								>
									<img
										className="app-logo-img"
										alt="WooPrice"
										src={projectLogo}
									/>
								</IconButton>
							</Link>
							<Hidden smDown>
								<Box className="app-logo-text">{translate("app.name")}</Box>
							</Hidden>
						</Box>
					</Hidden>
					<Hidden mdDown>
						<Box className="d-flex align-items-center">
							{!props.isCollapsedLayout && (
								<Box
									className={clsx("btn-toggle-collapse", {
										"btn-toggle-collapse-closed": sidebarToggle,
									})}
								>
									<Tooltip title="Toggle Sidebar" placement="right">
										<IconButton
											color="inherit"
											onClick={toggleSidebar}
											size="medium"
											className="btn-inverse"
										>
											{sidebarToggle ? (
												<MenuRoundedIcon />
											) : (
												<MenuOpenRoundedIcon />
											)}
										</IconButton>
									</Tooltip>
								</Box>
							)}
						</Box>
					</Hidden>
					<Box className="d-flex align-items-center">
						<HelpToggle />
						<LoadingIndicator />
						<HeaderDots />
						<HeaderUserbox />

						<Box className="toggle-sidebar-btn-mobile">
							<Tooltip title="Toggle Sidebar" placement="right">
								<IconButton
									color="inherit"
									onClick={toggleSidebarMobile}
									size="medium"
								>
									{sidebarToggleMobile ? (
										<MenuOpenRoundedIcon />
									) : (
										<MenuRoundedIcon />
									)}
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			</AppBar>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	headerShadow: state.ThemeOptions.headerShadow,
	headerFixed: state.ThemeOptions.headerFixed,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
	sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
	setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
	setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
