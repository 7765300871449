import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslate } from "react-admin";
import { StatIcon } from "../icons";

const useStyles = makeStyles(
	(theme) => ({
		message: {
			textAlign: "center",
			opacity: theme.palette.type === "light" ? 0.5 : 0.8,
			margin: "0 1em",
			color:
				theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
		},
		icon: {
			width: "9em",
			height: "9em",
		},
		toolbar: {
			textAlign: "center",
			marginTop: "2em",
		},
		paragraph: {
			maxWidth: 600,
			margin: "0 auto",
		},
		divider: {
			width: "20%",
			margin: "1em auto",
		},
	}),
	{ name: "RaEmpty" }
);

const Empty = ({ basePath, resource }) => {
	const translate = useTranslate();
	const classes = useStyles();
	return (
		<React.Fragment>
			<div className={classes.message}>
				<StatIcon className={classes.icon} />
				<Typography variant="h5" paragraph>
					{translate("resources.contest-stats.empty")}
				</Typography>
			</div>
			<div className={classes.toolbar}></div>
		</React.Fragment>
	);
};

export default Empty;
