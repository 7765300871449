import { get } from "lodash";
import * as React from "react";
import { cloneElement } from "react";
import { sanitizeListRestProps, TopToolbar } from "react-admin";
import CreateButton from "../components/buttons/CreateButton";

const Actions = ({ isAdmin, ...props }) => {
	const { className, filters, resource, basePath, filterValues, ...rest } =
		props;

	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			{cloneElement(filters, { context: "button" })}
			<CreateButton
				resource={resource}
				basePath={basePath}
				baseRecord={{ contest_id: get(filterValues, "contest_id") }}
			/>
		</TopToolbar>
	);
};

export default Actions;
