import * as React from "react";

import { CampaignMediaIcon, LandingIcon, LinkIcon } from "../icons";
import { Show as RaShow, SimpleShowLayout } from "react-admin";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { FormGrid } from "../components/forms/cards/FormGrid";
import { FormGridColumn } from "../components/forms/cards/FormGridColumn";
import GeneralField from "./fields/GeneralField";
import LandingsField from "./fields/LandingsField";
import LinksField from "./fields/LinksField";
import MediaField from "./fields/MediaField";
import { ShowCard } from "../components/show/ShowCard";
import Title from "../components/Title";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useFormStyles } from "../components/forms/useStyles";

const Show = (props) => {
  const classes = useFormStyles();
  const theme = useTheme();
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <RaShow
      title={<Title source="name" />}
      actions={null}
      {...props}
      classes={classes}
      component="div"
      style={{ margin: !sm ? -theme.spacing(2) : 0 }}
    >
      <SimpleShowLayout>
        <FormGrid container spacing={2} grid={true}>
          <FormGridColumn size={4}>
            <ShowCard
              classes={classes}
              label="resources.campaigns.tabs.general"
              icon={<VisibilityIcon />}
            >
              <GeneralField />
            </ShowCard>
          </FormGridColumn>
          <FormGridColumn size={8}>
            <ShowCard
              classes={classes}
              label="resources.campaigns.tabs.landings"
              icon={<LandingIcon />}
            >
              <LandingsField source="landings" show fullWidth />
            </ShowCard>
            <ShowCard
              classes={classes}
              label="resources.campaigns.tabs.media"
              icon={<CampaignMediaIcon />}
            >
              <MediaField
                label="resources.campaigns.tabs.media"
                source="media"
                fullWidth
                canCreate={false}
                canRemove={false}
                useLabel={false}
              />
            </ShowCard>
            <ShowCard
              classes={classes}
              label="resources.campaigns.tabs.links"
              icon={<LinkIcon />}
            >
              <LinksField
                label="resources.campaigns.tabs.links"
                source="links"
                fullWidth
                canCreate={true}
                useLabel={false}
              />
            </ShowCard>
          </FormGridColumn>
        </FormGrid>
      </SimpleShowLayout>

      {/*			- TODO: inserire delle informazioni per prelevare le API della*/}
    </RaShow>
  );
};

export default Show;
