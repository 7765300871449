import Create from "./Create";
import { ENVIRONMENT } from "../config";
import Edit from "./Edit";
import { LanguageMessagesIcon } from "icons";
import List from "./List";

let resource = {
  options: {
    group: "admin",
    roles: ["admin"],
  },
  icon: LanguageMessagesIcon,
  list: List,
  edit: Edit,
  create: Create,
};

if (ENVIRONMENT !== "DEVELOPER") {
  resource = {};
}

export default resource;
