import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";
import { useFormStyles } from "../components/forms/useStyles";

const Edit = (props) => {
  const classes = useFormStyles();
  return (
    <RaEdit {...props} classes={classes} component="div">
      <Form />
    </RaEdit>
  );
};
export default Edit;
