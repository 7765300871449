import { useGetIdentity, useTranslate } from "react-admin";

const WelcomeMessage = () => {
  const { loading, identity } = useGetIdentity();
  const translate = useTranslate();
  if (loading) {
    return null;
  }
  return translate("ra.dashboard.welcome", identity);
};

export default WelcomeMessage;
