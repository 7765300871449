import React, { useMemo } from "react";

import { ReferenceInput } from "ra-ui-materialui";
import { SelectInput } from "react-admin";
import { get } from "lodash";

const LandingInput = (props) => {
  const campaignId = useMemo(
    () => get(props.filterValues, "campaign_id", null),
    [props.filterValues]
  );
  return (
    <ReferenceInput
      {...props}
      filter={{ campaign_id: campaignId }}
      reference="landings"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default LandingInput;
