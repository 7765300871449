import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const CampaignField = ({ record, source, basePath, ...props }) => {
  const translate = useTranslate();
  return (
    <Typography {...props}>
      {get(
        record,
        source,
        translate("resources.commissions.fields.campaigns.all")
      )}
    </Typography>
  );
};
export default CampaignField;
