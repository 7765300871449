import { Grid, makeStyles } from "@material-ui/core";

import Dashlet from "./Dashlet";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));
const DatagridRow = ({ row }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      {row.dashlets.map((dashlet) => (
        <Dashlet key={dashlet.id} dashlet={dashlet} />
      ))}
    </Grid>
  );
};

export default DatagridRow;
