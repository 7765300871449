import React from "react";
import logo from "../../assets/images/logo.png";
const { useTranslate } = require("ra-core");
const BasicTitle = ({ message }) => {
  const translate = useTranslate();
  return (
    <span className="w-100 text-left text-md-center pb-4">
      <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
        <img alt="..." className="w-50 mx-auto d-block img-fluid" src={logo} />
      </h1>
      <p className="font-size-lg text-xl-left text-center mb-0 text-black-50">
        {translate(message)}
      </p>
    </span>
  );
};
export default BasicTitle;
