import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";
import Title from "../components/Title";
import { useFormStyles } from "../components/forms/useStyles";

const Edit = (props) => {
  const classes = useFormStyles();
  return (
    <RaEdit
      {...props}
      classes={classes}
      title={<Title source="fullname" />}
      component="div"
    >
      <Form />
    </RaEdit>
  );
};
export default Edit;
