import {
  Card,
  CardContent,
  Grid,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";

import React from "react";

const Text = ({ header, value, valueStyle }) => (
  <div className="text-center">
    <span className="text-black-50 d-block">{header}</span>
    <b className="font-size-xxl" style={valueStyle}>
      {value}
    </b>
  </div>
);

const LinearProgress = ({ header, value }) => (
  <div className="w-100 mt-3 mt-md-0">
    <div className="mb-1">
      <span className="text-black-50 pr-1">{header}</span>
      <span className="text-danger">{value}</span>
    </div>
    <MuiLinearProgress variant="determinate" value={value} />
  </div>
);

const Components = {
  Text,
  LinearProgress,
};

const IndicatorClusterValue = (indicatorClusterValue) => {
  const Component = Components[indicatorClusterValue.type];
  return Component ? <Component {...indicatorClusterValue} /> : null;
};

const IndicatorCluster = ({
  data: { title, top_left_header_caption, values },
}) => {
  const itemSize = 12 / values.length;
  return (
    <Card className="card-box mb-4">
      <div className="card-header">
        <div className="font-size-lg d-block text-truncate">
          <span className="text-black-50 pr-2">{top_left_header_caption}</span>{" "}
          {title}
        </div>
      </div>
      <CardContent className="p-3">
        <Grid container spacing={4}>
          {values.map((data, index) => (
            <Grid item xs={itemSize} key={index}>
              <IndicatorClusterValue {...data} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default IndicatorCluster;
