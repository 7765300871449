import AutocompleteInput from "components/inputs/AutocompleteInput";
import React from "react";
import { ReferenceInput } from "ra-ui-materialui";

const UserInput = (props) => (
  <ReferenceInput
    {...props}
    reference="users"
    filter={{ role: "advertiser" }}
    sort={{
      field: "username",
      order: "ASC",
    }}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);
export default UserInput;
