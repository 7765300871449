import { RewardIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
	options: {
		group: "gamification",
		roles: ["admin"],
	},
	icon: RewardIcon,
	list: List,
	edit: Edit,
	create: Create,
};
