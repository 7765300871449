import { green } from "@material-ui/core/colors";
import { get } from "lodash";
import React, { useEffect } from "react";
import {
	LinearProgress,
	List as RaList,
	NumberField,
	TextField,
	useGetList,
	useListContext,
	useRedirect,
} from "react-admin";
import perPage from "utils/perPage";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import Actions from "./Actions";
import Empty from "./Empty";
import PointsField from "./fields/PointsField";
import RewardField from "./fields/RewardField";
import Filters from "./Filters";

const Datagrid = ({ defaultContestId, ...props }) => {
	const { filterValues, data, loading } = useListContext();
	const showContestStats = get(filterValues, "scc") === 1;
	const redirect = useRedirect();
	useEffect(() => {
		if (!showContestStats && !loading) {
			redirect("show", "/contests", defaultContestId);
		}
	}, [showContestStats, defaultContestId, redirect, loading]);
	if (Object.keys(data).length === 0 && !loading) {
		return <Empty />;
	}
	return (
		<ResponsiveList
			{...props}
			rowStyle={(r) => (r.is_me ? { backgroundColor: green[50] } : {})}
			mobileHeader={
				<React.Fragment>
					<TextField
						source="position"
						style={{ fontWeight: "bold" }}
						variant="h4"
					/>
					<TextField
						source="user.name"
						style={{ fontWeight: "bold" }}
						label="resources.contest-stats.fields.user_id"
					/>
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<RewardField
						sortable={false}
						source="reward_id"
						label="resources.contest-stats.fields.reward_id"
					/>
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<NumberField source="points" sortable={false} variant="h4" />
				</React.Fragment>
			}
		>
			<TextField
				source="position"
				style={{ fontWeight: "bold" }}
				sortable={false}
				variant="h4"
			/>
			<TextField
				source="user.name"
				style={{ fontWeight: "bold" }}
				label="resources.contest-stats.fields.user_id"
				sortable={false}
				variant="subtitle1"
			/>
			<RewardField
				sortable={false}
				label="resources.contest-stats.fields.reward_id"
			/>
			<PointsField source="points" sortable={false} variant="h4" />
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	const { data, loading } = useGetList(
		"contests",
		{ page: 1, perPage: 1 },
		{ field: "created", order: "DESC" },
		{ active: true }
	);
	const contestId = get(data, `${get(Object.keys(data), 0)}.id`);
	if (loading) {
		return <LinearProgress />;
	}
	return (
		<RaList
			{...props}
			classes={classes}
			filters={<Filters />}
			actions={<Actions />}
			perPage={perPage()}
			exporter={false}
			bulkActionButtons={false}
			pagination={false}
			filterDefaultValues={{
				contest_id: contestId,
			}}
		>
			<Datagrid defaultContestId={contestId} />
		</RaList>
	);
};
export default List;
