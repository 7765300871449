import { useTranslate, useUpdateMany } from "react-admin";

import React from "react";
import classnames from "classnames";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: "pointer",
    "& p": {
      marginRight: `${theme.spacing(1)}px !important`,
    },
  },
}));
const NotificationMessage = ({ message, onClick }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [updateMany] = useUpdateMany("notifications", [get(message, "id")], {
    readed: moment().format(),
  });
  const shouldUpdate = () => get(message, "readed") === null;
  const openLink = () =>
    (document.location.href = `#/${get(message, "resource") ??
      "notifications"}`);
  const handleClick = () =>
    !onClick() && (!shouldUpdate() || !updateMany()) && !openLink();
  return (
    <div
      key={message.id}
      className={classnames("timeline-item", classes.item)}
      onClick={handleClick}
    >
      <div className="timeline-item-offset">
        {moment(get(message, "created")).format("HH:mm")}
      </div>
      <div className="timeline-item--content">
        <div className="timeline-item--icon"></div>
        <h4 className="timeline-item--label mb-2 font-weight-bold">
          {get(message, "title")}
        </h4>
        <p
          dangerouslySetInnerHTML={{
            __html: get(message, "content"),
          }}
        />
        <small>
          {moment(get(message, "created")).format(
            translate("app.date_format.short")
          )}
        </small>
      </div>
    </div>
  );
};
export default NotificationMessage;
