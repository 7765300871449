import { get } from "lodash";
import React from "react";
import {
	ChipField,
	List as RaList,
	TextField,
	useTranslate,
} from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/perPage";
import Actions from "./Actions";
import Filters from "./Filters";

const Datagrid = (props) => {
	const hasCampaignFilter = !!get(props, "filterValues.campaign_id");
	const translate = useTranslate();
	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField
						source="user.name"
						variant="subtitle1"
						style={{ fontWeight: "bold" }}
						label={translate("resources.users.name", 1)}
					/>
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					{!hasCampaignFilter && (
						<TextField
							source="campaign.name"
							label={translate("resources.campaigns.name", 1)}
							style={{ fontWeight: "bold" }}
						/>
					)}
				</React.Fragment>
			}
		>
			<TextField source="id" label="ID" style={{ fontWeight: "bold" }} />
			{!hasCampaignFilter && (
				<ChipField
					style={{ marginLeft: 0 }}
					source="campaign.name"
					label={translate("resources.campaigns.name", 1)}
				/>
			)}
			<TextField
				source="user.name"
				label={translate("resources.users.name", 1)}
			/>
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	return (
		<RaList
			{...props}
			classes={classes}
			actions={<Actions />}
			filters={<Filters />}
			perPage={perPage()}
		>
			<Datagrid {...props} />
		</RaList>
	);
};
export default List;
