import React, { useMemo } from "react";

import { Chip } from "@material-ui/core";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const StatusField = ({ source, record }) => {
  const translate = useTranslate();
  const status = get(record, source);
  const color = useMemo(
    () =>
      status === "approved"
        ? "primary"
        : status === "rejected"
        ? "secondary"
        : "default",
    [status]
  );
  return (
    <Chip
      color={color}
      label={translate(`resources.user-profile-approvals.status.${status}`)}
    />
  );
};

export default StatusField;
