import { ReferenceInput, SelectInput } from "ra-ui-materialui";

import React from "react";

const PostbackEventInput = ({ ...props }) => (
  <ReferenceInput
    {...props}
    reference="postback-events"
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default PostbackEventInput;
