import { useMediaQuery } from "@material-ui/core";
import { useFiltersStyles } from "components/lists/useStyles";
import React from "react";
import {
	AutocompleteInput,
	Filter as RaFilter,
	ReferenceInput,
} from "react-admin";
import SearchInput from "../components/inputs/SearchInput";
import { usersConfig } from "../users";

const Filters = (props) => {
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const classes = useFiltersStyles();
	return (
		<RaFilter {...props} classes={classes} variant="outlined">
			<SearchInput source="q" alwaysOn fullWidth />
			<ReferenceInput
				source="campaign_id"
				reference="campaigns"
				alwaysOn={!sm}
				fullWidth
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				source="user_id"
				reference="users"
				{...usersConfig.reference}
				fullWidth
				alwaysOn={!sm}
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
		</RaFilter>
	);
};
export default Filters;
