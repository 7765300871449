import { UserIcon } from "icons";
import config from "./config";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export const usersConfig = config;

export default {
	options: {
		group: "admin",
		roles: ["admin"],
	},
	icon: UserIcon,
	list: List,
	edit: Edit,
	create: Create,
};
