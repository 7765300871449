import { makeStyles } from "@material-ui/core";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { ImageField as RaImageField } from "react-admin";
import placeholder from "../../assets/images/placeholder.png";

const useStyles = makeStyles(
	(theme) => ({
		img: (size) => ({
			"& img": {
				objectFit: "cover",
				...size,
			},
		}),
	}),
	{
		name: "ImageField",
	}
);

const ImageField = ({ size, source, record, ...props }) => {
	const classes = useStyles(size);
	const image = get(record, source);
	return (
		<RaImageField
			{...props}
			source={image ? source : "placeholder"}
			record={image ? record : { ...record, placeholder }}
			className={classes.img}
		/>
	);
};

ImageField.propTypes = {
	size: PropTypes.object,
};

ImageField.defaultProps = {
	size: { width: 150, height: 80 },
};

export default ImageField;
