import AutocompleteInput from "components/inputs/AutocompleteInput";
import React from "react";
import { ReferenceInput } from "ra-ui-materialui";

const UserInput = (props) => (
  <ReferenceInput
    {...props}
    reference="users"
    sort={{ field: "fullname", order: "ASC" }}
  >
    <AutocompleteInput optionText="name" resettable />
  </ReferenceInput>
);

export default UserInput;
