import { Button, ButtonGroup } from "@material-ui/core";
import { useMutation, useNotify, useRefresh, useTranslate } from "react-admin";

import React from "react";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { get } from "lodash";

const SetStatusButton = ({
  record,
  label,
  status,
  Icon,
  onSuccess,
  ...props
}) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const [handleApprove, { loading }] = useMutation(
    {
      type: "update",
      resource: "user-parent-approvals",
      payload: {
        id: record.id,
        data: {
          id: record.id,
          user_id: record.user_id,
          status,
        },
      },
    },
    {
      onSuccess: ({ data }) => {
        notify(`resources.user-parent-approvals.messages.${status}`);
        refresh();
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => notify("resources.user-parent-approvals.messages.error"),
    }
  );
  if (get(record, "status") === status) {
    return null;
  }
  return (
    <Button {...props} onClick={handleApprove} disabled={loading}>
      <Icon />
      {label !== null && translate(label)}
    </Button>
  );
};

const ApprovalButton = ({
  record,
  forUser = false,
  onSuccess,
  onViewDetailsClick,
}) => {
  const handleClick = () => onViewDetailsClick(record);
  const isApproved = get(record, "status") === "approved";
  if (isApproved) {
    return null;
  }
  return (
    <ButtonGroup color="primary" variant="text" size="small">
      <SetStatusButton
        record={record}
        status="approved"
        label={
          forUser ? "resources.user-parent-approvals.buttons.accept" : null
        }
        Icon={ThumbUpAltIcon}
        onSuccess={onSuccess}
      />
      {!forUser && (
        <Button onClick={handleClick}>
          <VisibilityIcon />
        </Button>
      )}
      <SetStatusButton
        record={record}
        status="rejected"
        label={
          forUser ? "resources.user-parent-approvals.buttons.reject" : null
        }
        Icon={ThumbDownAltIcon}
        onSuccess={onSuccess}
      />
    </ButtonGroup>
  );
};

export default ApprovalButton;
