import { Chip } from "@material-ui/core";
import React from "react";
import { get } from "lodash";
import { useTranslate } from "ra-core";
const StatusField = ({ record }) => {
  const translate = useTranslate();
  const status = get(record, `stats_cpa.status`, "undefined");
  const label = translate(`resources.orders.status.${status}`);
  return <Chip label={label} />;
};

export default StatusField;
