import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";
import Title from "components/Title";
import { useFormStyles } from "../components/forms/useStyles";

const Edit = ({ staticContext, ...props }) => {
  const classes = useFormStyles();
  return (
    <RaEdit
      {...props}
      id="profile"
      resource="users"
      basePath="profile"
      classes={classes}
      component="div"
      title={<Title source="email" />}
    >
      <Form />
    </RaEdit>
  );
};

export default Edit;
