import { CampaignWhitelistIcon } from "../icons";
import Create from "./Create";
import List from "./List";

export default {
	options: {
		group: "campaigns",
		roles: ["admin", "advertiser"],
	},
	icon: CampaignWhitelistIcon,
	list: List,
	create: Create,
};
