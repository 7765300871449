import React, { Fragment } from "react";

import ChangeStatusButton from "./buttons/ChangeStatusButton";

const BulkActions = (props) => (
  <Fragment>
    <ChangeStatusButton {...props} />
  </Fragment>
);
export default BulkActions;
