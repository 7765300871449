import { get } from "lodash";
import React, { useMemo } from "react";
import { EditButton as RaEditButton, usePermissions } from "react-admin";

const EditButton = (props) => {
	const userId = get(props, "record.user_id");
	const { loaded, permissions } = usePermissions();
	const isAdmin = useMemo(
		() => loaded && permissions && permissions(["admin"]),
		[loaded, permissions]
	);
	if (!isAdmin && !userId) {
		return null;
	}
	return <RaEditButton {...props} />;
};

export default EditButton;
