import React, { useMemo } from "react";

import { ReferenceInput } from "ra-ui-materialui";
import { SelectInput } from "react-admin";
import { get } from "lodash";

const textRenderer = (record) => {
  const code = get(record, "code");
  if (code && code !== "") {
    return code;
  }
  const url = get(record, "url");
  if (url && url !== "") {
    return url;
  }
  const id = get(record, "id");
  return `#${id}`;
};
const LinkInput = (props) => {
  const filter = useMemo(
    () => ({
      campaign_id: get(props.filterValues, "campaign_id", null),
      landing_id: get(props.filterValues, "landing_id", null),
    }),
    [props.filterValues]
  );
  return (
    <ReferenceInput {...props} filter={filter} reference="links">
      <SelectInput optionText={textRenderer} />
    </ReferenceInput>
  );
};
export default LinkInput;
