import { Notification, setSidebarVisibility } from "react-admin";
import React, { useEffect } from "react";

import PageTitle from "./PageTitle";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    height: "auto",
    inHeight: "auto",
    '& .create-page>div>div>form>div[class*="MuiToolbar-root"], & .edit-page>div>div>form>div[class*="MuiToolbar-root"]': {
      backgroundColor: "#fff !important",
      boxShadow:
        "0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
      borderRadius: "0.5rem",
    },
    '& .create-page>div>div>form>div[class*="MuiToolbar-root"]:before, & .edit-page>div>div>form>div[class*="MuiToolbar-root"]': {
      content: "",
      top: "-30px",
      left: 0,
      width: "100%",
      height: "30px",
    },
  },
  appFrame: {
    margin: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      // "& .list-page": {
      //   // margin: theme.spacing(2),
      // },
      // "& [class*=RaMobileList-main]": {
      //   margin: -theme.spacing(1),
      // },
      // margin: 0,
    },
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      "& .list-page > [class*=MuiToolbar-root]:first-child": {
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
        marginTop: -theme.spacing(2),
        marginRight: -theme.spacing(2),
      },
    },
  },
  content: {
    margin: 0,
    padding: 0,
  },
  contentWithSidebar: {},
}));

const Layout = ({ children, dashboard, logout, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSidebarVisibility]);

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.contentWithSidebar}>
          <PageTitle />
          <div className={classes.content}>{children}</div>
        </main>
        <Notification />
      </div>
    </div>
  );
};

export default Layout;
