import { Collapse, IconButton, makeStyles } from "@material-ui/core";

import HelpIcon from "@material-ui/icons/Help";
import React from "react";
import useHelpProvider from "./useHelpProvider";

const useStyles = makeStyles(() => ({
  icon: {
    color: "white",
  },
}));
const HelpToggle = () => {
  const { hasHelp, visible, toggle } = useHelpProvider("HelpToggle");
  const classes = useStyles();
  const handleToggle = () => toggle(!visible);
  return (
    <Collapse in={hasHelp && !visible}>
      <IconButton onClick={handleToggle} className={classes.icon}>
        <HelpIcon />
      </IconButton>
    </Collapse>
  );
};

export default HelpToggle;
