import { ReferenceInput, SelectInput } from "ra-ui-materialui";

import React from "react";

const CategoryInput = ({ ...props }) => (
  <ReferenceInput
    {...props}
    reference="campaign-media-categories"
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default CategoryInput;
