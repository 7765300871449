import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import { PagesIcon } from "icons";
import Show from "./Show";

export default {
  options: {
    group: "admin",
    roles: ["admin"],
  },
  icon: PagesIcon,
  list: List,
  edit: Edit,
  create: Create,
  show: Show,
};
