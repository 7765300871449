import * as React from "react";

import {
  CreateButton,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from "react-admin";

import { cloneElement } from "react";

const Actions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    // currentSort,
    resource,
    displayedFilters,
    filterValues,
    // hasCreate,
    basePath,
    // selectedIds,
    showFilter,
    // total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton
        basePath={basePath}
        label="resources.user-parent-approvals.buttons.create"
      />
    </TopToolbar>
  );
};
export default Actions;
