import { InputAdornment } from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { TextInput } from "react-admin";

const SearchInput = (props) => (
  <TextInput
    {...props}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />
);
export default SearchInput;
