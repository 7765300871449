import { Button, ButtonGroup } from "@material-ui/core";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { get } from "lodash";
import React from "react";
import { useMutation, useNotify, useRefresh, useTranslate } from "react-admin";

const SetStatusButton = ({ record, status, Icon, ...props }) => {
	const translate = useTranslate();
	const refresh = useRefresh();
	const notify = useNotify();

	const [handleApprove, { loading }] = useMutation(
		{
			type: "update",
			resource: "users-campaigns",
			payload: {
				id: record.id,
				data: {
					user_id: record.user_id,
					campaign_id: record.campaign_id,
					notes: record.notes,
					status,
				},
			},
		},
		{
			onSuccess: () => {
				notify(translate(`resources.users-campaigns.messages.${status}`));
				refresh();
			},
			onError: () =>
				notify(translate("resources.users-campaigns.messages.error")),
		}
	);
	if (get(record, "status") === status) {
		return null;
	}
	return (
		<Button {...props} onClick={handleApprove} disabled={loading}>
			<Icon />
		</Button>
	);
};

const SettingsField = ({ record, onViewDetailsClick }) => {
	const handleClick = () => onViewDetailsClick(record);

	return (
		<ButtonGroup color="primary" variant="text" size="small">
			<SetStatusButton
				record={record}
				status="approved"
				Icon={ThumbUpAltIcon}
			/>
			<Button onClick={handleClick}>
				<VisibilityIcon />
			</Button>
			<SetStatusButton
				record={record}
				status="rejected"
				Icon={ThumbDownAltIcon}
			/>
		</ButtonGroup>
	);
};

export default SettingsField;
