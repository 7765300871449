import { Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";

const HtmlField = ({ size, source, record, basePath, ...props }) => {
	const __html = get(record, source, "");
	return (
		<Typography {...props} component="div">
			<div dangerouslySetInnerHTML={{ __html }} />
		</Typography>
	);
};

export default HtmlField;
