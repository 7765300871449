import { useMediaQuery } from "@material-ui/core";
import { useFiltersStyles } from "components/lists/useStyles";
import React from "react";
import { Filter, ReferenceInput, SelectInput, useTranslate } from "react-admin";

const Filters = (props) => {
	const translate = useTranslate();
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const classes = useFiltersStyles();
	return (
		<Filter {...props} classes={classes} variant="outlined">
			<ReferenceInput
				label={translate("resources.contests.name", 1)}
				source="contest_id"
				fullWidth
				reference="contests"
				resettable
				alwaysOn
				style={sm ? { marginRight: 16 } : { marginRight: 0 }}
			>
				<SelectInput optionText="fullname" />
			</ReferenceInput>
		</Filter>
	);
};
export default Filters;
