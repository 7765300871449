import { makeStyles } from "@material-ui/core";

const _useToolbarStyles = makeStyles(
  (theme) => ({
    toolbar: {
      paddingLeft: theme.spacing(3),
      "& button, & a": {
        marginRight: theme.spacing(2),
      },
    },
  }),
  { name: "RaToolbar" }
);

export const useToolbarStyles = () => _useToolbarStyles();
