import { Card } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";

const Table = ({ data: { title, table_rows, table_columns } }) => {
  return (
    <Card className="card-box mb-4">
      <div className="card-header">
        <div className="card-header--title font-weight-bold">{title}</div>
      </div>
      <PerfectScrollbar className="scroll-area-sm mb-2">
        <div className="table-responsive">
          <table className="text-nowrap mb-0 table table-borderless table-hover">
            <thead>
              <tr>
                {table_columns.map((column, index) => (
                  <th key={index} style={column.style}>
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {table_rows.map((row, index) => (
                <tr key={index} style={row.style}>
                  {row.cells.map((cell, index) => (
                    <td key={index} style={cell.style}>
                      {cell.value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PerfectScrollbar>
    </Card>
  );
};

export default Table;
