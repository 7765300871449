import List from "./List";
/**
 * https://material-ui.com/components/material-icons/
 */
import { NotificationsIcon } from "icons";

export default {
  options: {
    group: "dashboard",
    roles: ["admin", "user", "advertiser", "publisher"],
  },
  icon: NotificationsIcon,
  list: List,
  create: () => {},
};
