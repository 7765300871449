let appUrl = `//${document.location.host}/`;
let environment = "PRODUCTION";
if (appUrl.endsWith(":3000/")) {
	appUrl = process.env.REACT_APP_URL
		? `http://${process.env.REACT_APP_URL}/`
		: "//wooprice.local/";
	// appUrl = "//wooprice.local/";
	environment = "DEVELOPER";
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const SIGNUP_URL = `${APP_URL}signup/`;
export const VERSION = "1.0.0";
export const ENVIRONMENT = environment;
