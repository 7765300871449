import { LinearProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { get } from "lodash";
import React, { Fragment } from "react";

export const CompletePercentageField = ({ record, source }) => {
	const percentage = get(record, source, 0);
	return (
		<Fragment>
			<Box display="flex" alignItems="center">
				<Box width="100%" mr={1}>
					<BorderLinearProgress variant="determinate" value={percentage} />
				</Box>
				<Box minWidth={35}>
					<Typography
						variant="body2"
						color="textSecondary"
						style={{ marginBottom: "16px" }}
					>
						{percentage + "%"}
					</Typography>
				</Box>
			</Box>
		</Fragment>
	);
};

CompletePercentageField.defaultProps = {
	percentage: 0,
};

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 10,
		borderRadius: 5,
		marginBottom: theme.spacing(2),
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[400],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: theme.palette.primary,
	},
}))(LinearProgress);
