import {
  DateField,
  List as RaList,
  TextField,
  useTranslate,
} from "react-admin";

import ContentField from "./fields/ContentField";
import Empty from "./Empty";
import Filters from "./Filters";
import React from "react";
import ReadButton from "./buttons/ReadButton";
import ReadUnreadButton from "./buttons/ReadUnreadButton";
import ResponsiveList from "../components/lists/ResponsiveList";
import UnreadButton from "./buttons/UnreadButton";
import ViewField from "./fields/ViewField";
import perPage from "../utils/perPage";
import { useListStyles } from "../components/lists/useStyles";

const BulkActionButtons = (props) => (
  <React.Fragment>
    <ReadButton
      label="resources.notifications.actions.sign_as_readed"
      {...props}
    />
    <UnreadButton
      label="resources.notifications.actions.sign_as_readed"
      {...props}
    />
  </React.Fragment>
);

const List = (props) => {
  const translate = useTranslate();
  const classes = useListStyles();
  return (
    <RaList
      {...props}
      exporter={false}
      perPage={perPage(50, 25)}
      filters={<Filters />}
      sort={{ field: "created", order: "DESC" }}
      classes={classes}
      empty={<Empty />}
      actions={false}
      bulkActionButtons={<BulkActionButtons />}
      breakpoint="xs"
      title={translate("resources.notifications.name", 0)}
    >
      <ResponsiveList
        mobileHeader={
          <React.Fragment>
            <TextField
              source="title"
              style={{ fontWeight: "bold" }}
              variant="subtitle1"
            />
          </React.Fragment>
        }
        mobileActions={
          <React.Fragment>
            <ReadUnreadButton source="readed" />
            <ViewField source="resource" label="" />
          </React.Fragment>
        }
        mobileBody={
          <React.Fragment>
            <DateField source="created" showTime color="textSecondary" />
            <TextField source="content" />
          </React.Fragment>
        }
      >
        <ContentField label="resources.notifications.fields.content" />
        <DateField
          showTime
          source="created"
          options={{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }}
        />
      </ResponsiveList>
    </RaList>
  );
};
export default List;
