import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Children, Fragment, isValidElement } from "react";

export const FormGrid = ({ spacing, children, ...props }) => {
	const classes = useFormGridStyles();
	return (
		<Grid container spacing={spacing} className={classes.root}>
			{Children.map(children, (column, index) => {
				if (!column) {
					return;
				}
				return (
					<Fragment>
						{isValidElement(column) ? React.cloneElement(column, props) : null}
					</Fragment>
				);
			})}
		</Grid>
	);
};

FormGrid.defaultProps = {
	spacing: 1,
};

const useFormGridStyles = makeStyles(
	(theme) => ({
		root: {
			flexGrow: 1,
			paddingBottom: theme.spacing(2),
		},
	}),
	{
		name: "FormGrid",
	}
);
