import { AutocompleteInput, ReferenceInput } from "react-admin";

import React from "react";

const AdvertiserInput = ({ helperText = false, ...props }) => (
  <ReferenceInput
    {...props}
    reference="users"
    sort={{ field: "name", order: "ASC" }}
    filter={{ role: "advertiser" }}
  >
    <AutocompleteInput optionText="name" helperText={helperText} />
  </ReferenceInput>
);
export default AdvertiserInput;
