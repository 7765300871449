import { get } from "lodash";
import * as React from "react";
import { cloneElement } from "react";
import { sanitizeListRestProps, ShowButton, TopToolbar } from "react-admin";
import { ContestStatIcon } from "../icons";

const Actions = ({ isAdmin, ...props }) => {
	const {
		className,
		filters,
		resource,
		basePath,
		filterValues,
		...rest
	} = props;
	const contestId = get(filterValues, "contest_id");
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			{cloneElement(filters, { context: "button" })}
			{contestId && (
				<ShowButton
					icon={<ContestStatIcon />}
					label="resources.contests.fields.details"
					resource="contests"
					basePath="/contests"
					record={{ id: contestId }}
					style={{ whiteSpace: "nowrap" }}
				/>
			)}
		</TopToolbar>
	);
};

export default Actions;
