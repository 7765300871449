import AutocompleteInput from "components/inputs/AutocompleteInput";
import React from "react";
import { ReferenceInput } from "ra-ui-materialui";

const ReferralInput = (props) => (
  <ReferenceInput
    {...props}
    reference="users"
    filter={{ status: "active" }}
    allowEmpty={true}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);
export default ReferralInput;
