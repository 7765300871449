const { useSelector } = require("components/lists/useStyles");
const { useLocale, usePermissions, getResources } = require("ra-core");
const { useMemo } = require("react");
const { shallowEqual } = require("react-redux");
const { default: config } = require("../config/sidebar");
const { default: createGroups } = require("../helper/createGroups");
const { default: useBadges } = require("./useBadges");
const { default: usePages } = require("./usePages");

const useMenu = () => {
  const locale = useLocale();
  const pages = usePages({ locale });
  const { data: badges } = useBadges();
  const { loaded, permissions } = usePermissions();
  const resources = useSelector(getResources, shallowEqual);
  const menu = useMemo(
    () =>
      loaded && pages !== null
        ? createGroups(config, resources, permissions, badges, pages)
        : [],
    [resources, permissions, pages, loaded, badges]
  );
  return menu;
};

export default useMenu;
