import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CommissionsField from "components/fields/CommissionsField";
import HtmlField from "components/fields/HtmlField";
import ImageField from "components/fields/ImageField";
import Workflow from "components/workflow";
import { CategoryOutlinedIcon } from "icons";
import React from "react";
import { TextField, useTranslate } from "react-admin";

const Label = ({ source, resource, record, basePath, icon, ...props }) => {
	const translate = useTranslate();
	const theme = useTheme();
	const style = { fontWeight: "bold", marginBottom: theme.spacing(2) };
	return (
		<Typography style={style} {...props}>
			{icon && React.isValidElement(icon)
				? React.cloneElement(icon, { style: { marginRight: theme.spacing(1) } })
				: ""}
			<Typography component="span" style={style} variant="h6">
				{translate(`resources.${resource}.fields.${source}`)}
			</Typography>
		</Typography>
	);
};

const Field = ({ children, useLabel = true, icon = undefined, ...props }) => (
	<Grid item>
		{useLabel && <Label {...props} icon={icon} />}
		{React.Children.map(children, (field) =>
			React.isValidElement(field)
				? React.cloneElement(field, { ...props, ...field.props })
				: null
		)}
	</Grid>
);

const GeneralField = ({ source, label, ...props }) => {
	const columnProps = {
		item: true,
		spacing: 2,
		container: true,
		direction: "column",
		justify: "center",
	};
	return (
		<Box>
			<Grid {...columnProps}>
				<Field
					{...props}
					source="preview.file.path"
					label="resources.campaigns.fields.preview_media_id"
					size={{ width: 300, height: 150 }}
					useLabel={false}
				>
					<ImageField />
				</Field>
				<Field
					{...props}
					source="name"
					variant="h5"
					style={{ fontWeight: "bold" }}
					useLabel={false}
				>
					<TextField />
				</Field>
				<Field
					{...props}
					source="state"
					readonly={true}
					admin={false}
					useLabel={false}
				>
					<Workflow.StateCollectionInput />
				</Field>
				<Field
					{...props}
					source="category.name"
					icon={<CategoryOutlinedIcon />}
				>
					<TextField variant="body1" />
				</Field>
				<Field
					{...props}
					source="commissions"
					options={{ style: "currency", currency: "EUR" }}
					icon={<MonetizationOnOutlinedIcon />}
				>
					<CommissionsField />
				</Field>
				<Field {...props} source="description" icon={<InfoOutlinedIcon />}>
					<HtmlField />
				</Field>
			</Grid>
		</Box>
	);
};

export default GeneralField;
