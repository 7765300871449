import { Chip } from "@material-ui/core";
import React from "react";
import { get } from "lodash";
import { useTranslate } from "react-admin";

const StatusField = ({ source, record }) => {
  const translate = useTranslate();
  const status = get(record, source);
  return (
    <Chip
      color={
        status === "approved"
          ? "primary"
          : status === "rejected"
          ? "secondary"
          : "default"
      }
      label={translate(`resources.user-parent-approvals.status.${status}`)}
    />
  );
};

export default StatusField;
