import ImageField from "components/fields/ImageField";
import ResponsiveList from "components/lists/ResponsiveList";
import { useListStyles, useSelector } from "components/lists/useStyles";
import { get } from "lodash";
import React, { Fragment } from "react";
import {
	ChipField,
	DateField,
	EditButton,
	List as RaList,
	TextField,
} from "react-admin";
import perPage from "utils/perPage";
import Filters from "./Filters";

const Datagrid = (props) => {
	const hasCampaignFilter = !!get(props, "filterValues.campaign_id");
	return (
		<ResponsiveList
			{...props}
			mobileBody={
				<Fragment>
					{!hasCampaignFilter && (
						<ChipField
							source="campaign.name"
							label="resources.landings.fields.campaign_id"
						/>
					)}
					<ImageField
						source="preview.file.path"
						label="resources.campaigns.fields.preview_media_id"
					/>
					<TextField
						source="name"
						variant="subtitle2"
						style={{ fontWeight: "bold" }}
					/>
					<DateField source="modified" showTime />
				</Fragment>
			}
			mobileActions={
				<Fragment>
					<EditButton />
				</Fragment>
			}
		>
			<TextField source="id" />
			{!hasCampaignFilter && (
				<ChipField
					source="campaign.name"
					label="resources.landings.fields.campaign_id"
				/>
			)}
			<ImageField
				source="preview.file.path"
				label="resources.campaigns.fields.preview_media_id"
			/>
			<TextField
				source="name"
				variant="subtitle2"
				style={{ fontWeight: "bold" }}
			/>
			<DateField source="created" showTime />
			<EditButton />
		</ResponsiveList>
	);
};

const List = (props) => {
	const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
	const classes = useListStyles({ sidebarOpen });
	return (
		<RaList
			{...props}
			filters={<Filters />}
			classes={classes}
			perPage={perPage(25, 10)}
		>
			<Datagrid {...props} />
		</RaList>
	);
};
export default List;
