import { Filter } from "react-admin";
import React from "react";
import RolesInput from "./inputs/RoleInput";
import SearchInput from "components/inputs/SearchInput";
import StatusInput from "./inputs/StatusInput";
import { useFiltersStyles } from "../components/lists/useStyles";
import { useMediaQuery } from "@material-ui/core";

const Filters = (props) => {
  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useFiltersStyles();
  return (
    <Filter {...props} classes={classes}
            variant="outlined">
      <SearchInput variant="outlined" source="q" alwaysOn fullWidth />
      <StatusInput
        source="status"
        alwaysOn={!sm}
        fullWidth
      />
      <RolesInput
        source="role_id"
        alwaysOn={!sm}
        fullWidth
      />
    </Filter>
  );
};
export default Filters;
