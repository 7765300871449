import { ChipField, useTranslate } from "react-admin";
import React, { useMemo } from "react";

import { get } from "lodash";
import { makeStyles } from "@material-ui/core";
import useWorkflow from "../useWorkflow";

const useStyles = makeStyles(() => ({
  chip: {
    marginLeft: 0,
  },
}));

const StateField = ({
  label = "app.label.workflow.state",
  record,
  resource: toResolve,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  // Se il componente è utilizzato all'interno di TransactionLogsField
  // è necessario risolvere il vero nome della risorsa partendo dalla risorsa
  // utilizzata per recuperare l'elenco dei logs.
  const resource = useMemo(
    () => toResolve.replace("workflow/transactions/", ""),
    [toResolve]
  );
  const workflow = useWorkflow({ resource });
  const state = useMemo(
    () =>
      (workflow &&
        workflow.getState({
          ...record,
          // Se il componente è utilizzato in TransactionLogsField la transazione equivale
          // al record stesso, quindi provvedo ad associarla automaticamente per consentire
          // comunque la risoluzione dello stato ad essa associato.
          transaction: get(record, "transaction", record),
        })) ||
      undefined,
    [record, workflow]
  );

  return (
    <ChipField
      {...props}
      label={translate(label)}
      classes={classes}
      source="name"
      record={state}
      color="secondary"
    />
  );
};
export default StateField;
