import Form from "./Form";
import {Create as RaCreate} from "react-admin";
import React from "react";
import {useFormStyles} from "../components/forms/useStyles";

const Create = ({permissions, ...props}) => {
    const classes = useFormStyles();
    return (
        <RaCreate {...props} classes={classes} redirect="list"
                  component="div">
            <Form permissions={permissions} create={true}/>
        </RaCreate>
    );
};

export default Create;
