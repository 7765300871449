import { Button, Card, CardContent, makeStyles } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import React, { useState } from "react";
import { useDataProvider, useNotify, useRedirect, useTranslate } from "ra-core";

import BasicInput from "components/layout/BasicInput";
import BasicLayout from "components/layout/BasicLayout";
import BasicTitle from "components/layout/BasicTitle";
import { Link } from "react-router-dom";
import { Person } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  recoverPasswordForm: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const RecoverPasswordPage = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const submit = (values) => {
    setLoading(true);
    dataProvider
      .create("users/reset", { data: values })
      .then(() => {
        notify(translate("ra.auth.password_reset.success"));
        redirect("/login");
      })
      .catch(({ message }) => {
        notify(message, "warning");
        setLoading(false);
      });
  };
  const validate = (values) => {
    const errors = { account: undefined };

    if (!values.account) {
      errors.account = translate("ra.validation.required");
    }
    return errors;
  };
  return (
    <BasicLayout>
      <BasicTitle message="ra.auth.recover_password" />
      <Card className="m-0 w-100 p-0 border-0">
        <CardContent className="p-3 pt-5">
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={classes.recoverPasswordForm}
              >
                <div className="mb-3">
                  <Field
                    Icon={Person}
                    autoFocus
                    id="account"
                    name="account"
                    component={BasicInput}
                    label={translate("ra.auth.account")}
                    disabled={loading}
                  />
                </div>
                <div className="text-center">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={loading}
                    className="my-2"
                  >
                    {translate("ra.auth.recover")}
                  </Button>
                  <br />
                  <Button type="button" component={Link} to="/login">
                    &larr;
                    {translate("ra.auth.back_to_login")}
                  </Button>
                </div>
              </form>
            )}
          />
        </CardContent>
      </Card>
    </BasicLayout>
  );
};

export default RecoverPasswordPage;
