import React from "react";
const {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormHelperText,
} = require("@material-ui/core");

const BasicInput = ({
  Icon,
  label,
  meta: { touched, error },
  input: inputProps,
  ...props
}) => {
  return (
    <FormControl className="w-100" error={!!(touched && error)}>
      <InputLabel htmlFor={props.id}>{label}</InputLabel>
      <Input
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        }
        error={!!(touched && error)}
        {...props}
        {...inputProps}
      />
      {!!(touched && error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default BasicInput;
