import React, { useMemo } from "react";
import {
	DateField,
	EditButton,
	List as RaList,
	TextField,
	usePermissions,
} from "react-admin";
import CommissionsField from "../components/fields/CommissionsField";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import perPage from "../utils/perPage";
import BulkActions from "./BulkActions";
import LandingField from "./fields/LandingField";
import PaymentMethodField from "./fields/PaymentMethodField";
import StatusField from "./fields/StatusField";
import Filters from "./Filters";

const Content = (props) => {
	const { loading, permissions } = usePermissions();
	const admin = useMemo(
		() => !loading && permissions(["admin", "advertiser"]),
		[loading, permissions]
	);
	const { hasUserFilter, hasCampaignFilter, hasLandingFilter } = useMemo(
		() => ({
			hasUserFilter: props.filterValues.user_id !== undefined,
			hasCampaignFilter: props.filterValues.campaign_id !== undefined,
			hasLandingFilter: props.filterValues.landing_id !== undefined,
		}),
		[props.filterValues]
	);

	return (
		<ResponsiveList
			{...props}
			mobileHeader={
				<React.Fragment>
					<TextField source="code" />
				</React.Fragment>
			}
			mobileBody={
				<React.Fragment>
					<TextField source="campaign.name" />
					<TextField source="landing.name" />
					<DateField source="created" showTime />
					<StatusField />
				</React.Fragment>
			}
			mobileActions={
				<React.Fragment>
					<EditButton />
				</React.Fragment>
			}
		>
			{admin && !hasUserFilter && (
				<TextField source="user.nice_name" sortBy="Users.username" />
			)}
			<TextField
				source="code"
				style={{ marginLeft: 0, fontFamily: "Consolas, Courier New" }}
			/>
			<DateField source="created" showTime />
			<StatusField
				label="resources.orders.fields.status"
				sortBy="StatsCPA.status"
			/>
			<TextField source="customer.full_name" sortBy="Customers.first_name" />
			{!hasCampaignFilter && (
				<TextField source="campaign.name" sortBy="Campaigns.name" />
			)}
			{!hasLandingFilter && (
				<LandingField
					label="resources.orders.fields.landing"
					sortBy="Landings.name"
				/>
			)}
			<PaymentMethodField source="payment_method" />
			<CommissionsField
				source="commissions"
				admin={admin}
				forceCurrency={true}
			/>
			{admin && <EditButton />}
		</ResponsiveList>
	);
};

const List = (props) => {
	const classes = useListStyles();
	const { loading, permissions } = usePermissions();
	const isPublisher = useMemo(() => !loading && permissions(["publisher"]), [
		loading,
		permissions,
	]);
	return (
		<RaList
			{...props}
			exporter={false}
			filters={<Filters />}
			bulkActionButtons={!isPublisher && <BulkActions />}
			classes={classes}
			perPage={perPage(25, 10)}
			sort={{ field: "created", order: "DESC" }}
		>
			<Content />
		</RaList>
	);
};
export default List;
