import React, { useState } from "react";
import { DateField, List as RaList, TextField } from "react-admin";
import ResponsiveList from "../components/lists/ResponsiveList";
import { useListStyles } from "../components/lists/useStyles";
import ApprovalDialog from "./dialogs/ApprovalDialog";
import SettingsField from "./fields/SettingsField";
import StatusField from "./fields/StatusField";
import Filters from "./Filters";

const List = (props) => {
	const [record, setRecord] = useState(null);
	const handleSetRecord = (record) => setRecord(record);
	const handleUnsetRecord = () => setRecord(null);
	const classes = useListStyles();
	return (
		<>
			<RaList
				{...props}
				classes={classes}
				perPage={25}
				filters={<Filters />}
				filterDefaultValues={{ status: "pending" }}
			>
				<ResponsiveList
					mobileHeader={
						<React.Fragment>
							<TextField
								variant="h6"
								style={{ fontWeight: "bold" }}
								source="campaign.name"
							/>
							<StatusField source="status" />
						</React.Fragment>
					}
					mobileBody={
						<React.Fragment>
							<TextField source="user.name" variant="body1" />
						</React.Fragment>
					}
					mobileActions={
						<React.Fragment>
							<SettingsField
								sortable={false}
								label={""}
								onViewDetailsClick={handleSetRecord}
							/>
						</React.Fragment>
					}
				>
					<TextField source="campaign.name" variant="subtitle2" />
					<TextField source="user.name" />
					<StatusField source="status" />
					<DateField source="created" showTime />
					<SettingsField
						sortable={false}
						label={""}
						onViewDetailsClick={handleSetRecord}
					/>
				</ResponsiveList>
			</RaList>
			<ApprovalDialog record={record} onCloseRequest={handleUnsetRecord} />
		</>
	);
};
export default List;
