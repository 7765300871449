import {
  DateField,
  EditButton,
  List as RaList,
  ShowButton,
  TextField,
} from "react-admin";
import React, { useState } from "react";

import Actions from "./Actions";
import ApprovalButton from "./buttons/ApprovalButton";
import ApprovalDialog from "./dialogs/ApprovalDialog";
import Filters from "./Filters";
import { Fragment } from "react";
import ResponsiveList from "../components/lists/ResponsiveList";
import StatusField from "./fields/StatusField";
import { useListStyles } from "../components/lists/useStyles";

const List = (props) => {
  const [record, setRecord] = useState(null);
  const handleSetRecord = (record) => setRecord(record);
  const handleUnsetRecord = () => setRecord(null);
  const classes = useListStyles();
  const { permissions } = props;
  const isAdmin = permissions && permissions(["admin"]);
  return (
    <Fragment>
      <RaList
        {...props}
        actions={<Actions />}
        bulkActionButtons={false}
        classes={classes}
        perPage={25}
        filters={<Filters />}
        sort={{ field: "created", order: "desc" }}
      >
        <ResponsiveList
          mobileHeader={
            <React.Fragment>
              <TextField
                source="user.name"
                label="resources.user-parent-approvals.fields.user_id"
                style={{ fontWeight: "bold" }}
              />
              <StatusField source="status" />
            </React.Fragment>
          }
          mobileBody={
            <React.Fragment>
              <DateField showTime source="created" color="textSecondary" />
            </React.Fragment>
          }
          mobileActions={
            <React.Fragment>
              <ApprovalButton
                sortable={false}
                label={""}
                onViewDetailsClick={handleSetRecord}
              />
            </React.Fragment>
          }
        >
          <TextField
            source="user.name"
            label="resources.user-parent-approvals.fields.user_id"
            variant="subtitle2"
          />
          <StatusField source="status" />
          <DateField showTime source="created" />
          {isAdmin && (
            <ApprovalButton
              sortable={false}
              label={""}
              onViewDetailsClick={handleSetRecord}
            />
          )}
          {isAdmin && <ShowButton />}
          {isAdmin && <EditButton />}
        </ResponsiveList>
      </RaList>
      <ApprovalDialog record={record} onCloseRequest={handleUnsetRecord} />
    </Fragment>
  );
};
export default List;
