import {TextInput, required} from "react-admin";

import React from "react";
import useValidatableSave from "components/forms/useValidatableSave";
import {CardForm} from "../components/forms/cards/CardForm";
import {FormCard} from "../components/forms/cards/FormCard";
import {useTranslate} from "ra-core";
import {CategoryIcon} from "../icons";

const Form = (props) => {
  const save = useValidatableSave({...props, redirect: undefined});
  const translate = useTranslate();
  return (
    <CardForm {...props} save={save} variant="outlined">
      <FormCard size={4} offset={4}
                label={translate("resources.categories.name", 1)}
                icon={<CategoryIcon/>}
      >
        <TextInput source="name" validate={required()} fullWidth/>
      </FormCard>
    </CardForm>
  );
};
export default Form;
