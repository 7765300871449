import { DashboardIcon } from "icons";
import List from "./List";
import React from "react";
import WelcomeMessage from "./components/WelcomeMessage";

export default {
  icon: DashboardIcon,
  options: {
    group: "dashboard",
    roles: ["admin", "advertiser", "publisher"],
    pageTitleConfig: {
      breadcrumbs: [
        {
          label: "Dashboard",
          to: "/",
        },
        {
          label: "Statistiche",
          to: "/dashboard",
        },
      ],
      Icon: DashboardIcon,
      title: "Dashboard",
      sub: <WelcomeMessage />,
    },
  },
  list: List,
};
