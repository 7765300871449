import React from "react";
import { SelectInput } from "react-admin";

const STATUSES = [
  {
    id: "pending",
    name: "resources.users.status.pending",
  },
  {
    id: "active",
    name: "resources.users.status.active",
  },
  {
    id: "banned",
    name: "resources.users.status.banned",
  },
];

const StatusInput = (props) => <SelectInput {...props} choices={STATUSES} />;
export default StatusInput;
