import { CheckboxGroupInput, ReferenceArrayInput } from "react-admin";
import { formatMany, parseMany } from "../../utils/parsing";

import React from "react";
import useCheckboxGroupStyles from "../../components/inputs/useCheckboxGroupStyles";

const RolesInput = (props) => {
  const classes = useCheckboxGroupStyles();
  return (
    <ReferenceArrayInput
      {...props}
      className={classes.root}
      reference="roles"
      sort={{ field: "name", order: "ASC" }}
      format={formatMany}
      parse={parseMany}
      fullWidth
    >
      <CheckboxGroupInput optionText="name" />
    </ReferenceArrayInput>
  );
};
export default RolesInput;
