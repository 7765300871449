import React, { useMemo } from "react";

import { Card } from "@material-ui/core";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DEFAULT_CHART_CONFIG = {
  chart: {
    width: "100%",
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      zIndex: 3,
    },
  },
  colors: ["#1bc943"],
  fill: {
    opacity: 1,
  },
  stroke: {
    color: "#1bc943",
    width: 3,
  },
  legend: {
    show: false,
  },
  markers: {
    size: 0,
  },
  xaxis: {
    crosshairs: {
      width: 0,
    },
  },
  yaxis: {
    min: 0,
  },
};

const Indicator = ({
  data: { title, indicator, subtitle, short, severity, angle, chart_data },
  props: { chart, chartContainerStyle },
}) => {
  const series = useMemo(
    () =>
      Object.keys(chart_data).map((serieName) => ({
        name: serieName,
        data: chart_data[serieName],
      })),
    [chart_data]
  );
  return (
    <Card className="pl-3 pt-3 mb-4" style={{ height: "100%" }}>
      <div className="d-flex justify-content-between">
        <div
          className="pr-5 flex-grow-1 text-nowrap"
          style={{
            backgroundColor: "transparent",
            zIndex: 1,
            pointerEvents: "none",
          }}
        >
          {title && (
            <div className="text-uppercase font-weight-bold font-size-xs mb-2">
              {title}
            </div>
          )}
          <div className="font-weight-bold">
            <div className="font-size-xxl mb-1">{indicator}</div>
            {subtitle && (
              <div className={`text-${severity}`}>
                <span>{subtitle}</span>
                {angle && (
                  <FontAwesomeIcon
                    icon={["fas", `angle-${angle}`]}
                    className="ml-2"
                  />
                )}
              </div>
            )}
            {short && <span className={`text-black-50`}>{short}</span>}
          </div>
        </div>
        {series.some((s) => s.data.length > 0) && (
          <div
            className="pt-8"
            style={{
              bottom: 0,
              right: 0,
              left: 0,
              width: "100%",
              position: "absolute",
              ...chartContainerStyle,
            }}
          >
            <Chart
              options={{ ...DEFAULT_CHART_CONFIG, ...chart }}
              series={series}
              type="area"
              height={100}
              width={"100%"}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
export default Indicator;
