import { IconButton, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { get } from "lodash";
import React from "react";
import { useRedirect, useTranslate } from "react-admin";
import { CampaignIcon, RewardIcon } from "../../icons";

const SettingsField = ({ record }) => {
	const translate = useTranslate();
	const redirect = useRedirect();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOpen = (resource) => {
		setAnchorEl(null);
		const url = `/${resource}?filter=${encodeURIComponent(
			JSON.stringify({
				contest_id: get(record, "id"),
			})
		)}`;
		redirect(url);
	};
	return (
		<>
			<IconButton
				aria-controls="simple-menu"
				aria-haspopup="true"
				title={translate("resources.campaigns.fields.settings.help")}
				onClick={handleClick}
			>
				<SettingsIcon />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleOpen.bind(this, "rewards")}>
					<ListItemIcon>
						<RewardIcon size="small" />
					</ListItemIcon>
					{translate("menu.items.rewards")}
				</MenuItem>
				<MenuItem onClick={handleOpen.bind(this, "contests-campaigns")}>
					<ListItemIcon>
						<CampaignIcon size="small" />
					</ListItemIcon>
					{translate("menu.items.contests-campaigns")}
				</MenuItem>
			</Menu>
		</>
	);
};

export default SettingsField;
