import { InputAdornment } from "@material-ui/core";
import React from "react";
import SYMBOLS from "commissions/Symbols";
import { get } from "lodash";
import { useFormState } from "react-final-form";
import { useSelector } from "react-redux";

const { NumberInput } = require("ra-ui-materialui");

const AmountInput = (props) => {
  const formState = useFormState();

  const eventId = get(formState.values, "event_id", null);
  const event = useSelector((state) =>
    get(state, `admin.resources.events.data[${eventId}]`, "")
  );
  const eventValueType = get(event, "value_type");
  const eventName = get(event, "name");
  const symbol = get(SYMBOLS, eventValueType, eventName);
  return (
    <NumberInput
      {...props}
      InputProps={{
        endAdornment: symbol && (
          <InputAdornment position="end">{symbol}</InputAdornment>
        ),
      }}
    />
  );
};

export default AmountInput;
