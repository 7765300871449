import React, { useMemo } from "react";

import { FormTab as RaFormTab } from "react-admin";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { useFormState } from "react-final-form";
import { useRoles } from "../../../authProvider";
import useWorkflow from "../useWorkflow";

const useStyles = makeStyles({
  tabHidden: {
    display: "none",
    visibility: "hidden",
  },
});

const FormTab = ({ ...props }) => {
  const form = useFormState();
  const { record, children } = props;
  const roles = useRoles();
  const classes = useStyles();
  const fields = useMemo(
    () => React.Children.map(children, ({ props: { source } }) => source),
    [children]
  );
  const resource = useMemo(() => {
    let { resource } = props;
    if (!resource) {
      // Ra sta disegnando il "Tab" vero e proprio all'interno del quale,
      // per ora, non esiste alcun riferimento alla risorsa, quindi lo deduciamo da value.
      const { value } = props;
      resource = value.split("/").filter((s) => s.length > 0)[0];
    }
    return resource;
  }, [props]);

  const workflow = useWorkflow({ resource });
  const visible =
    workflow && workflow.canReadFields(fields, roles, record || form.values);

  return (
    <RaFormTab
      {...props}
      className={classnames(!visible && classes.tabHidden)}
    />
  );
};
export default FormTab;
