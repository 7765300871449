import { get } from "lodash";
import React, { useCallback, useMemo } from "react";
import { SaveButton, Toolbar as RaToolbar } from "react-admin";
import { useForm } from "react-final-form";
import { useRoles } from "../../../authProvider";
import BackButton from "../buttons/BackButton";
import StateButton from "../buttons/StateButton";
import useWorkflow from "../useWorkflow";
import { useToolbarStyles } from "./useStyles";
import {useMediaQuery} from "@material-ui/core";

const Toolbar = ({ children, mutationMode, ...props }) => {
	const form = useForm();
	const { handleSubmitWithRedirect, record } = props;
	const classes = useToolbarStyles();
	const roles = useRoles();
	const workflow = useWorkflow({ resource: props.resource });
	const { states, save } = useMemo(() => {
		const save =
			workflow && workflow.canEdit(roles, record) && get(record, "id", 0) > 0;
		const states = (workflow && workflow.getNextStates(roles, record)) || [];
		return { states, save };
	}, [workflow, record, roles]);
	const handleClick = useCallback(() => {
		form.change("state", record.state);
		handleSubmitWithRedirect("list");
	}, [form, record.state, handleSubmitWithRedirect]);
	const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	if (!record) {
		return null;
	}
	return (
		<RaToolbar {...props} classes={classes}>
			{save && (
				<SaveButton
					disableElevation
					{...props}
					handleSubmitWithRedirect={handleClick}
				/>
			)}
			{!sm && states.map((state) => (
				<StateButton
					disableElevation
					key={get(state, "code")}
					state={state}
					{...props}
				/>
			))}
			{React.Children.count(children) > 0 &&
				React.Children.map(children, (child, key) =>
					React.cloneElement(child, { ...props, key })
				)}

			<BackButton />
		</RaToolbar>
	);
};

export default Toolbar;
