import {
  Button,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
} from "react-admin";
import { Menu, MenuItem } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

const ChangeStatusButton = ({ selectedIds }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(null);
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [updateStatus] = useMutation(
    {
      type: "post",
      resource: "users/change-status",
      payload: {
        body: { selectedIds, status },
      },
    },
    {
      onSuccess: () => {
        notify(translate("resources.users.messages.change_status_success"));
        refresh();
        unselectAll("users");
      },
      onError: () => {
        notify(
          translate("resources.users.messages.change_status_fail"),
          "warning"
        );
      },
    }
  );

  useEffect(() => {
    if (status) {
      updateStatus();
      handleClose();
      setStatus(null);
    }
  }, [status, updateStatus]);

  return (
    <Fragment>
      <Button
        label={translate("resources.users.buttons.change_status")}
        onClick={handleClick.bind(this)}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {["pending", "active", "banned", "suspended"].map((id) => (
          <MenuItem key={id} onClick={setStatus.bind(this, id)}>
            {translate(`resources.users.status.${id}`)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
export default ChangeStatusButton;
