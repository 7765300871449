import {
  LinearProgress,
  SelectInput,
  TextInput,
  email,
  required,
  useQueryWithStore,
} from "react-admin";
import React, { useMemo } from "react";
import { useForm, useFormState } from "react-final-form";

import { Grid } from "@material-ui/core";
import GridSingle from "../../components/GridSingle";
import { get } from "lodash";

const getRules = (config) => {
  switch (config.validate) {
    case "email":
      return [required(), email()];
    case "required":
      return [required()];
    default:
      return [];
  }
};

const PaymentMethodsInput = ({ source, ...props }) => {
  const formState = useFormState();
  const form = useForm();
  const { data: paymentMethods, loading } = useQueryWithStore({
    type: "getList",
    resource: "payment-methods",
    payload: {
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        field: "name",
        order: "ASC",
      },
    },
  });
  const paymentMethodId = get(formState.values, `${source}_id`, null);
  const paymentMethodConfig = useMemo(() => {
    if (!paymentMethods) {
      return {};
    }
    let target = paymentMethods.find((pm) => pm.id === paymentMethodId);
    if (!target) {
      return {};
    }
    return JSON.parse(target.config);
  }, [paymentMethods, paymentMethodId]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Grid container>
      <GridSingle md={12}>
        <SelectInput
          label="ra.profile.fields.profile.payment_method_id"
          source={`${source}_id`}
          choices={paymentMethods}
          onChange={() => {
            form.batch(() => {
              form.change(`${source}_data`, undefined);
            });
          }}
          {...props}
        />
      </GridSingle>
      {Object.keys(paymentMethodConfig).length > 0 && (
        <Grid container>
          {Object.keys(paymentMethodConfig).map((field) => {
            let config = get(paymentMethodConfig, field, {});
            return (
              <GridSingle key={field} md={7}>
                <TextInput
                  {...config}
                  {...props}
                  source={`${source}_data.${field}`}
                  id={`${source}_data.${field}`}
                  label={`ra.profile.fields.${field}`}
                  validate={getRules(config)}
                  fullWidth
                />
              </GridSingle>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};
export default PaymentMethodsInput;
