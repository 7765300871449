import { required, useRedirect } from "react-admin";

import AmountInput from "./inputs/AmountInput";
import ApprovalTypeInput from "./inputs/ApprovalTypeInput";
import CampaignInput from "./inputs/CampaignInput";
import { CardForm } from "../components/forms/cards/CardForm";
import EventInput from "./inputs/EventInput";
import { FormCard } from "../components/forms/cards/FormCard";
import React from "react";
import ReferenceToolbar from "components/forms/ReferenceToolbar";
import TypeInput from "./inputs/TypeInput";
import UserInput from "./inputs/UserInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeRedirect } from "components/fields/ListField";
import useQueryString from "utils/useQueryString";
import { useTranslate } from "ra-core";

const Form = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const qs = useQueryString();
  const r = parseInt(qs.get("r"), 10);

  return (
    <CardForm
      {...props}
      initialValues={{
        approval_type: "automatic",
        commission_items: [{ amount: null, to_value: null }],
      }}
      variant="outlined"
      toolbar={
        r === 1 ? (
          <ReferenceToolbar
            backRedirect="/campaigns"
            backReference="campaigns"
            backReferenceTarget="campaign_id"
            backTab={0}
            disableElevation
          />
        ) : (
          undefined
        )
      }
      redirect={
        r === 1 ? () => redirect(makeRedirect({ ...props, tab: 0 })) : undefined
      }
    >
      <FormCard
        size={4}
        offset={4}
        label={translate("resources.commissions.name", 1)}
        icon={<VisibilityIcon />}
      >
        <TypeInput source="type" fullWidth />
        <EventInput source="event_id" validate={required()} fullWidth />
        <ApprovalTypeInput
          source="approval_type"
          validate={required()}
          fullWidth
        />
        <CampaignInput source="campaign_id" fullWidth />
        <UserInput source="user_id" fullWidth />
        <AmountInput source="amount" validate={required()} fullWidth />
      </FormCard>
    </CardForm>
  );
};
export default Form;
