import CodeInput from "components/inputs/CodeInput";
import React from "react";
import TextInput from "components/inputs/TextInput";
import TextMessageInput from "components/inputs/TextMessageInput";
import { get } from "lodash";
import { required } from "ra-core";
import { url } from "utils/validation";
import { useFormState } from "react-final-form";

const ContentInput = ({ ...props }) => {
  const formState = useFormState();
  const type = get(formState.values, "type", "script");
  const validate = type === "url" ? [required(), url] : [required()];
  return (
    <TextMessageInput
      {...props}
      Component={type === "url" ? TextInput : CodeInput}
      validate={validate}
    />
  );
};

export default ContentInput;
