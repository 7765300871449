import { Card, CardContent } from "@material-ui/core";
import React, { useMemo } from "react";

import ApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DEFAULT_CHART_CONFIG = {
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },

  stroke: {
    color: "#1bc943",
    curve: "smooth",
    width: 4,
  },
  colors: ["#1bc943"],
  fill: {
    color: "1bc943",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.7,
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  yaxis: {
    min: 0,
  },
};
const Chart = ({
  data: {
    header_caption,
    title,
    indicator,
    indicator_angle,
    indicator_caption,
    indicator_severity,
    short,
    chart_data,
  },
  props: { chart },
}) => {
  const series = useMemo(
    () =>
      Object.keys(chart_data).map((serieName) => ({
        name: serieName,
        data: chart_data[serieName],
      })),
    [chart_data]
  );
  return (
    <Card className="card-box mb-4">
      <div className="card-header">
        <div className="card-header--title">
          <small>{header_caption}</small>
          <b>{title}</b>
        </div>
      </div>
      <CardContent className="pb-0">
        {/* <div className="align-box-row">
          <div className="font-weight-bold">
            <small className="text-black-50 d-block mt-2 mb-2 text-uppercase">
              New Accounts
            </small>
            <span className="font-size-xxl ">586,356</span>
          </div>
          <div className="ml-auto">
            <div className="bg-first text-center text-white font-size-xl d-50 rounded-circle align-items-center d-flex justify-content-center">
              <FontAwesomeIcon icon={["far", "building"]} />
            </div>
          </div>
        </div> */}
        {(indicator || short) && (
          <div className="mt-1">
            {indicator_angle && (
              <FontAwesomeIcon
                icon={["fas", `arrow-${indicator_angle}`]}
                className={`text-${indicator_severity}`}
              />
            )}
            <span className={`text-${indicator_severity} px-1`}>
              {indicator}
            </span>
            <span className="text-black-50">{indicator_caption}</span>
          </div>
        )}
      </CardContent>
      <ApexChart
        options={{ ...DEFAULT_CHART_CONFIG, ...chart }}
        series={series}
        type="area"
        height={172}
      />
    </Card>
  );
};

export default Chart;
