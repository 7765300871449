import React, { useMemo } from "react";

import { get } from "lodash";
import { useRoles } from "../../../authProvider";
import useWorkflow from "../useWorkflow";

const Input = ({ component, ...props }) => {
  const roles = useRoles();
  const { resource, source, record } = useMemo(() => {
    const resource = get(props, "resource", component.props.resource);
    const source = get(props, "source", component.props.source);
    const record = get(props, "record", component.props.record);
    return { resource, source, record };
  }, [props, component]);

  const workflow = useWorkflow({ resource });
  const { visible, disabled } = useMemo(() => {
    const visible = workflow && workflow.canReadField(source, roles, record);
    const disabled = workflow && !workflow.canEditField(source, roles, record);
    return { visible, disabled };
  }, [workflow, source, record, roles]);

  if (!visible) {
    return null;
  }
  return React.cloneElement(component, {
    ...component.props,
    ...props,
    disabled,
    resource,
    source,
    record,
  });
};
export default Input;
