import {
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required,
  useRedirect,
} from "react-admin";
import { LandingIcon, ScriptIcon } from "icons";

import { CardForm } from "../components/forms/cards/CardForm";
import CopiableInput from "components/inputs/CopiableInput";
import { FormCard } from "../components/forms/cards/FormCard";
import { FormGrid } from "../components/forms/cards/FormGrid";
import { FormGridColumn } from "../components/forms/cards/FormGridColumn";
import ImageInput from "components/inputs/ImageInput";
import PreviewButton from "./buttons/PreviewButton";
import React from "react";
import ReferenceToolbar from "components/forms/ReferenceToolbar";
import SettingsInputs from "./inputs/SettingsInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeRedirect } from "components/fields/ListField";
import useQueryString from "utils/useQueryString";

const Form = ({ created, ...props }) => {
  const qs = useQueryString();
  const r = parseInt(qs.get("r"), 10);
  const redirect = useRedirect();
  return (
    <CardForm
      {...props}
      variant="outlined"
      toolbar={
        r === 1 ? (
          <ReferenceToolbar
            backRedirect="/campaigns"
            backReference="campaigns"
            backReferenceTarget="campaign_id"
            backTab={0}
          >
            {created && <PreviewButton />}
          </ReferenceToolbar>
        ) : (
          undefined
        )
      }
      redirect={
        r === 1 ? () => redirect(makeRedirect({ ...props, tab: 0 })) : undefined
      }
      grid={true}
    >
      <FormGrid container>
        <FormGridColumn>
          <FormCard
            label="resources.landings.tabs.general"
            icon={<VisibilityIcon />}
          >
            <ReferenceInput
              source="campaign_id"
              reference="campaigns"
              fullWidth
              validate={required()}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput
              source="name"
              validate={required()}
              helperText="resources.landings.helpers.name"
              fullWidth
            />
            <ImageInput
              source="preview"
              label="resources.landings.fields.preview_media_id"
              helperText="resources.landings.helpers.preview_media_id"
            />
          </FormCard>
        </FormGridColumn>

        <FormGridColumn>
          <FormCard
            label="resources.landings.tabs.settings"
            icon={<LandingIcon />}
          >
            <SettingsInputs />
          </FormCard>
          {created && (
            <FormCard
              label="resources.landings.tabs.script"
              icon={<ScriptIcon />}
            >
              <CopiableInput
                source="script_head"
                readOnly
                fullWidth
                helperText="resources.landings.helpers.script_head"
              />
              <CopiableInput
                source="script_iframe"
                helperText="resources.landings.helpers.script_iframe"
                readOnly
                fullWidth
                multiline
              />
            </FormCard>
          )}
        </FormGridColumn>
      </FormGrid>
    </CardForm>
  );
};

export default Form;
