import { UserParentApprovalIcon } from "icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import Show from "./Show";

export default {
	icon: UserParentApprovalIcon,
	options: {
		group: "settings",
		roles: ["admin", "advertiser"],
	},
	list: List,
	edit: Edit,
	show: Show,
	create: Create,
};
