import { linkToRecord, useResourceContext } from "ra-core";

import { Button } from "@material-ui/core";
import ContentCreate from "@material-ui/icons/Create";
import { Link } from "react-router-dom";
import React from "react";
import { useMemo } from "react";

const defaultIcon = <ContentCreate />;
const stopPropagation = (e) => e.stopPropagation();

const EditButton = ({
  basePath = "",
  icon = defaultIcon,
  label = "ra.action.edit",
  record,
  scrollToTop = true,
  redirect = true,
  ...rest
}) => {
  const resource = useResourceContext();
  return (
    <Button
      component={Link}
      to={useMemo(
        () => ({
          pathname: record
            ? linkToRecord(basePath || `/${resource}`, record.id)
            : "",
          search: redirect ? "?r=1" : "",
          state: { _scrollToTop: scrollToTop },
        }),
        [basePath, record, resource, scrollToTop, redirect]
      )}
      label={label}
      onClick={stopPropagation}
      {...rest}
    >
      {icon}
    </Button>
  );
};

export default EditButton;
