import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import { useTranslate } from "ra-core";
const LandingField = ({ record, sortBy, basePath, resource, ...props }) => {
  const translate = useTranslate();
  const name = get(
    record,
    "landing.name",
    translate("resources.orders.no_landing")
  );
  return <Typography {...props}>{name}</Typography>;
};

export default LandingField;
