import ImageField from "components/fields/ImageField";
import MoneyField from "components/fields/MoneyField";
import { get } from "lodash";
import React from "react";
import { NumberField, TextField, useRedirect } from "react-admin";
import ListField, { makeRedirect } from "../../components/fields/ListField";

const OffersField = ({ planned, show = false, ...props }) => {
	const redirect = useRedirect();
	const id = get(props, "record.id", 0);
	if (id <= 0) {
		return null;
	}
	return (
		<ListField
			{...props}
			show={show}
			reference="campaign-offers"
			target="campaign_id"
			canModify={true}
			canCreate={true}
			canRemove={true}
			sort={{ field: "id", order: "ASC" }}
			removeRedirect={() => redirect(makeRedirect({ ...props, tab: 2, show }))}
		>
			<TextField label="ID" source="id" />

			<TextField source="name" style={{ fontWeight: "bold" }} />
			<ImageField
				source="picture.file.path"
				label="resources.campaign-offers.fields.picture"
			/>
			<MoneyField source="price" />
			<NumberField source="quantity" />
		</ListField>
	);
};

export default OffersField;
