import { required, useTranslate } from "ra-core";

import AutocompleteInput from "components/inputs/AutocompleteInput";
import React from "react";
import { ReferenceInput } from "ra-ui-materialui";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const CampaignInput = (props) => {
  const translate = useTranslate();
  const formState = useFormState();
  const type = get(formState.values, "type", "none");
  const validate = type === "referral" ? required() : undefined;
  return (
    <ReferenceInput
      {...props}
      validate={validate}
      reference="campaigns"
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="name"
        emptyText={translate("resources.commissions.fields.campaigns.all")}
        resettable
      />
    </ReferenceInput>
  );
};
export default CampaignInput;
