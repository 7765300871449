import {
  Button,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany
} from 'react-admin';

import DraftsIcon from '@material-ui/icons/Drafts';
import React from 'react';

const UnreadButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'notifications',
    selectedIds,
    { readed: null },
    {
      onSuccess: () => {
        refresh();
        notify('resources.notifications.actions.unreaded.done');
        unselectAll('notifications');
      },
      onFailure: error =>
        notify('resources.notifications.actions.unreaded.error', 'warning')
    }
  );

  return (
    <Button
      color="secondary"
      label="resources.notifications.actions.sign_as_unreaded"
      disabled={loading}
      onClick={updateMany}>
      <DraftsIcon />
    </Button>
  );
};

export default UnreadButton;
