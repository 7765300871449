import * as React from "react";

import { ExportButton, TopToolbar, sanitizeListRestProps } from "react-admin";

import CreateButton from "../components/buttons/CreateButton";
import { cloneElement } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    "& .MuiButton-label": {
      alignItems: "start",
      "& span": {
        whiteSpace: "nowrap",
      },
    },
  },
}));

const Actions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
          className: classes.button,
        })}
      <CreateButton
        basePath={basePath}
        baseRecord={{ ...filterValues, type: "none" }}
        className={classes.button}
      />
      <CreateButton
        basePath={basePath}
        baseRecord={{ type: "referral" }}
        label="resources.commissions.buttons.add_referal"
        className={classes.button}
      />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
        className={classes.button}
      />
    </TopToolbar>
  );
};

Actions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export default Actions;
