import {
	Divider,
	ListItemIcon,
	makeStyles,
	Menu,
	MenuItem,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { get } from "lodash";
import React from "react";
import { Button, useRedirect, useTranslate } from "react-admin";
import {
	CampaignBlacklistIcon,
	CampaignWhitelistIcon,
	CommissionIcon,
	LandingIcon,
	UsersCampaignsIcon,
} from "../../icons";

const useStyles = makeStyles({
	divider: {
		marginTop: ".5em",
		marginBottom: ".5em",
	},
});

const SettingsField = ({ record, label = null, variant = "text" }) => {
	const translate = useTranslate();
	const redirect = useRedirect();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOpen = (resource) => {
		setAnchorEl(null);
		redirect(
			`/${resource}?filter=${encodeURIComponent(
				JSON.stringify({
					campaign_id: get(record, "id"),
				})
			)}`
		);
	};
	return (
		<>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				label={label}
				color="primary"
				variant={variant}
				size="medium"
			>
				<SettingsIcon />
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleOpen.bind(this, "users-campaigns")}>
					<ListItemIcon>
						<UsersCampaignsIcon size="small" />
					</ListItemIcon>
					{translate("menu.items.users-campaigns")}
				</MenuItem>
				<MenuItem onClick={handleOpen.bind(this, "campaign-whitelists")}>
					<ListItemIcon>
						<CampaignWhitelistIcon size="small" />
					</ListItemIcon>
					{translate("menu.items.campaign-whitelists")}
				</MenuItem>
				<MenuItem onClick={handleOpen.bind(this, "campaign-blacklists")}>
					<ListItemIcon>
						<CampaignBlacklistIcon size="small" />
					</ListItemIcon>
					{translate("menu.items.campaign-blacklists")}
				</MenuItem>
				<Divider className={classes.divider} />
				<MenuItem onClick={handleOpen.bind(this, "landings")}>
					<ListItemIcon>
						<LandingIcon size="small" />
					</ListItemIcon>
					{translate("menu.items.landings")}
				</MenuItem>
				<MenuItem onClick={handleOpen.bind(this, "commissions")}>
					<ListItemIcon>
						<CommissionIcon size="small" />
					</ListItemIcon>
					{translate("menu.items.commissions")}
				</MenuItem>
			</Menu>
		</>
	);
};

export default SettingsField;
